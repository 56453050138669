<script>
import { ref, onMounted, computed, inject } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'

export default {
  name: 'eventList',
  components: {
    Pagination // Pagination 컴포넌트를 사용하기 위해 등록합니다.
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const userName = ref('');
    const token = ref('');
    const events = ref([]);
    const page = ref(1); // 페이지 번호
    const limit = 6; // 한 페이지에 표시할 항목 수
    const viewMode = ref('');
    const onlyMy = ref(false); // only_my
    const status = ref(''); // 상태
    // PAGINATION
    const events_total = ref(0);
    const event_pagination = ref(0);

    function format_status(statusValue) {
      switch (statusValue) {
        case 0:
          return { 'statusText': '준비중', 'statusClass': 'on_ready' };
        case 1:
          return { 'statusText': '진행중', 'statusClass': 'on_going' };
        case 2:
          return { 'statusText': '종료', 'statusClass': 'finished' };
        default:
          return '-';
      }
    }
    function pushRouter(type, eventId, isPeice) {
      console.log(type, eventId)
      if (type == "master") {
        router.push(`/admin/event/select/master/${eventId}`)
      } else if (type == "piece") {
        router.push(`/admin/event/setting/${eventId}`)
      }
    }

    function pagination() {
      let totalPages = Math.ceil(events_total.value / limit); // 나눈 값을 올림하여 정수로 변환
      event_pagination.value = totalPages <= 1 ? 1 : totalPages; // 1 이하인 경우에는 1로 설정
    }

    const searchQuery = ref(''); // 검색어를 저장할 상태 변수
    const hoverStates = ref([]);

    // TODO:
    // 이벤트 리스트 A타입 사용자 외엔 종료행사 안보이도록 처리
    async function fetchEventList(page_index, onlyMyValue, statusValue) {
      console.log(onlyMyValue)
      page.value = page_index
      status.value = statusValue
      onlyMy.value = onlyMyValue
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/event/read_list', {
          params: {
            page: page.value,
            limit,
            only_my: onlyMy.value,
            status: status.value,
            search_by: 'title',
            search_text: searchQuery.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          events.value = response.data.response_data.event_list; // 이벤트 리스트를 저장합니다.
          events_total.value = response.data.response_data.total_count; // 이벤트 리스트를 저장합니다.
          hoverStates.value = new Array(response.data.response_data.length).fill(false);
          bookmark.value = new Array(response.data.response_data.length).fill(false);
          pagination();
        } else {
          // 요청이 성공했지만 결과가 false인 경우
          // 이벤트를 불러오지 못했다는 알림창 띄우기
          alert('이벤트를 불러오지 못했습니다.');
        }
      } catch (error) {
        // 요청이 실패한 경우
        console.error('이벤트 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }
    const handleClickPage = (index) => {
      fetchEventList(index, onlyMy.value, status.value);
    }
    const handleClickWork = (onlyMy) => {
      fetchEventList(page.value, onlyMy, status.value);
    }
    const handleClickStatus = (status) => {
      fetchEventList(page.value, onlyMy.value, status);
    }
    const handleSearchText = () => {
      fetchEventList(page.value, onlyMy.value, status.value);
    }

    //검색 기능
    // input에 포커스 여부를 추적하는 참조 변수
    const isInputFocused = ref(false);
    // 포커스 상태를 업데이트하는 함수
    const handleFocus = () => {
      isInputFocused.value = true;
    };
    const handleBlur = () => {
      isInputFocused.value = false;
    };

    // 리스트뷰 관련 기능
    const handleMouseOver = (index) => {
      hoverStates.value[index] = true;
    };
    const handleMouseOut = (index) => {
      hoverStates.value[index] = false;
    };

    // BOOKMARK
    const bookmark = (event) => {
      const eventType = event.event_type;
      const eventId = event.event_id;
      const eventFavorite = event.favorite;

      if (eventFavorite === 0) {
        createFavorite(eventType, eventId)
      } else {
        deleteFavorite(eventType, eventId)
      }
    }

    async function createFavorite(eventType, eventId) {
      try {
        const payload = eventType === 'piece'
          ? { piece_event_id: eventId }
          : { master_event_id: eventId };

        const response = await axios.post('/event/create_favorite', payload, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          router.go()
        } else {
          alert('즐겨찾기 실패');
        }
      } catch (error) {
        console.error(error);
      }
    }

    async function deleteFavorite(eventType, eventId) {
      try {
        const payload = eventType === 'piece'
          ? { piece_event_id: eventId }
          : { master_event_id: eventId };

        const response = await axios.delete('/event/delete_favorite', {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
          data: payload // DELETE 메서드는 payload를 data 속성에 넣어서 전송
        });
        if (response.data.result === true) {
          router.go();
        } else {
          alert('즐겨찾기 취소 실패');
        }
      } catch (error) {
        console.error(error);
      }
    }

    const commitViewMode = (viewMode) => {
      store.commit('customStore/setEventListType', viewMode)
    }

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        userName.value = store.state.userStore.userName;
        token.value = store.state.userStore.token;
        store.commit('eventStore/setEventId', null);
        store.commit('eventStore/setMasterId', null);
        store.commit('eventStore/setEventTitle', null);
        viewMode.value = store.state.customStore.eventListType;
        fetchEventList(1, false, null);
      }
    });
    return {
      isAuthenticated,
      userName,
      fetchEventList,
      events,
      format_status,
      onlyMy,
      status,
      pushRouter,
      viewMode,
      commitViewMode,
      event_pagination,
      handleClickPage,
      handleClickWork,
      handleClickStatus,
      page,
      isInputFocused,
      handleFocus,
      handleBlur,
      searchQuery,
      handleSearchText,
      handleMouseOver,
      handleMouseOut,
      hoverStates,
      bookmark
    };
  },
}

</script>

<template>
  <div id="eventList">
    <div class="left">
      <div class="title_wrap">
        <p class="size_36 b_700">안녕하세요 {{ userName }}님</p>
        <p class="size_36 b_700">멋진 업무를 시작해볼까요?</p>
        <div class="main_search" :class="['main_search', { active: isInputFocused }]">
          <input type="text" placeholder="찾으시는 상담회가 있으세요?" v-model="searchQuery" @focus="handleFocus" @blur="handleBlur"
            @keyup.enter="handleSearchText">
          <p class="icon" @click="handleSearchText"><i class="icon-search"></i></p>
        </div>
        <router-link to="/admin/event/setting/new" class="block_prime_btn">상담회 추가</router-link>
      </div>
    </div>
    <div class="right">
      <div class="event_wrap">
        <div class="event_function">
          <div class="event_function_left">
            <div class="event_function_view_wrap">
              <p class="event_function_view_cont" :class="{ 'active': viewMode == 'card' }"
                @click="viewMode = 'card', commitViewMode('card')">
                <i class="icon-card_view"></i>
              </p>
              <p class="event_function_view_cont" :class="{ 'active': viewMode == 'list' }"
                @click="viewMode = 'list', commitViewMode('list')">
                <i class="icon-list_view"></i>
              </p>
            </div>
            <div class="event_function_view_cont">
              <label class="function_name" for="onlyMy">내 업무만 보기</label>
              <label for="onlyMy" class="fake_checkbox" :class="{ 'active': onlyMy }"><i v-if="onlyMy"
                  class="icon-checked"></i></label>
              <input type="checkbox" id="onlyMy" v-model="onlyMy" @change="handleClickWork(onlyMy)" />
            </div>
          </div>
          <div class="event_function_right">
            <div class="event_function_view_wrap">
              <p class="function_name" :class="{ 'active': status == null }" @click="handleClickStatus(null)">전체 행사</p>
              <p class="function_name" :class="{ 'active': status == '0' }" @click="handleClickStatus(0)">준비중</p>
              <p class="function_name" :class="{ 'active': status == '1' }" @click="handleClickStatus(1)">진행중</p>
              <p class="function_name" :class="{ 'active': status == '2' }" @click="handleClickStatus(2)">종료</p>
            </div>
          </div>
        </div>
        <div class="event_card_wrap" v-if="viewMode === 'card'">
          <template v-if="events.length > 0">
            <div v-for="(item, index) in events" :key="index" class="event_card"
              :class="{ 'master': item.event_type == 'master' }"
              @click="pushRouter(item.event_type, item.event_id, item.piece_text)">
              <div class="event_card_top">
                <div class="event_card_top_left">
                  <p class="master_icon" v-if="item.event_type == 'master'"><i class="icon-crown"></i></p>
                  <p class="event_status" :class="format_status(item.status).statusClass">{{
                    format_status(item.status).statusText }}</p>
                </div>
                <div class="event_card_top_right">
                  <p class="event_pm" v-if="item.event_type == 'piece'">{{ item.pm_text }}</p>
                  <p class="event_bookmark" @click.stop="bookmark(item)"><i
                      :class="item.favorite === 1 ? 'icon-bookmark_on' : 'icon-bookmark'"></i></p>
                </div>
              </div>
              <p class="event_title">{{ item.title }}</p>
              <div class="event_card_bottom">
                <template v-if="item.event_type == 'master'">
                  <p class="event_info"><i class="icon-person"></i>{{ item.company }}</p>
                  <p class="event_info"><i class="icon-manual2"></i>{{ item.piece_text }}</p>
                  <p class="event_info"><i class="icon-pm"></i>{{ item.pm_text }}</p>
                </template>
                <template v-else>
                  <p class="event_info"><i class="icon-date"></i>{{ item.start_date + " ~ " + item.end_date }}
                  </p>
                  <p class="event_info"><i class="icon-location"></i>{{ item.place }}</p>
                  <p class="event_info"><i class="icon-person"></i>{{ item.company }}</p>
                </template>
              </div>
            </div>
          </template>
          <template v-else>
            <p>데이터가 없습니다.</p>
          </template>
        </div>
        <div class="event_list_wrap" v-else-if="viewMode === 'list'">
          <table>
            <thead>
              <tr>
                <th class="status">상태</th>
                <th>행사명</th>
                <th>날짜</th>
                <th>장소</th>
                <th>고객사</th>
                <th>담당PM</th>
                <th>즐겨찾기</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="events.length > 0">
                <tr class="event_list_cont" v-for="(item, index) in events" :class="{ hover: hoverStates[index] }"
                  @mouseover="handleMouseOver(index)" @mouseout="handleMouseOut(index)"
                  @click="pushRouter(item.event_type, item.event_id, item.piece_text)">
                  <td>
                    <p class="event_status" :class="format_status(item.status).statusClass">
                      {{ format_status(item.status).statusText }}</p>
                  </td>
                  <td class="title">
                    <div class="ds_flex al_center gap4">
                      <p class="master_icon" v-if="item.event_type == 'master'"><i class="icon-crown"></i></p>
                      <p>{{ item.title }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="ds_flex gap4 al_center">
                      <p>{{ item.start_date }} </p>
                      <p v-if="item.start_date != item.end_date">~ {{ item.end_date }}</p>
                    </div>
                  </td>
                  <td>{{ item.place }}</td>
                  <td>{{ item.company }}</td>
                  <td>{{ item.pm_text }}</td>
                  <td class="bookmark" @click.stop="bookmark(item)">
                    <p class="event_bookmark"><i
                        :class="item.favorite === 1 ? 'icon-bookmark_on' : 'icon-bookmark'"></i></p>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr class="event_list_cont">
                  <td colspan="7">데이터가 없습니다.</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <Pagination :currentPage="page" :totalPages="event_pagination" :handleClickPage="handleClickPage" />
      </div>
    </div>
  </div>
</template>
