<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';
import bisatStatus from '@/assets/js/bisatStatus.json';

export default defineComponent({
  props: {
    showStatusModal: Boolean,
    selectedUserData: Object
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');

    const eventId = computed(() => store.state.eventStore.eventId);

    // 유저 데이터
    const scheduleId = computed(() => props.selectedUserData.scheduleId);
    const userStatus = computed(() => props.selectedUserData.userStatus);
    const userId = computed(() => props.selectedUserData.userId);
    const userType = computed(() => props.selectedUserData.type);
    const userInfo = ref({})

    watch(
      () => props.selectedUserData,
      async (newValue) => {
        if (newValue && newValue.userId) {  // newValue가 존재하고 userId가 있을 때만 실행
          await fetchUserData();
        }
      },
      { immediate: true }
    );

    const closeModals = () => {
      emit('close');
      userInfo.value = {}
    }

    const changeUserType = (userType) => {
      switch (userType) {
        case 'BUYER':
          return '바이어';
        case 'INTERPRETER':
          return '통역사';
        case 'SELLER':
          return '셀러';
        case 'C2':
          return '현장요원';
        default:
          return '-';
      }
    }

    const getStatusList = computed(() => {
      if (userType.value && bisatStatus[userType.value]) {
        return bisatStatus[userType.value].operate || [];
      }
      return [];
    });

    // 상태 업데이트
    const changeStatus = async () => {
      const data = {
        "update_list": [{
          piece_event_id: eventId.value,
          account_id: userInfo.value.account_id,
          account_type: userType.value,
          schedule_id: scheduleId.value,
          code_name: userInfo.value.userStatus,
          created_how: 'SCHEDULETABLE'
        }]
      };
      const url = '/schedule/update_schedule_account_status';
      try {
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.data.result === true) {
          router.go(0)
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('상태 업데이트 오류:', error);
      }
    }

    // 클릭한 사용자의 상세 정보 불러오기
    async function fetchUserData() {
      try {
        const queryParams = new URLSearchParams({
          account_id: userId.value,
          account_type: userType.value,
        });

        const url = `/user/read_account_detail_info?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          userInfo.value = response.data.info.account
          userInfo.value.userStatus = userStatus.value
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('사용자 정보 에러:', error);
      }
    }



    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
      }

      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });

    return {
      closeModals,
      changeUserType,
      getStatusList,
      changeStatus,
      userInfo,
      userType,
      userStatus,
      userId
    }
  },
});
</script>

<template>
  <div id="operateTableModal">
    <div class="overlay" v-if="showStatusModal"></div>
    <div class="modal status_width" v-if="showStatusModal">
      <div class="modal_overflow">
        <p class="icon_box close" @click="closeModals()">
          <i class="icon-delete sub_text"></i>
        </p>
        <p class="user_type_bdg" :class="userType">{{ changeUserType(userType) }}</p>
        <p class="size_26 b_700">{{ userType === 'INTERPRETER' ? userInfo.full_name : userInfo.company }}</p>
        <div class="user_cont" v-if="userInfo">
          <p v-if="userType != 'INTERPRETER'"><label>이름</label> {{ userInfo.full_name }}</p>
          <p v-if="userInfo.email"><label>이메일</label> {{ userInfo.email }}</p>
          <p v-if="userInfo.mobile"><label>연락처</label> {{ userInfo.mobile }}</p>
          <p v-if="userInfo.country"><label>국가</label> {{ userInfo.country }}</p>
          <p v-if="userInfo.lang1"><label>언어</label> {{ userInfo.lang1 }} <span v-if="userInfo.lang2">|</span> {{
            userInfo.lang2 }}</p>
          <p v-if="userInfo.memo" class="memo"><label>비고</label> {{ userInfo.memo }}</p>
        </div>
        <div class="ds_flex jus_between">
          <p class="size_18 b_700">상태</p>
          <div class="user_survey">
            <p class="active"><label>상담일지</label> 0/0</p>
            <p><label>만족도조사</label> 대기중</p>
          </div>
        </div>
        <div class="user_status">
          <p class="status" v-for="status in getStatusList" :key="status.status"
            :class="{ 'active': userInfo.userStatus === status.status }" @click="userInfo.userStatus = status.status">
            <i :class="`icon-${status.icon}`"></i>{{ status.label }}
          </p>
        </div>
        <div class="btn_wrap ds_flex gap18 jus_end">
          <p class="inline_cont_btn cancel_btn" @click="closeModals">취소</p>
          <p class="inline_cont_btn active_btn" @click="changeStatus">저장</p>
        </div>
      </div>
    </div>
  </div>
</template>
