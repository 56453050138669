<script>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import bisatStatus from '@/assets/js/bisatStatus.json';
import userDetailForHome from '@/components/bis/userDetailForHome.vue';

export default {
  name: 'BISSurvey',
  components: {
  },
  props: {
    user: Object,
    event: Object,
  },
  setup(props) {
    const axios = inject('axios'); // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const token = ref('');
    const router = useRouter(); // useRouter를 사용하여 router 인스턴스를 가져옵니다.
    const { userName, formattedUserType, userType, accountStatus } = props.user;
    const { selectDate, scheduleDate, eventId } = props.event;

    return {
    };
  },
};
</script>

<template>
  <div>
    <div class="survey">
      스케줄 별 파라미터 받아서 상담일지 작성하는 곳
    </div>
  </div>
</template>
