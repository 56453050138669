<script>
import subHeader from '@/components/admin/subHeader.vue'
import {computed, onMounted, ref, inject, onBeforeUnmount} from 'vue'
import {useStore} from 'vuex'
import router from '@/router'

export default {
  name: 'sendNotice',
  components: {subHeader},
  setup() {
    const axios = inject('axios'); // $axios 주입 받기
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');
    const activeSub = ref('sendNotice');
    const userName = ref('');

    let noticeMsg = ref('');
    let noticeList = ref([]);
    let noticeType = ref([false, false, false, false]);
    let noticeTypeName = ref(['BUYER', 'INTERPRETER', 'SELLER', 'FM']);
    let sentNoticeBox = ref(false);
    let noticeDateArr = ref([]);

    async function getNoticeList() {
      try {
        const headers = {
          headers: {
            Authorization: `Bearer ${token.value}`,
          }
        };

        const params = new URLSearchParams({
          piece_event_id: eventId.value,
        })

        const url = `/notice/read_notice_list?${params.toString()}`
        const res = await axios.get(url, headers)

        if (res.data.result) {
          store.state.noticeList.value = res.data.notice_list;
          noticeList.value = store.state.noticeList.value;
          noticeDateArr.value = extractUniqueDate();
        }
      } catch (e) {
        console.error(e);
      }
    }

    async function createNotice() {
      try {
        const url = `/notice/create_notice`
        const headers = {
          headers: {
            Authorization: `Bearer ${token.value}`,
          }
        };

        const rb = {
          piece_event_id: eventId.value,
          notice_type_list: setNoticeTypes(),
          notice_text: noticeMsg.value
        }

        const res = await axios.post(url, rb, headers);

        if (res.data.result) {
          await getNoticeList();

          noticeType.value = [false, false, false, false];
          noticeMsg.value = '';

          alert('공지 보내기 완료');
        }
      } catch (e) {
        console.error(e);
      }
    }

    async function readNoticeForBis() {
      try {
        const headers = {
          headers: {
            Authorization: `Bearer ${token.value}`,
          }
        };

        const params = new URLSearchParams({
          piece_event_id: eventId.value,
        })

        const url = `/notice/read_notice_for_bis?${params.toString()}`
        const res = await axios.get(url, headers)

        if (res.data.result) {

        }
      } catch (e) {
        console.error(e);
      }
    }

    function splitDateTime(dateTimeString, type) { // type : 0 === YMD, 1 === TIME
      let dateString = dateTimeString.split('T')[type];

      if (type === 1) { // Hour + Minute
        const temp = dateString.split(':');
        dateString = `${temp[0]}:${temp[1]}`;
      }

      return dateString;
    }

    function modifyNoticeType(noticeType) { // 타입 한글 텍스트로 변환
      const typeMap = {
        'BUYER': '바이어',
        'INTERPRETER': '통역사',
        'SELLER': '셀러',
        'FM': '현장요원'
      };

      return noticeType
        .map(type => typeMap[type] || '')
        .filter(Boolean)
        .join(', ');
    }

    function setNoticeTypes() { // 공지 보낼 대상 선택 처리
      let temp = [];

      for (let i = 0; i < noticeType.value.length; i++) {
        if (noticeType.value[i]) {
          temp.push(noticeTypeName.value[i]);
        }
      }
      console.log('temp: ', temp);
      return temp;
    }

    async function dispatchNotice() {
      const isTypeSelected = noticeType.value.some(type => type);

      if (!isTypeSelected) {
        alert('보낼 대상을 선택하세요');
        return;
      }

      if (!noticeMsg.value) {
        alert('메시지를 입력하세요.')
        return;
      }

      await createNotice();
    }

    function extractUniqueDate() { // 공지 날짜 뽑기
      const uniqueDates = new Set();

      store.state.noticeList.value.forEach(notice => {
        const date = splitDateTime(notice.created_at, 0);
        uniqueDates.add(date);
      });

      return Array.from(uniqueDates).reverse();
    }

    function filterByDate(targetDate, index) { // 날짜 클릭시 보낸 공지한 날짜
      const filteredList = new Set();

      store.state.noticeList.value.forEach(notice => {
        const date = splitDateTime(notice.created_at, 0)

        if (date === targetDate) {
          filteredList.add(notice);
        }
      })

      noticeList.value = Array.from(filteredList);

      highlightSelectedDate(index);
    }

    function highlightSelectedDate(index) { // 보낸 공지함에서 날짜 선택시 하이라이트
      const dateTarget = document.getElementsByClassName('sent_date');

      dateTarget.forEach((el, i) => {
        if (i === index) {
          el.classList.add('selected');
        } else {
          el.classList.remove('selected');
        }
      })
    }

    function urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    }

    async function subscribeUser() {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        try {
          const registration = await navigator.serviceWorker.register('/serviceWorker.js');
          console.log('ServiceWorker registration successful with scope: ', registration.scope);

          const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array('BH2aDXV7tBpJuzLa8IdwV-fbdGRLrMP7G7VUaRzOI5cT84sM2zkxLs37yNA-G68HdYOkPE32vFGSreI36DByqd4'),
          });

          const payload = {
            subscription: subscription,
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
            piece_event_id: eventId.value,
          }

          // console.log('User is subscribed:', JSON.stringify(subscription));

          const res = await axios.post('http://localhost:3000/subscribe', payload);
          console.log("res.data : ", res.data);

        } catch (error) {
          console.error('Failed to subscribe the user: ', error);
        }
      } else {
        console.warn('Push messaging is not supported');
      }
    }

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        await router.push({name: 'home'});
      } else {
        token.value = store.state.userStore.token;
        userName.value = store.state.userStore.userName;
      }

      if (store.state.noticeList.value === undefined) {
        await getNoticeList();
      }

      noticeList.value = store.state.noticeList.value;

      await subscribeUser();
    })

    return {
      store,
      isAuthenticated,
      token,
      activeSub,
      eventId,
      userName,

      noticeList,
      noticeType,
      noticeMsg,
      sentNoticeBox,
      noticeDateArr,

      //function
      splitDateTime,
      modifyNoticeType,
      dispatchNotice,
      filterByDate,
    }
  },
}
</script>

<template>
  <div id="sendNotice" class="back_light_gray2">
    <sub-header :activeSub="activeSub"></sub-header>

    <div class="send_notice_container">
      <div class="left_wrapper" :class="{'right_box_flag' : sentNoticeBox}">
        <div class="ds_flex jus_between align-center title_container_top">
          <div class="ds_flex al_center gap8">
            <p class="size_22 b_700 primary_text">공지 보내기</p>
            <img src="@/assets/img/sendNotice/sendNotice_icon_megaphone.svg" alt="megaphone">
          </div>
          <button class="main_text size_14 send_notice_btn" :class="{'selected' : sentNoticeBox}"
                  @click="sentNoticeBox = !sentNoticeBox">보낸 공지함
          </button>
        </div>
        <p class="sub_text notice_subtitle">공지할 대상을 선택 후 알림을 띄울 메시지를 입력하세요.</p>

        <div class="msg_wrapper">
          <div class="msg_row ds_flex">
            <p class="msg_row_left b_700">보낼 대상</p>
            <div class="ds_flex gap8 msg_row_right">
              <input id="notice_buyer" class="check_wrap_boxed" type="checkbox" v-model="noticeType[0]"
                     name="notice_buyer"/>
              <label for="notice_buyer" class="check_wrap_boxed">
                <em><i class="icon-checked"></i></em>바이어
              </label>
              <input id="notice_interpreter" class="check_wrap_boxed" type="checkbox" v-model="noticeType[1]"
                     name="notice_interpreter"/>
              <label for="notice_interpreter" class="check_wrap_boxed">
                <em><i class="icon-checked"></i></em>통역사
              </label>
              <input id="notice_seller" class="check_wrap_boxed" type="checkbox" v-model="noticeType[2]"
                     name="notice_seller"/>
              <label for="notice_seller" class="check_wrap_boxed">
                <em><i class="icon-checked"></i></em>셀러
              </label>
              <input id="notice_field_agent" class="check_wrap_boxed" type="checkbox" v-model="noticeType[3]"
                     name="notice_field_agent"/>
              <label for="notice_field_agent" class="check_wrap_boxed">
                <em><i class="icon-checked"></i></em>현장요원
              </label>
            </div>
          </div>
          <div class="msg_row ds_flex">
            <p class="msg_row_left b_700">메시지</p>
            <textarea class="notice_textarea" placeholder="전달할 공지사항을 입력하세요." v-model="noticeMsg"></textarea>
          </div>

          <button v-if="noticeMsg !== '' " class="main_text size_14 send_notice_btn send b_700" @click="dispatchNotice">
            보내기
          </button>
          <button v-else class="send_notice_btn send b_700 cancel_btn">보내기</button>
        </div>
      </div>

      <div class="right_wrapper" v-show="sentNoticeBox">
        <div class="right_container">
          <h3 class="size_26 primary_text b_700 sent_notice_title">보낸 공지함</h3>
          <div class="sent_date_container ds_flex al_center gap12">
            <p class="sent_date secondary_text" v-for="(noticeDateArr, index) in noticeDateArr" :key="index"
               @click="filterByDate(noticeDateArr, index)">
              {{ noticeDateArr }}</p>
          </div>

          <div class="sent_notice_container" v-for="(notice, index) in noticeList" :key="index">
            <!--          <p class="notice_send_status">전송실패</p>-->
            <div class="ds_flex jus_between notice_target_title">
              <p class="size_16 primary_text b_700">{{ modifyNoticeType(notice.notice_type) }}</p>
              <span class="size_14 sub_text">{{ splitDateTime(notice.created_at, 1) }}</span>
            </div>

            <p class="back_light_gray2 secondary_text size_14 notice_contents">{{ notice.notice_text }}</p>
            <p class="notice_sender sub_text size_14">{{ notice.created_by }}</p>

            <hr/>
          </div>
        </div>
      </div>

      <div class="toast_wrapper">
        <div class="left_container">
          <img src="@/assets/img/sendNotice/sendNotice_icon_megaphone.svg" alt="megaphone">
        </div>
        <div class="right_container">
          <p>웹푸시 같은 알람</p>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>

</style>
