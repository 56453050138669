<script>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import bisatStatus from '@/assets/js/bisatStatus.json';
import userDetailForHome from '@/components/bis/userDetailForHome.vue';

export default {
  name: 'BISDashboard',
  components: {
    userDetailForHome
  },
  props: {
    user: Object,
    event: Object,
  },
  setup(props) {
    const axios = inject('axios'); // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const token = ref('');
    const router = useRouter(); // useRouter를 사용하여 router 인스턴스를 가져옵니다.
    const { userName, formattedUserType, userType, accountStatus } = props.user;
    const { selectDate, scheduleDate, eventId } = props.event;


    const eventDate = computed(() => store.state.eventStore.eventDate);
    // eventDate가 변경될 때마다 함수를 재호출하도록 watch 설정
    watch(eventDate, (newDate, oldDate) => {
      if (newDate !== oldDate) {
        console.log(`eventDate changed from ${oldDate} to ${newDate}`);
        getNotice();
        fetchEvent();
      }
    });

    //event Info
    const isActiveReception = ref('');
    const eventInfo = ref({})
    const scheduleInfo = ref({})
    const buyerInfo = ref({})

    // MODALS
    const showUserBISModal = ref(false);
    const activeAccountInfo = ref({});
    const closeAllModals = () => {
      showUserBISModal.value = false;
    };
    const handleUserBISModal = (type, account_id) => {
      showUserBISModal.value = true
      activeAccountInfo.value = { type, account_id }
    }

    //공지사항
    const latestNotice = ref('')
    const summary = ref(true)
    const isTouched = computed(() => {
      if (latestNotice.value) {
        return store.getters['bisStore/getNoticeTouched'](latestNotice.value.notice_id);
      }
      return false;
    });

    // 공지를 클릭하면 상태를 업데이트
    const handleNoticeClick = () => {
      if (latestNotice.value) {
        const noticeId = latestNotice.value.notice_id;
        store.commit('bisStore/setNoticeTouched', { noticeId, touched: true });
      }
    };

    async function getNotice() {
      try {
        const response = await axios.get('/notice/read_notice_list', {
          params: {
            'piece_event_id': eventId.value,
            'date': selectDate.value,
            'notice_type': userType.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          const length = response.data.notice_list.length - 1;
          latestNotice.value = response.data.notice_list[length];
          // updated_at 포맷 변환
          if (latestNotice && latestNotice.value.updated_at) {
            latestNotice.value.updated_at = formatDate(latestNotice.value.updated_at);
          }

          // 각 notice_id에 대한 터치 상태 저장
          if (latestNotice.value && latestNotice.value.notice_id) {
            const noticeId = latestNotice.value.notice_id;
            const isTouched = store.getters['bisStore/getNoticeTouched'](noticeId);

            // isTouched 상태가 없으면 기본값으로 설정
            if (!isTouched) {
              store.commit('bisStore/setNoticeTouched', { noticeId, touched: false });
            }
          }
        } else {
          console.log("공지 로드 실패")
        }
      } catch (error) {
      }
    }

    function formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    }


    // 이벤트 전체 데이터
    const filteredScheduleList = ref({})
    async function fetchEvent() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/event/read_event_info_for_bis', {
          params: {
            piece_event_id: eventId.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          eventInfo.value = response.data.event; // 이벤트 리스트를 저장합니다.
          scheduleInfo.value = response.data.schedule; // 이벤트 리스트를 저장합니다.
          buyerInfo.value = response.data.buyer; // 이벤트 리스트를 저장합니다.
          isActiveReception.value = response.data.event.is_reception
          filteredScheduleList.value = response.data.schedule.schedule_list.filter(schedule =>
            schedule.date === selectDate.value
          );
        } else {
          console.log("이벤트 로드 실패")
        }
      } catch (error) {
      }
    }

    const makeCall = (number) => {
      window.location.href = `tel:${number}}`;
    };

    const getIconClass = (status) => {
      const categoryData = bisatStatus['BUYER']['logs'];
      const statusItem = categoryData.find(item => item.status === status);
      return statusItem ? statusItem.icon : '';
    };
    const getIconName = (status) => {
      const categoryData = bisatStatus['BUYER']['logs'];
      const statusItem = categoryData.find(item => item.status === status);
      return statusItem ? statusItem.label : '';
    };

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        await getNotice()
        await fetchEvent()
      }
    });

    return {
      latestNotice,
      summary,
      isTouched,
      handleNoticeClick,
      userName,
      formattedUserType,
      eventInfo,
      scheduleInfo,
      buyerInfo,
      accountStatus,
      filteredScheduleList,
      makeCall,
      getIconClass,
      getIconName,
      isActiveReception,
      closeAllModals,
      showUserBISModal,
      activeAccountInfo,
      handleUserBISModal
    };
  },
};
</script>

<template>
  <div class="back_light_gray2" :class="{ 'touch_disable': showUserBISModal }">
    <div class="dashboard">
      <div class="notice event_box" :class="{ 'new': !isTouched }" v-if="latestNotice">
        <template v-if="summary">
          <img class="summary_img" src="../../assets/img/sendNotice/sendNotice_icon_megaphone.svg" />
          <p class="summary_text">{{ latestNotice.notice_text }}</p>
          <i class="icon-down_arrow" @click="summary = false; handleNoticeClick()"></i>
        </template>
        <template v-else>
          <div class="ds_flex al_center gap8">
            <img src="../../assets/img/sendNotice/sendNotice_icon_megaphone.svg" />
            <p class="size_12 eqqui_text">공지사항</p>
          </div>
          <p class="size_15 b_600">{{ latestNotice.notice_text }}</p>
          <div class="ds_flex al_center jus_between w100">
            <p class="size_12 sub_text">{{ latestNotice.updated_at }}</p>
            <div class="ds_flex" @click="summary = true">
              <p class="size_12 sub_text">접기</p>
              <i class="icon-up_arrow sub_text"></i>
            </div>
          </div>
        </template>
      </div>
      <div class="event_box sec2">
        <template
          v-if="accountStatus.status !== null && accountStatus.attended_at !== null && isActiveReception === 'Y'">
          <div class="ds_flex jus_between al_end metBuyer">
            <p class="size_18 b_700">{{ userName }} {{ formattedUserType }}님<br />담당 바이어를 만났나요?</p>
            <p class="attend_time">출석 {{ accountStatus.attended_at }}</p>
          </div>
          <p class="block_sub_btn">바이어 상태 변경하기</p>
        </template>
        <template v-else-if="accountStatus.status !== null && accountStatus.attended_at !== null">
          <div class="ds_flex jus_between al_end">
            <p class="size_18 b_700">{{ userName }} {{ formattedUserType }}님<br />성공적인 통역을 응원해요!</p>
            <p class="attend_time">출석 {{ accountStatus.attended_at }}</p>
          </div>
          <div class="schedule_cnt">
            <div class="ds_flex al_center jus_between">
              <p class="size_14 secondary_text">오늘의 스케줄</p>
              <p class="size_20 b_700">{{ accountStatus.total_schedules }}</p>
            </div>
            <div class="ds_flex al_center jus_between">
              <p class="size_14 secondary_text">상담일지 진행률</p>
              <p class="size_20 b_700">{{ accountStatus.total_crecords }}/{{ accountStatus.total_schedules }}</p>
            </div>
          </div>
        </template>
        <template v-else>
          <p class="size_18 b_700 attend">{{ userName }} {{ formattedUserType }}님<br />성공적인 통역을 응원해요!</p>
          <p class="icon_cont_btn b_600"><i class="icon-qr_code"></i>QR코드로 출석하기</p>
        </template>
      </div>
      <p class="bis_title">상담회 정보</p>
      <div class="event_box sec3">
        <p class="b_700">{{ eventInfo.piece_event_title }}</p>
        <p class="event_info ds_flex al_center gap6">
          <span class="icon_box"><i class="icon-date"></i></span>
          <span class="size_14 secondary_text">{{ eventInfo.start_date + " ~ " + eventInfo.end_date }}</span>
        </p>
        <p class="event_info ds_flex al_center gap6">
          <span class="icon_box"><i class="icon-location"></i></span>
          <span class="size_14 secondary_text">{{ eventInfo.place }}</span>
        </p>
        <div class="pm_info ds_flex jus_between" v-for="item, index in eventInfo.pm_list" :key="index">
          <div class="ds_flex gap8 al_center">
            <i class="icon_box icon-pm"></i>
            <p class="size_14 b_700">{{ item.full_name }}</p>
          </div>
          <div class="ds_flex gap8 al_center" @click="makeCall(item.mobile)">
            <p class="size_14 secondary_text">{{ item.mobile }}</p>
            <i class="icon-call sub_text"></i>
          </div>
        </div>
      </div>
      <p class="bis_title">담당 바이어</p>
      <div class="event_box sec4" v-for="buyer in buyerInfo" :key="buyer.buyer_id">
        <p class="ds_flex al_center jus_between" @click="handleUserBISModal('BUYER', buyer.buyer_id)">
          <span class="b_700">{{ buyer.company }}</span>
          <span class="icon_box"><i class="icon-up_arrow rotate90 size_20 sub_text"></i></span>
        </p>
        <p class="size_14 secondary_text">담당자: {{ buyer.full_name }}</p>
        <div class="buyer_status ds_flex jus_between">
          <p class="size_12 secondary_text">현재 상태</p>
          <p class="ds_flex al_center gap8">
            <span class="size_12 b_600">{{ getIconName(buyer.status) }}</span>
            <i :class="`icon-${getIconClass(buyer.status)}`"></i>
          </p>
        </div>
        <div class="ds_flex gap8 al_center">
          <p class="size_14 b_600">바이어 설문조사 제출하기</p>
          <i class="icon-up_arrow rotate90 size_20"></i>
        </div>
      </div>
      <a class="bis_title ds_flex al_center jus_between" href="/bis/schedule">
        <span>상담 스케줄</span>
        <span class="icon_box"><i class="icon-up_arrow rotate90 size_24 sub_text"></i></span>
      </a>
      <div class="event_box sec5">
        <p class="size_18 b_700">{{ filteredScheduleList.length }}개의 스케줄</p>
        <div class="schedule_wrap ds_flex dir_column gap12">
          <div class="schedule" v-for="item in filteredScheduleList" :key="item.schedule_id">
            <div class="ds_flex jus_between al_center">
              <p class="time">{{ item.start_time }}-{{ item.end_time }}</p>
              <p class="isVideo" v-if="item.video_link !== null && item.video_link !== ''">화상상담</p>
            </div>
            <div class="user_wrap ds_flex dir_column gap12">
              <div class="ds_flex gap8 al_center" @click="handleUserBISModal('BUYER', item.buyer.account_id)">
                <p class="user_type_bdg BUYER size_10">바이어</p>
                <p class="size_14 b_700">{{ item.buyer.company }}</p>
                <p class="icon_box"><i class="icon-up_arrow rotate90 size_20"></i></p>
              </div>
              <div class="ds_flex gap8 al_center" @click="handleUserBISModal('SELLER', item.seller.account_id)">
                <p class="user_type_bdg SELLER size_10">셀러</p>
                <p class="size_14 b_700">{{ item.seller.company }}</p>
                <p class="icon_box"><i class="icon-up_arrow rotate90 size_20"></i></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <user-detail-for-home :show-user-BIS-modal="showUserBISModal" @close="closeAllModals"
      :active-account-info="activeAccountInfo"></user-detail-for-home>
  </div>
</template>
