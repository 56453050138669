<script>
import { ref, onMounted, computed, inject } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import subHeader from "@/components/admin/subHeader.vue";
import tabHeader from "@/components/admin/tabHeader.vue";
import surveySettingModal from "@/components/admin/masterSurveySettingModal.vue";
import * as survey_form from "@/assets/js/survey_form";
import DropDownNext from "@/components/admin/dropDown/dropDownNextAction.vue";

export default {
    name: "surveySetting",
    props: {
        window: Object,
    },
    components: {
        subHeader,
        tabHeader,
        surveySettingModal,
        DropDownNext,
    },
    setup(props) {
        const axios = inject("axios"); // $axios 주입 받기
        const store = useStore();
        const eventId = ref("");
        const masterId = ref("");
        const isAuthenticated = computed(() => store.state.userStore.isAuthenticated);
        const accountType = computed(() => store.state.userStore.accountType);
        const userName = ref("");
        const token = ref("");

        const activeSub = ref("setting"); // 여기에서 'setting'이라는 값을 정의합니다
        const activeHeadTab = ref("question"); // 여기에서 'question'이라는 값을 정의합니다.

        const validateCqType = (val) => {
            return ["SINGLE", "MULTIPLE"].includes(val);
        };

        // 메뉴 내 탭
        const activeTab = ref("INTERPRETER");
        const handleActive = (BIS) => {
            activeTab.value = BIS;
        };
        const activeLang = ref("ko");
        const checkedLang = ref(["ko"]);
        const availableLang = ref(["ko", "en", "cn", "jp"]);

        // 언어탭 추가
        const openLangDropDown = ref(false);
        const oLDFocus = ref(null);
        const openoLD = () => {
            if (openLangDropDown.value) {
                openLangDropDown.value = false;
            } else {
                openLangDropDown.value = true;
            }
        };
        const findAvailable = (lang) => {
            const bool = checkedLang.value.find((avail) => avail == lang);
            if (bool == lang) {
                return true;
            } else {
                return false;
            }
        };
        const checkAvailableLang = (lang) => {
            if (lang == activeLang.value) {
                alert("액티브된 탭은 삭제할 수 없습니다.");
            } else {
                if (findAvailable(lang)) {
                    const newLang = checkedLang.value.filter((_lang) => _lang !== lang);
                    checkedLang.value = newLang;
                } else {
                    checkedLang.value.push(lang);
                    activeLang.value = lang;
                }
            }
        };
        const alignedLang = computed(() => {
            return checkedLang.value.sort((a, b) => {
                if (a == "ko" || b == "jp" || (a == "en" && b != "ko") || (a != "jp" && b == "cn")) {
                    // ko가 배열 뒤쪽에 있거나
                    // jp가 배열 앞쪽에 있거나
                    // en이 앞으로 갈지 정할 때 앞에 ko가 아니거나 (cn, jp면 앞으로)
                    // cn이 뒤로 갈지 정할 때 뒤에 jp가 아니거나 (en, ko면 뒤로)
                    // 위 네가지 중 하나면 비교군 둘이 스위치
                    return -1;
                } else {
                    return 0;
                }
            });
        });

        // 설문구조 리스트
        const activeList = computed(() => {
            if (activeTab.value == "INTERPRETER") {
                return surveyIList.value;
            } else if (activeTab.value == "BUYER") {
                return surveyBList.value;
            } else if (activeTab.value == "SELLER") {
                return surveySList.value;
            }
        });
        const emptyBool = computed(() => {
            // 각 리스트 비었는지 확인
            if (activeList.value.length < 1) {
                return true;
            } else {
                return false;
            }
        });
        const { height } = props.window;
        const selected = ref({
            INTERPRETER: [],
            SELLER: [],
            BUYER: [],
        }); // 모달에서 지금까지 선택된 공통질문. 부모, 모달 수정 모두 반영
        let calledSelected = {
            INTERPRETER: [],
            SELLER: [],
            BUYER: [],
        }; // 모달에서 수정된 내용 디텍용 공통질문 배열. 부모 수정만 반영
        const surveyIList = ref([]); // 통역사 설문구조
        const surveyBList = ref([]); // 바이어 설문구조
        const surveySList = ref([]); // 셀러 설문구조
        const readMasterToQuestionList = async (BIS, event = masterId.value) => {
            // BIS 별 질문 받아오기
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/consultation/read_piece_to_question_list`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        master_event_id: Number(event),
                        mtc_type: BIS,
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    // 결과값 선가공 후 ref 데이터에 집어넣는다.)
                    if (BIS == "INTERPRETER") {
                        surveyIList.value = response.data.question_list;
                        getOptionList(surveyIList.value);
                    } else if (BIS == "BUYER") {
                        surveyBList.value = response.data.question_list;
                        getOptionList(surveyBList.value);
                    } else if (BIS == "SELLER") {
                        surveySList.value = response.data.question_list;
                        getOptionList(surveySList.value);
                    }
                    response.data.question_list.forEach((item) => {
                        if (item.is_one_time == 0) {
                            // 공통질문이면
                            selected.value[BIS].push(item.cq_id);
                            calledSelected[BIS].push(item.cq_id);
                        }
                    });
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        };

        const readCheckQuestionList = async (BIS) => {
            // 새로운 폼 추가 submit 후 질문들 호출
            let scopedSelect = []; // 부모에 저장되어있던거는 빼고 모달 수정된것만 호출해서
            selected.value[BIS].forEach((s) => {
                if (!calledSelected[BIS].includes(s)) {
                    scopedSelect.push(s);
                    calledSelected[BIS].push(s);
                }
            });
            // selected로 리스트 만들기
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                let url = `/consultation/read_check_question_list?`;
                scopedSelect.forEach((s) => {
                    url += `cq_id_list=${s}&`;
                });
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    response.data.question_list.map((q, index) => {
                        q.mtc_title = q.cq_title;
                        q.mtc_answer_form = q.cq_answer_form;
                        q.is_must = q.is_essential;
                        q.is_one_time = !q.is_essential;
                        q.is_sent_email = 0;
                        q.cq_is_essential = q.is_essential;
                        q.sort = index;
                        q.option_list = [];
                        q.next_action = {};
                    });

                    if (BIS == "INTERPRETER") {
                        surveyIList.value = [...surveyIList.value, ...response.data.question_list];
                    } else if (BIS == "BUYER") {
                        surveyBList.value = [...surveyBList.value, ...response.data.question_list];
                    } else if (BIS == "SELLER") {
                        surveySList.value = [...surveySList.value, ...response.data.question_list];
                    }
                    getOptionList(activeList.value);
                    getNextAction(activeList.value);
                } else {
                    alert("리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        };

        // options_list 만듦
        const getOptionList = (paramList) => {
            paramList.map((q, index) => {
                q.option_list = [];
                if (index < paramList.length - 1) {
                    paramList.forEach((qq, idx) => {
                        let option_name = "";
                        if (idx > index) {
                            if (idx == index + 1) {
                                option_name = `${idx + 1}. 다음 선택지로 넘어가기`;
                                q.option_list.push({
                                    key: "next",
                                    name: option_name,
                                });
                            } else {
                                option_name = `${idx + 1}. ${qq.mtc_title[activeLang.value]}`;
                                q.option_list.push({
                                    key: idx + 1,
                                    name: option_name,
                                });
                            }
                            if (idx == paramList.length - 1) {
                                q.option_list.push({
                                    key: "finish",
                                    name: `상담일지 끝내기`,
                                });
                            }
                        }
                    });
                } else {
                    q.option_list.push({
                        key: "finish",
                        name: `상담일지 끝내기`,
                    });
                }
            });
        };

        // next_action 만듦
        const getNextAction = (paramList) => {
            paramList.map((q, index) => {
                q.next_action = {};
                if (index < paramList.length - 1) {
                    if (q.cq_type == "SINGLE") {
                        Object.keys(q.mtc_answer_form).forEach((key) => {
                            q.next_action[key] = q.option_list[0].key;
                        });
                    } else {
                        q.next_action["0"] = q.option_list[0].key;
                    }
                } else {
                    if (q.cq_type == "SINGLE") {
                        Object.keys(q.mtc_answer_form).forEach((key) => {
                            q.next_action[key] = q.option_list[0].key;
                        });
                    } else {
                        q.next_action["0"] = q.option_list[0].key;
                    }
                }
            });
        };

        //sort 변경
        const getSort = (paramList) => {
            paramList.map((q, index) => {
                q.sort = index;
            });
        };

        // 폼 변경, 검사
        const surveyChange = (index, key, value, lang = "") => {
            if (value == "bool") {
                // 불리엔 토글 모드
                if (!activeList.value[index][key]) {
                    activeList.value[index][key] = 1;
                } else {
                    activeList.value[index][key] = 0;
                }
            } else {
                activeList.value[index][key][lang] = value;
            }
        };

        const surveyFormChange = (index, idx, lang, value) => {
            activeList.value[index]["mtc_answer_form"][idx][lang] = value;
        };

        const surveyDelete = (item, index) => {
            if (confirm("질문을 삭제 후 저장하면 복구할 수 없습니다. 진행하시겠습니까?")) {
                // 각 질문 지움
                if (item.cq_id != "") {
                    // 공통질문이면 modal로 내려가는 selected 변경
                    const newSelected = selected.value[activeTab.value].filter((_item) => _item !== item.cq_id);
                    selected.value[activeTab.value] = newSelected;
                    // 반응형 데이터와 비반응형 데이터 따로 나눠서 할당
                    const newCalled = calledSelected[activeTab.value].filter((index) => index !== item.cq_id);
                    calledSelected[activeTab.value] = newCalled;
                }
                activeList.value.splice(index, 1);
                getOptionList(activeList.value);
                getNextAction(activeList.value);
            }
        };

        const actionChange = (index, idx, selected) => {
            activeList.value[index].next_action[idx] = selected;
        };

        // 팝업
        const showAddType = ref(false);
        const showNewQuestion = ref(false);
        const showLoadQuestion = ref(false);
        const showPreview = ref(false);
        const showOrderQuestion = ref(false);

        const closeAllModals = () => {
            showAddType.value = false;
            showNewQuestion.value = false;
            showLoadQuestion.value = false;
            showOrderQuestion.value = false;
            showPreview.value = false;
        };

        const modalTypeChange = (props, value) => {
            // 팝업 내에서 props 변경된 경우 emit으로 호출
            if (props == "showAddType") {
                showAddType.value = value;
            } else if (props == "selected") {
                selected.value[activeTab.value] = value;
                readCheckQuestionList(activeTab.value);
            } else if (props == "activeList") {
                if (activeTab.value == "INTERPRETER") {
                    surveyIList.value = value;
                } else if (activeTab.value == "BUYER") {
                    surveyBList.value = value;
                } else if (activeTab.value == "SELLER") {
                    surveySList.value = value;
                }
                getSort(activeList.value);
                getOptionList(activeList.value);
                getNextAction(activeList.value);
            } else if (props == "eventSelected") {
                readMasterToQuestionList(activeTab.value, value);
            }
        };

        // 질문 추가
        const openQuestionDropDown = ref(false);
        const oQDFocus = ref(null);

        const openoQD = () => {
            if (openQuestionDropDown.value == true) {
                return false;
            } else {
                oQDFocus.value.focus();
            }
        };

        const blurCloseoQD = () => {
            // 드롭다운 자신 눌렀을때 닫히도록 타임아웃 걸기
            // 타임아웃 없으면 자신 눌렀을 때 닫기는 척하다 다시 켜짐
            setTimeout(() => {
                openQuestionDropDown.value = false;
            }, 90);
        };

        const modalPush = (question) => {
            const new_index = {
                cq_answer_form: question.mtc_answer_form,
                cq_id: "",
                cq_title: question.mtc_title,
                cq_type: question.cq_type,
                is_essential: 0,
                is_must: 1,
                is_one_time: 1,
                is_sent_email: 0,
                lang: Object.keys(question.mtc_title),
                list_title: question.mtc_title["ko"],
                next_action: {},
                option_list: {},
                mtc_answer_form: question.mtc_answer_form,
                mtc_title: question.mtc_title,
                sort: activeList.value.length,
            };
            activeList.value.push(new_index);
            getOptionList(activeList.value);
            getNextAction(activeList.value);
        };

        const cancelDirection = () => {
            // 취소 버튼 누르면 새로고침 해서 변경 정보 날리기
            // 추후 새로고침 대신 데이터 초기화로 변경 필요할지?
            if (confirm("확인을 누르시면 수정내용이 초기화 됩니다. 초기화하시겠습니까?")) {
                location.reload();
            }
        };

        const submitForm = async () => {
            // create 모두 끝나고 나면 콜백으로 있는 애들 검사해서 수정
            deleteMasterToQuestion();
        };

        const readOriginQuestions = async (type) => {
            // db에 있는 질문 불러오기
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/consultation/read_piece_to_question_list`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        master_event_id: masterId.value,
                        mtc_type: type,
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    return response.data.question_list;
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        };

        const validateUpdate = async () => {
            // db에 있는 질문이랑 다른거 꼽아내기
            const [i_result, s_result, b_result] = await Promise.all([readOriginQuestions("INTERPRETER"), readOriginQuestions("SELLER"), readOriginQuestions("BUYER")]);
            const origin = [...i_result, ...s_result, ...b_result];
            const update = [...surveyIList.value, ...surveyBList.value, ...surveySList.value];
            const mtcList = [];
            const deleteList = [];

            if (origin.length > 0) {
                origin.forEach((ori) => {
                    let upd = update.find((item) => item.mtc_id == ori.mtc_id);
    
                    if (upd == undefined) {
                        deleteList.push(ori.mtc_id);
                        return false;
                    }
    
                    Object.keys(ori["mtc_title"]).forEach((key) => {
                        if (ori["mtc_title"][key] != upd["mtc_title"][key]) {
                            mtcList.push({
                                mtc_id: upd.mtc_id,
                                mtc_title: upd.mtc_title,
                                mtc_answer_form: upd.mtc_answer_form,
                                is_must: upd.is_must,
                                is_sent_email: upd.is_sent_email,
                                next_action: upd.next_action,
                            });
                            return false;
                        }
                    });
    
                    if (validateCqType(ori["cq_type"])) {
                        for (const [key, value] of Object.entries(ori["mtc_answer_form"])) {
                            for (const [key2, value2] of Object.entries(value)) {
                                if (value2 != upd["mtc_answer_form"][key][key2]) {
                                    mtcList.push({
                                        mtc_id: upd.mtc_id,
                                        mtc_title: upd.mtc_title,
                                        mtc_answer_form: upd.mtc_answer_form,
                                        is_must: upd.is_must,
                                        is_sent_email: upd.is_sent_email,
                                        next_action: upd.next_action,
                                    });
                                    return false;
                                }
                            }
                        }
                    }
    
                    for (const [key, value] of Object.entries(ori["next_action"])) {
                        if (value != upd["next_action"][key]) {
                            mtcList.push({
                                mtc_id: upd.mtc_id,
                                mtc_title: upd.mtc_title,
                                mtc_answer_form: upd.mtc_answer_form,
                                is_must: upd.is_must,
                                is_sent_email: upd.is_sent_email,
                                next_action: upd.next_action,
                            });
                            return false;
                        }
                    }
    
                    if (ori["is_must"] != upd["is_must"]) {
                        mtcList.push({
                            mtc_id: upd.mtc_id,
                            mtc_title: upd.mtc_title,
                            mtc_answer_form: upd.mtc_answer_form,
                            is_must: upd.is_must,
                            is_sent_email: upd.is_sent_email,
                            next_action: upd.next_action,
                        });
                        return false;
                    }
    
                    if (ori["is_sent_email"] != upd["is_sent_email"]) {
                        mtcList.push({
                            mtc_id: upd.mtc_id,
                            mtc_title: upd.mtc_title,
                            mtc_answer_form: upd.mtc_answer_form,
                            is_must: upd.is_must,
                            is_sent_email: upd.is_sent_email,
                            next_action: upd.next_action,
                        });
                        return false;
                    }
                });
            }

            return { mtcList, deleteList };
        };

        const deleteMasterToQuestion = async () => {
            const deleteList = await validateUpdate();
            // console.log("deleteMasterToQuestion started");
            // console.log(deleteList);
            if (deleteList.deleteList.length > 0) {
                try {
                    // Axios 요청을 보냅니다.
                    let url = `/consultation/delete_piece_to_question`;
                    // Axios 요청을 보냅니다.
                    const response = await axios.delete(
                        url,
                        {
                            data: {
                                master_event_id: masterId.value,
                                mtc_id_list: deleteList.deleteList,
                            },
                            headers: {
                                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                            },
                        }
                    );
    
                    // 요청이 성공했고 결과가 true인 경우
                    if (response.data.result === true) {
                        return true;
                    } else {
                        alert("질문을 삭제하지 못했습니다.");
                    }
                } catch (error) {
                    console.error("질문 삭제하는 중에 오류가 발생했습니다:", error);
                } finally {
                    updateMasterToQuestion();
                }
            } else {
                updateMasterToQuestion();
            }
        };

        const updateMasterToQuestion = async () => {
            const mtcList = await validateUpdate();
            if (mtcList.mtcList.length > 0) {
                try {
                    // Axios 요청을 보냅니다.
                    const response = await axios.put(
                        "/consultation/update_master_to_question",
                        {
                            master_event_id: masterId.value,
                            mtc_list: mtcList.mtcList,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                            },
                        }
                    );
    
                    // 요청이 성공했고 결과가 true인 경우
                    if (response.data.result === true) {
                        return true;
                    } else {
                        alert("설문을 수정하지 못했습니다.");
                    }
                } catch (error) {
                    console.error("설문을 수정하는 중에 오류가 발생했습니다:", error);
                } finally {
                    promiseOrdered();
                }
            } else {
                promiseOrdered();
            }
        };

        const promiseOrdered = async () => {
            Promise.all([updateMasterToQuestionOrder("INTERPRETER"), updateMasterToQuestionOrder("SELLER"), updateMasterToQuestionOrder("BUYER")]).then(([i_result, s_result, b_result]) => {
                if (i_result == true && s_result == true && b_result == true) {
                    promiseCreate();
                } else {
                    alert(`${i_result}\n${s_result}\n${b_result}`);
                }
            });
        }

        const updateMasterToQuestionOrder = async type => {
            const mtcOrderList = [];
            let updateList = [];
            if (type == 'INTERPRETER') {
                updateList = surveyIList.value;
            } else if (type == 'SELLER') {
                updateList = surveySList.value;
            } else if (type == 'BUYER') {
                updateList = surveyBList.value;
            }

            updateList.forEach((item) => {
                // console.log(item.mtc_id);
                if (item.mtc_id != "" && item.mtc_id != undefined) {
                    mtcOrderList.push({
                        mtc_id: item.mtc_id,
                        sort: item.sort,
                    });
                }
            });

            if (mtcOrderList.length > 0) {
                try {
                    // Axios 요청을 보냅니다.
                    const response = await axios.put(
                        "/consultation/update_master_to_question_order",
                        {
                            master_event_id: masterId.value,
                            mtc_order_list: mtcOrderList
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                            },
                        }
                    );
    
                    // 요청이 성공했고 결과가 true인 경우
                    if (response.data.result === true) {
                        return true;
                    } else {
                        return false;
                    }
                } catch (error) {
                    return error;
                }
            } else {
                return true;
            }
        };

        const promiseCreate = () => {
            Promise.all([createMasterToQuestionList("INTERPRETER"), createMasterToQuestionList("SELLER"), createMasterToQuestionList("BUYER")]).then(([i_result, s_result, b_result]) => {
                if (i_result == true && s_result == true && b_result == true) {
                    alert("설문구조 수정이 완료되었습니다.");
                    location.reload();
                } else {
                    alert(`${i_result}\n${s_result}\n${b_result}`);
                }
            });
        }

        const createMasterToQuestionList = async (type) => {
            let createList = null;
            if (type == "INTERPRETER") {
                createList = surveyIList.value;
            } else if (type == "BUYER") {
                createList = surveyBList.value;
            } else if (type == "SELLER") {
                createList = surveySList.value;
            }
            let request_fq = [];
            let request_oq = [];
            if (createList.length > 0) {
                createList.forEach((item) => {
                    if (item.mtc_id == "" || item.mtc_id == undefined) {
                        if (item.cq_is_essential == 0 || item.cq_is_essential == undefined) {
                            request_oq.push({
                                sort: item.sort,
                                is_must: item.is_must,
                                is_sent_email: item.is_sent_email,
                                next_action: item.next_action,
                                cq_type: item.cq_type,
                                mtc_title: item.mtc_title,
                                mtc_answer_form: Object.assign({}, item.mtc_answer_form),
                            });
                        } else {
                            request_fq.push({
                                sort: item.sort,
                                is_must: item.is_must,
                                is_sent_email: item.is_sent_email,
                                next_action: item.next_action,
                                cq_id: item.cq_id,
                                mtc_title: item.mtc_title,
                                mtc_answer_form: Object.assign({}, item.mtc_answer_form),
                            });
                        }
                    }
                });
                console.log(request_fq, request_oq);
                try {
                    // 전체 URL에 쿼리 스트링을 추가합니다.
                    let url = `/consultation/create_piece_to_question_list`;
                    // Axios 요청을 보냅니다.
                    const response = await axios.post(
                        url,
                        {
                            request_fq: request_fq,
                            request_oq: request_oq,
                            master_event_id: masterId.value,
                            mtc_type: type,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                            },
                        }
                    );

                    // 요청이 성공했고 결과가 true인 경우
                    if (response.data.result === true) {
                        return true;
                    } else {
                        return false;
                    }
                } catch (error) {
                    return error;
                }
            } else {
                return true;
            }
        };

        onMounted(async () => {
            if (checkPermissionsAndRedirect()) {
                await loadEventDetails();
            }
        });

        const checkPermissionsAndRedirect = () => {
            if (!isAuthenticated.value) {
                router.push({ name: "home" }); // 비인증 사용자를 홈으로 리다이렉션
                return false;
            } else if (accountType.value !== "A1" && accountType.value !== "A2") {
                alert("접근 권한이 없습니다.");
                router.push({ name: "eventList" }); // 권한이 없는 사용자를 이벤트 리스트로 리다이렉션
                return false;
            }
            return true;
        };

        const loadEventDetails = async () => {
            userName.value = store.state.userStore.userName;
            token.value = store.state.userStore.token;
            eventId.value = router.currentRoute.value.params.eventId;
            store.commit("eventStore/setEventId", eventId.value);
            masterId.value = store.state.eventStore.masterId;

            if (masterId.value !== '') {
                try {
                    await readMasterToQuestionList("BUYER");
                    await readMasterToQuestionList("INTERPRETER");
                    await readMasterToQuestionList("SELLER");
                } catch (error) {
                    console.error("Error loading event details:", error);
                    alert("이벤트를 불러오지 못했습니다.");
                    router.push("/admin/event/select");
                }
            }
        };

        return {
            // 공통
            eventId, // 피스 상담회 pk
            masterId, // 마스터 상담회 pk
            isAuthenticated, // 페이지 접속 권한
            userName, // 유저 네임
            activeSub, // 행사 설정 탭 액티브
            activeHeadTab, // 설정 세부 탭 액티브
            survey_form, // js import
            validateCqType, // cq_type 단일선택 다중선택 boolean
            // 메뉴 내 탭
            activeTab, // BIS 액티브
            handleActive, // BIS 액티브 함수
            checkedLang, // 추가된 언어페어 리스트
            availableLang, // 모든 언어페어 리스트
            activeLang, // 언어페어 액티브
            // 언어탭 추가
            openLangDropDown, // 언어탭 드롭다운
            oLDFocus, // 드롭다운 포커스용 인풋
            openoLD, // 열고 닫기 함수
            findAvailable, // 드롭다운 내 체크 여부
            checkAvailableLang, // 언어탭 체크
            alignedLang, // 언어탭 체크
            // 설문구조 리스트
            activeList, // activeHeadTab에 따라 surveyXList 반환 컴퓨티드
            emptyBool, // activeList가 비어있는지 boolean
            height, // 설문구조 없을때 높이재서 백그라운드 채우기
            selected, // 새폼에서 선택 된 질문들 emit
            surveyIList, // 통역사 설문구조
            surveyBList, // 바이어 설문구조
            surveySList, // 셀러 설문구조
            surveyChange, // for문 내 mtc_answer_form 제외 모든 필드 수정용 함수
            surveyFormChange, // for문 내 mtc_answer_form 필드 수정용 함수
            surveyDelete, // 질문 삭제 버튼
            actionChange, // nextaction 변경 시 함수
            // 팝업
            showAddType, // 상담일지 폼 설정하기 open boolean
            showNewQuestion, // 새 질문 추가하기  boolean
            showLoadQuestion, // 질문 불러오기  boolean
            showOrderQuestion, // 질문 순서 변경하기 boolean
            showPreview, // 질문 미리보기 boolean
            closeAllModals, // 모든 팝업 닫힘처리
            modalTypeChange, // 팝업 타입 변경 함수
            // 질문 추가
            openQuestionDropDown, // 질문추가 드롭다운
            oQDFocus, // 드롭다운 포커스용 인풋
            openoQD, // 열고 닫기 함수
            blurCloseoQD, // 블러 조작 함수
            modalPush, // 새 질문 activeList 추가 함수
            // 서브밋
            cancelDirection, // 최하단 취소 누를 시 confirm 후 새로고침
            submitForm, // 최하단 저장 버튼
            validateUpdate,
        };
    },
};
</script>
<template>
    <div id="surveySetting" class="back_light_gray2">
        <sub-header :activeSub="activeSub"></sub-header>
        <div class="mx_1160">
            <div class="header_tab_wrap">
                <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
            </div>
            <div class="ds_flex jus_between al_center">
                <div class="bis_tab">
                    <p class="inline_sub_btn" :class="{ active: activeTab == 'INTERPRETER' }" @click="handleActive('INTERPRETER')">통역사</p>
                    <p class="inline_sub_btn" :class="{ active: activeTab == 'BUYER' }" @click="handleActive('BUYER')">바이어</p>
                    <p class="inline_sub_btn" :class="{ active: activeTab == 'SELLER' }" @click="handleActive('SELLER')">셀러</p>
                </div>
                <div v-show="!emptyBool" class="ds_flex gap14">
                    <p
                        class="inline_cont_btn hover_back_btn"
                        @click="
                            showLoadQuestion = false;
                            showPreview = false;
                            showOrderQuestion = true;
                        "
                    >
                        질문 순서 변경하기
                    </p>
                    <p
                        class="icon_cont_btn hover_back_btn"
                        @click="
                            showLoadQuestion = false;
                            showPreview = true;
                            showOrderQuestion = false;
                        "
                    >
                        <i class="icon-preview"></i>구조 미리보기
                    </p>
                </div>
            </div>
            <template v-if="emptyBool">
                <div class="ds_flex dir_column jus_center al_center" :style="`height: ${height - 600}px; min-height: 300px`">
                    <img src="@/assets/img/survey/no_survey.png" />
                    <p class="primary_text size_18 b_700" style="margin-top: 20px">저장된 상담일지 구조가 없어요.</p>
                    <p class="secondary_text t_center" style="margin-top: 16px">질문 항목을 선택 및 추가하여<br />상담회 설문 구조를 설정하세요.</p>
                    <button class="inline_cont_btn active_btn b_700" style="margin-top: 32px" @click="showAddType = true">상담일지 폼 설정하기</button>
                </div>
            </template>
            <template v-else>
                <div class="lang_tab">
                    <p v-for="lang in alignedLang" :key="lang" :class="[activeLang == lang ? 'active' : '', 'csP']" @click="activeLang = lang">{{ survey_form.return_language(lang) }}</p>
                    <p class="plus fake_dropdown_wrap">
                        <i class="icon-plus" @click="openoLD"></i>
                        <transition name="dropdown">
                            <div class="fake_dropdown_options" v-if="openLangDropDown" style="width: 140px; top: 0; left: 100%">
                                <div v-for="lang in availableLang" class="fake_option csP" :key="lang">
                                    <label class="fake_checkbox" :for="`check_for_${lang}`" :class="{ active: findAvailable(lang) }">
                                        <i v-if="findAvailable(lang)" class="icon-checked white_text"></i>
                                    </label>
                                    <label class="fake_label" :for="`check_for_${lang}`">{{ survey_form.return_language(lang) }}</label>
                                    <input type="checkbox" :id="`check_for_${lang}`" :name="`check_for_${lang}`" @change="checkAvailableLang(lang)" />
                                </div>
                                <div class="dropdown_btn_wrap">
                                    <div class="dropdown_submit active_btn" @click="openLangDropDown = false">완료</div>
                                </div>
                            </div>
                        </transition>
                    </p>
                </div>
                <div class="survey_flow" :class="activeLang == alignedLang[0] ? 'border' : ''">
                    <div v-for="(item, index) in activeList" :key="index" class="survey_flow_inner">
                        <div class="survey_flow_top">
                            <div class="flow_title">
                                <div class="num">{{ index + 1 }}</div>
                                <input type="text" :id="`mtc_title_${index}`" :value="item.mtc_title[activeLang]" @keyup="surveyChange(index, 'mtc_title', $event.target.value, activeLang)" />
                            </div>
                            <div class="flow_check">
                                <div class="flow_check_inner">
                                    <label class="secondary_text csP" :for="`is_sent_email_${index}`">이메일로 전송</label>
                                    <label class="fake_checkbox" :for="`is_sent_email_${index}`" :class="{ active: item.is_sent_email == 1 }">
                                        <i v-if="item.is_sent_email == 1" class="icon-checked"></i>
                                    </label>
                                    <input
                                        type="checkbox"
                                        :id="`is_sent_email_${index}`"
                                        @change="surveyChange(index, 'is_sent_email', 'bool')"
                                        :true-value="1"
                                        :false-value="0"
                                        :value="item.is_sent_email"
                                    />
                                </div>
                                <div class="flow_check_inner" :class="{ disabled: item.cq_is_essential == 1 }">
                                    <label class="secondary_text csP" :for="`is_must_${index}`">필수 질문</label>
                                    <label class="fake_checkbox" :for="`is_must_${index}`" :class="{ active: item.is_must == 1 }">
                                        <i v-if="item.is_must == 1" class="icon-checked"></i>
                                    </label>
                                    <input
                                        type="checkbox"
                                        :id="`is_must_${index}`"
                                        @change="surveyChange(index, 'is_must', 'bool')"
                                        :true-value="1"
                                        :false-value="0"
                                        :value="item.is_must"
                                        :disabled="item.cq_is_essential == 1"
                                    />
                                </div>
                                <div class="flow_delete" @click="surveyDelete(item, index)"><i class="icon-trash size_20 sub_text"></i></div>
                            </div>
                        </div>
                        <div class="survey_flow_bottom">
                            <template v-if="item.cq_type == 'SINGLE'">
                                <div v-for="(itm, idx) in item.mtc_answer_form" :key="idx" class="ds_flex al_center gap36">
                                    <template v-if="item.is_one_time == 1">
                                        <label :for="`mtc_cq_answer_${idx}`" class="current_actions">
                                            <input type="text" :id="`mtc_cq_answer_${idx}`" :value="itm[activeLang]" @keyup="surveyFormChange(index, idx, activeLang, $event.target.value)" />
                                            {{ survey_form.return_cq_type(itm.cq_type) }}
                                            <i :class="`icon-q_${item.cq_type} not_entered_text`"></i>
                                        </label>
                                    </template>
                                    <template v-else>
                                        <div class="current_actions disabled">
                                            <p class="cont">{{ itm[activeLang] }}</p>
                                            <i :class="`icon-q_${item.cq_type} not_entered_text`"></i>
                                        </div>
                                    </template>
                                    <drop-down-next
                                        :options="item.option_list"
                                        :defaultValue="item.next_action[idx]"
                                        :disabled="index == activeList.length - 1"
                                        @change="
                                            (selected) => {
                                                actionChange(index, idx, selected);
                                            }
                                        "
                                    ></drop-down-next>
                                    <div class="delete">
                                        <i class="icon-delete sub_text" :style="item.is_one_time == 1 ? '' : 'color: #fff;'"></i>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="item.cq_type == 'MULTIPLE'">
                                <div v-for="(itm, idx) in item.mtc_answer_form" :key="idx" class="ds_flex al_center gap36">
                                    <template v-if="item.is_one_time == 1">
                                        <label :for="`mtc_title_${idx}`" class="current_actions">
                                            <input type="text" :id="`mtc_title_${idx}`" :value="itm[activeLang]" @keyup="surveyFormChange(index, idx, activeLang, $event.target.value)" />
                                            {{ survey_form.return_cq_type(itm.cq_type) }}
                                            <i :class="`icon-q_${item.cq_type} not_entered_text`"></i>
                                        </label>
                                    </template>
                                    <template v-else>
                                        <div class="current_actions disabled">
                                            <p class="cont">{{ itm[activeLang] }}</p>
                                            <i :class="`icon-q_${item.cq_type} not_entered_text`"></i>
                                        </div>
                                    </template>
                                    <drop-down-next
                                        :options="item.option_list"
                                        :defaultValue="item.next_action[0]"
                                        :disabled="index == activeList.length - 1"
                                        @change="
                                            (selected) => {
                                                actionChange(index, 0, selected);
                                            }
                                        "
                                    ></drop-down-next>
                                    <div class="delete">
                                        <i class="icon-delete sub_text" :style="item.is_one_time == 1 ? '' : 'color: #fff;'"></i>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="ds_flex al_center gap36 not_entered_text">
                                    <div class="current_actions disabled">
                                        <p class="cont">
                                            <template v-if="item.cq_type == 'SHORT' || item.cq_type == 'LONG'">답변을 입력하세요.</template>
                                            <template v-else-if="item.cq_type == 'NUMBER'">숫자를 입력하세요.</template>
                                            <template v-else-if="item.cq_type == 'FILE'">파일을 첨부하세요.</template>
                                        </p>
                                        <i :class="`icon-q_${item.cq_type} not_entered_text`"></i>
                                    </div>
                                    <drop-down-next
                                        :options="item.option_list"
                                        :defaultValue="item.next_action[0]"
                                        :disabled="index == activeList.length - 1"
                                        @change="
                                            (selected) => {
                                                actionChange(index, 0, selected);
                                            }
                                        "
                                    ></drop-down-next>
                                    <div class="delete">
                                        <i class="icon-delete sub_text" :style="item.is_one_time == 1 ? '' : 'color: #fff;'"></i>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="fake_dropdown_wrap" style="width: 140px">
                        <input type="text" ref="oQDFocus" name="dropDownOpener" @focus="openQuestionDropDown = true" @blur="blurCloseoQD()" class="focus_input" />
                        <p class="add_new_question" @click="openoQD">질문 추가 +</p>
                        <transition name="dropdown">
                            <div class="fake_dropdown_options" v-if="openQuestionDropDown">
                                <div class="fake_option" @click="showNewQuestion = true">새 질문 추가하기</div>
                                <div
                                    class="fake_option"
                                    @click="
                                        showLoadQuestion = true;
                                        showPreview = false;
                                        showOrderQuestion = false;
                                    "
                                >
                                    질문 불러오기
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="btn_wrap">
                    <div class="inline_main_btn cancel_btn b_700" @click="cancelDirection">취소</div>
                    <div class="inline_main_btn active_btn b_700" @click="submitForm">저장</div>
                </div>
            </template>
        </div>
        <survey-setting-modal
            :selected="selected"
            :BIS="activeTab"
            :show="{ showAddType: showAddType, showNewQuestion: showNewQuestion, showLoadQuestion: showLoadQuestion, showPreview: showPreview, showOrderQuestion: showOrderQuestion }"
            :checked-lang="alignedLang"
            :activeList="activeList"
            :height="height"
            @close="closeAllModals"
            @change="modalTypeChange"
            @push="modalPush"
        ></survey-setting-modal>
    </div>
</template>
