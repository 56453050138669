<script>
import { ref, reactive, onMounted, computed, inject, watch } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';
import tabHeader from '@/components/admin/tabHeader.vue';
import bisatStatus from '@/assets/js/bisatStatus.json';
import receptionTableModal from '@/components/admin/receptionTableModal.vue';
import country from '@/assets/js/bisatCountry'

export default {
  name: 'reception',
  components: {
    Pagination,
    subHeader,
    tabHeader,
    receptionTableModal,
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');
    const activeSub = ref('reception');
    const activeHeadTab = ref('reception');

    //이벤트 일시 관련 변수
    const eventDateList = ref([]);
    const selectDate = ref(null);

    // PAGINATION 관련 변수
    const currentPage = ref(1);
    const itemsPerPage = ref(10); // 페이지당 아이템 수
    const totalPages = computed(() => Math.ceil(receptionList.value.length / itemsPerPage.value));
    const paginatedReceptionList = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return receptionList.value.slice(start, end);
    });

    const handleClickPage = (pageIndex) => {
      if (pageIndex >= 1 && pageIndex <= totalPages.value) {
        currentPage.value = pageIndex;
      }
    };

    // 모달 관련 변수
    const showStatusModal = ref(false);
    const selectedUserData = ref({});
    const closeAllModals = (type) => {
      showStatusModal.value = false;
    };
    const openOperateModal = (type, userId, pta_id) => {
      selectedUserData.value = { type, userId, pta_id, mode: 'manual' };
      showStatusModal.value = true;
    };

    // 영접 데이터 관련 변수
    const receptionList = ref([]);
    const viewMode = ref('table')


    // FUNCTION BAR ----------------------------------------------
    const getCountryLabel = (value) => {
      const selectedCountry = country.find(item => item.code === value);
      return selectedCountry ? selectedCountry.label : value;
    };
    const onlyCurrentStatus = ref(true);
    const selectedStatuses = ref([]);

    const statusCount = (status) => {
      return paginatedReceptionList.value.filter(reception =>
        reception.status_logs.some(log => log.code_name === status)
      ).length;
    };

    // 선택된 상태에 따라 reception list를 필터링
    const filteredReceptionList = computed(() => {
      if (selectedStatuses.value.length === 0) {
        // 아무것도 선택되지 않았을 경우, 전체 리스트 반환
        return paginatedReceptionList.value;
      }
      // 선택된 상태에 맞는 reception만 필터링하여 반환
      return paginatedReceptionList.value.filter(reception =>
        reception.status_logs.some(log => selectedStatuses.value.includes(log.code_name))
      );
    });

    // 상태 일괄변경 기능
    const changeAllStatusCheck = ref(false);
    const selectAll = ref(false);
    // 각 항목에 대한 선택 상태 관리
    const handleCheckboxChange = (reception) => {
      reception.selected = !reception.selected;
      // 개별 선택에 따라 전체 선택 체크박스 상태 조정
      if (!reception.selected) {
        selectAll.value = false;
      } else if (paginatedReceptionList.value.every(item => item.selected)) {
        selectAll.value = true;
      }
    };

    // "상태 일괄 변경하기" 버튼 클릭 시 호출
    const changeAllStatus = () => {
      changeAllStatusCheck.value = !changeAllStatusCheck.value;
      if (!changeAllStatusCheck.value) {
        // 상태 일괄 변경이 비활성화될 때, selectAll 상태도 초기화
        selectAll.value = false;
        paginatedReceptionList.value.forEach(item => {
          item.selected = false;
        });
      }
    };

    // 전체 선택 토글 함수
    const toggleSelectAll = () => {
      selectAll.value = !selectAll.value;
      paginatedReceptionList.value.forEach(reception => {
        reception.selected = selectAll.value;
      });
    };

    const selectedReceptions = computed(() => {
      return paginatedReceptionList.value.filter(reception => reception.selected);
    });

    const dropDownStatus = ref(false)
    const changeStatusValue = ref('')

    // FUNCTION BAR END ----------------------------------------------

    // STEP1. 이벤트 날짜 리스트 받아오기
    async function fetchDate() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value
        });

        const url = `/schedule/read_date?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateList.value = response.data.date_list;
          selectDate.value = eventDateList.value[0]
          fetchReceptionList()
          fetchStatusLogList();
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    // STEP2. 영접 리스트 
    async function fetchReceptionList() {
      try {
        // 모든 바이어 표시인 경우 날짜 null값으로 전달하면 됨
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: selectDate.value
        });

        const url = `/reception/read_reception_list_table?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          receptionList.value = response.data.reception_list.map(item => {
            // entry_flight_arrival을 날짜와 시간으로 분리하여 새로운 필드에 추가
            let [datePart, timePart] = '';
            let [flightId, flightExit] = '';
            let [flightTerminal, flightGate] = '';
            if (item.entry_flight_arrival) {
              const entryFlightArrival = item.entry_flight_arrival;
              [datePart, timePart] = entryFlightArrival.split(' ');
            }

            if (item.entry_flight_no) {
              const entryFlightNo = item.entry_flight_no;
              [flightId, flightExit] = entryFlightNo.split(' ');
              [flightTerminal, flightGate] = flightExit.split('/');
            }

            return {
              ...item,
              entry_flight_arrival_date: datePart, // 날짜 부분
              entry_flight_arrival_time: timePart, // 시간 부분
              entry_flight_id: flightId,
              entry_flight_exit: flightExit,
              entry_flight_terminal: flightTerminal,
              entry_flight_gate: flightGate
            };
          });
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    // STEP2-1. 상태로그 리스트 
    const statusLogList = ref([])
    async function fetchStatusLogList() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: selectDate.value
        });

        const url = `/reception/read_reception_list_log?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          statusLogList.value = response.data.reception_list
          initializeTooltips()
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    const getIconClass = (type, category, status) => {
      const categoryData = bisatStatus[type][category];
      const statusItem = categoryData.find(item => item.status === status);
      return statusItem ? statusItem.icon : '';
    };
    const getIconName = (type, category, status) => {
      const categoryData = bisatStatus[type][category];
      const statusItem = categoryData.find(item => item.status === status);
      return statusItem ? statusItem.label : '';
    };

    //툴팁
    const tooltipState = ref({}); // 툴팁 상태를 저장할 반응형 객체
    const initializeTooltips = () => {
      const length = statusLogList.value.length;
      for (let index = 0; index < length; index++) {
        tooltipState.value[index] = ref(false); // 각 유저의 툴팁 상태를 ref로 초기화
      }
    };

    const showTooltip = (index) => {
      tooltipState.value[index] = true;
    };

    // 툴팁을 숨기는 메서드
    const hideTooltip = (index) => {
      tooltipState.value[index] = false;
    };

    // float_alert
    const showAlertListForAirline = ref([]);
    const showAlertListForHotel = ref([]);
    const showAlertListForMemo = ref([]);

    const handleInputForAirline = (index) => {
      showAlertListForAirline.value[index] = true;
    };
    const handleInputForHotel = (index) => {
      showAlertListForHotel.value[index] = true;
    };
    const handleInputForMemo = (index) => {
      showAlertListForMemo.value[index] = true;
    };

    // Input Save
    const receptionInputMemo = ref([]);
    const saveNewInput = async (pta_id, index, type) => {
      const url = "/reception/update_buyer_reception_info";
      const hotel = receptionList.value[index].hotel_name
      const memo = receptionInputMemo.value[index]
      let postData = {};

      if (type === 'hotel') {
        postData = {
          pta_id: pta_id,
          hotel_name: hotel
        };
      } else if (type === 'memo') {
        postData = {
          pta_id: pta_id,
          memo: memo
        };
      }

      try {
        const response = await axios.put(url, postData, {
          headers: {
            'Authorization': `Bearer ${token.value}`
          }
        });
        if (response.data.result == true) {
          if (type === 'hotel') {
            alert("호텔명이 저장되었습니다.");
          } else if (type === 'memo') {
            alert("메모가 저장되었습니다.");
          }
          // 새로고침
          router.go();
        } else {
          console.error("Error updating the event:", response.data);
          alert("이벤트 수정 중 오류가 발생했습니다.");
        }
      } catch (error) {
        console.error("Error updating the event:", error);
        alert("이벤트 수정 중 오류가 발생했습니다.");
      }
    }

    // 상태로그뷰 상태
    const getStatusList = computed(() => {
      return bisatStatus.BUYER.qr || [];
    });

    const getStatusLog = (statusLogs, codeName) => {
      return statusLogs.find(log => log.code_name === codeName) || { created_at: '' };
    };

    // 항공편 조회 시 날짜 조건 세팅: 당일 기준 -3~+6일 사이 데이터만 조회 가능
    const today = new Date();

    const formatDate = (date) => {
      let month = '' + (date.getMonth() + 1);
      let day = '' + date.getDate();
      const year = date.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    };

    const minDate = computed(() => {
      const date = new Date(today);
      date.setDate(today.getDate() - 3);
      return formatDate(date);
    });

    const maxDate = computed(() => {
      const date = new Date(today);
      date.setDate(today.getDate() + 6);
      return formatDate(date);
    });

    // 항공편 조회 API
    const flightInfos = reactive([]);
    const isLoading = reactive([]);
    const fetchAirplaneInfo = async (index, flight_date, flight_id, pta_id) => {
      if (!isLoading[index]) {
        isLoading[index] = { show: false };
      }
      isLoading[index].show = true;

      if (!flightInfos[index]) {
        flightInfos[index] = { show: false, flight_info: null };
      }
      flightInfos[index].show = true;

      try {
        const queryParams = new URLSearchParams({
          flight_type: 'ENTRY',
          flight_id: flight_id,
          flight_date: flight_date
        });

        const url = `/reception/read_reception_flight_info?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          showAlertListForAirline.value[index] = false
          flightInfos[index].flight_info = response.data.flight_info;
          saveAirplaneInfo(pta_id, response.data.flight_info)
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      } finally {
        isLoading[index].show = false;
      }
    };

    const saveAirplaneInfo = async (pta_id, flight_info) => {
      const url = "/reception/update_buyer_reception_info";
      let postData = {
        pta_id: pta_id,
        entry_airline: flight_info.airline,
        entry_flight_id: flight_info.flight_id,
        entry_terminal_id: flight_info.terminal_id,
        entry_flight_arrival: flight_info.estimate_time,
        entry_exit_number: flight_info.exit_number,
      };
      try {
        const response = await axios.put(url, postData, {
          headers: {
            'Authorization': `Bearer ${token.value}`
          }
        });
        if (response.data.result == true) {
          // 새로고침
          // router.go();
        } else {
          console.error("Error updating the event:", response.data);
          alert("영접 정보 수정 중 오류가 발생했습니다.");
        }
      } catch (error) {
        console.error("Error updating the event:", error);
        alert("영접 정보 수정 중 오류가 발생했습니다.");
      }
    }
    const getTerminalName = (value) => {
      const terminalNames = {
        'P01': '제1 터미널',
        'P02': '탑승동',
        'P03': '제2 터미널'
      };

      return terminalNames[value] || '알 수 없는 터미널';
    };

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        fetchDate();
        showAlertListForAirline.value = paginatedReceptionList.value.map(() => false);
        showAlertListForHotel.value = paginatedReceptionList.value.map(() => false);
        showAlertListForMemo.value = paginatedReceptionList.value.map(() => false);
        paginatedReceptionList.value.forEach(reception => {
          reception.selected = false;
        });
      }
    });

    return {
      activeSub,
      activeHeadTab,
      eventDateList,
      selectDate,
      paginatedReceptionList,
      currentPage,
      totalPages,
      handleClickPage,
      closeAllModals,
      showStatusModal,
      openOperateModal,
      selectedUserData,
      getIconClass,
      getIconName,
      viewMode,
      handleInputForAirline,
      showAlertListForAirline,
      handleInputForHotel,
      showAlertListForHotel,
      handleInputForMemo,
      showAlertListForMemo,
      receptionInputMemo,
      saveNewInput,
      getStatusList,
      statusLogList,
      getStatusLog,
      minDate,
      maxDate,
      fetchAirplaneInfo,
      flightInfos,
      isLoading,
      getTerminalName,
      getCountryLabel,
      onlyCurrentStatus,
      selectedStatuses,
      bisatStatus,
      statusCount,
      filteredReceptionList,
      selectAll,
      toggleSelectAll,
      selectedReceptions,
      changeAllStatusCheck,
      handleCheckboxChange,
      changeAllStatus,
      tooltipState,
      showTooltip,
      hideTooltip,
      dropDownStatus,
      changeStatusValue
    };
  },
}

</script>

<template>
  <div id="reception">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="wide_wrap">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
        <p class="inline_cont_btn">
          <router-link custom v-slot="{ navigate, href }" :to="{ name: 'receptionQr' }">
            <a :href="href" target="_blank" @click="navigate" class="main_text">QR코드 촬영</a>
          </router-link>
        </p>
      </div>
      <div class="tables_wrap">
        <div class="function_bar">
          <div class="row">
            <select v-model="selectDate">
              <option v-for="(item, index) in eventDateList" :key="index" :value="item">{{ item }}</option>
            </select>
            <div class="cont_wrap ds_flex gap18 al_center">
              <p class="icon_box"><i class="icon-download"></i></p>
              <!-- TODO: 검색어 기능 추가 예정 -->
              <p class="fake_textbox"><input type="text" placeholder="검색어" /><i class="icon-search"></i></p>
            </div>
          </div>
          <div class="row">
            <div class="reception_function_view_wrap">
              <p class="reception_function_view_cont inline_cont_btn" :class="{ 'active': viewMode == 'table' }"
                @click="viewMode = 'table'">
                테이블 뷰
              </p>
              <p class="reception_function_view_cont inline_cont_btn" :class="{ 'active': viewMode == 'log' }"
                @click="viewMode = 'log'">
                상태로그 뷰
              </p>
            </div>
            <div class="">
              <p class="inline_cont_btn change_all_status" @click="changeAllStatus" v-if="!changeAllStatusCheck">상태 일괄
                변경하기</p>
            </div>
          </div>
          <div class="row">
            <template v-if="!changeAllStatusCheck">
              <div class=" ds_flex gap8 al_center">
                <label class="size_14 secondary_text">상태로그 필터</label>
                <div class="status_wrap ds_flex gap10">
                  <div class="ds_flex al_center">
                    <label for="onlyCurrentStatus" class="fake_checkbox" :class="{ 'active': onlyCurrentStatus }">
                      <i v-if="onlyCurrentStatus" class="icon-checked"></i>
                    </label>
                    <input type="checkbox" id="onlyCurrentStatus" v-model="onlyCurrentStatus" />
                    <label for="onlyCurrentStatus" :class="{ 'active': onlyCurrentStatus }">현재 상태만 보기</label>
                  </div>
                  <div v-for="(item, index) in bisatStatus.BUYER.reception" :key="index" class="ds_flex al_center">
                    <!-- 체크박스의 active 상태를 올바르게 처리 -->
                    <label :for="'checkbox-' + item.status" class="fake_checkbox"
                      :class="{ 'active': selectedStatuses.includes(item.status) }">
                      <i v-if="selectedStatuses.includes(item.status)" class="icon-checked"></i>
                    </label>
                    <input type="checkbox" :id="'checkbox-' + item.status" :value="item.status"
                      v-model="selectedStatuses" />
                    <label :for="'checkbox-' + item.status"
                      :class="{ 'active': selectedStatuses.includes(item.status) }">{{ item.label }} {{
                        statusCount(item.status) }}</label>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div v-if="changeAllStatusCheck" class="ds_flex jus_between al_center change_all_status_info">
          <p class="size_14 secondary_text">상태를 변경할 바이어를 선택하세요.</p>
          <div class="change_all_status_wrap ds_flex al_center gap12">
            <p class="size_14 eqqui_text b_700">{{ }}명</p>
            <div>
              <p class="inline_cont_btn" @click="dropDownStatus = true">변경할 상태 선택</p>
              <div class="dropdown_status_wrap" v-if="dropDownStatus">
                <label :for="item.status" class="ds_flex gap8 al_center dropdown_status_cont"
                  v-for="item in bisatStatus.BUYER.reception" :key="item.status" :class="{'active': item.status === changeStatusValue}">
                  <input type="radio" :value="item.status" :id="item.status" name="status" v-model="changeStatusValue"/>
                  <i :class="`icon-${item.icon}`"></i>
                  <span class="size_14">{{ item.label }}</span>
                </label>
                <div class="btn_wrap ds_flex jus_between al_center">
                  <p class="cancel">취소</p>
                  <p class="submit">변경하기</p>
                </div>
              </div>
            </div>
            <p @click="changeAllStatus" class="size_12 sub_text pointer">취소</p>
          </div>
        </div>
        <div class="table_wrap">
          <table v-if="viewMode === 'table'" class="table_view">
            <thead>
              <tr>
                <th v-if="changeAllStatusCheck">
                  <label class="fake_checkbox" for="selectAll" :class="{ 'active': selectAll }">
                    <i v-if="selectAll" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" id="selectAll" v-model="selectAll" />
                </th>
                <th>바이어</th>
                <th>국가</th>
                <th>항공편</th>
                <th>호텔명</th>
                <th>상태로그</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="filteredReceptionList.length > 0">
                <tr v-for="(reception, index) in filteredReceptionList" :key="index">
                  <td v-if="changeAllStatusCheck">
                    <label class="fake_checkbox" :for="'select_' + reception.pta_id"
                      :class="{ 'active': reception.selected }">
                      <i v-if="reception.selected" class="icon-checked"></i>
                    </label>
                    <input type="checkbox" :id="'select_' + reception.pta_id" v-model="reception.selected"
                      @change="handleCheckboxChange(reception)" />
                  </td>
                  <td class="name" @click="openOperateModal('BUYER', reception.account_id, reception.pta_id)">
                    <div class="status_wrap">
                      <i :class="`icon-${getIconClass('BUYER', 'logs', reception.current_status)}`" class="size_36"></i>
                      <div class="cont">
                        <p class="b_700">{{ reception.company }}</p>
                        <p class="buyer_name">{{ reception.full_name }}</p>
                      </div>
                    </div>
                  </td>
                  <td>{{ getCountryLabel(reception.country) }}</td>
                  <td class="airline">
                    <div v-if="isLoading[index]?.show || flightInfos[index]?.show || reception.entry_flight_arrival"
                      class="flight_info">
                      <template v-if="isLoading[index]?.show">
                        데이터를 불러오는 중입니다.
                      </template>
                      <template v-else-if="flightInfos[index]?.flight_info">
                        <p class="size_14">{{ flightInfos[index].flight_info.airline }}</p>
                        <p class="size_12 secondary_text">{{ getTerminalName(flightInfos[index].flight_info.terminal_id)
                          }} | GATE {{
                            flightInfos[index].flight_info.exit_number }}</p>
                        <p class="size_14">{{ flightInfos[index].flight_info.estimate_time }}</p>
                      </template>
                      <template v-else-if="reception.entry_flight_arrival">
                        <p class="size_14">{{ reception.entry_airline }}</p>
                        <p class="size_12 secondary_text">{{ getTerminalName(reception.entry_flight_terminal) }} | GATE
                          {{ reception.entry_flight_gate }}</p>
                        <p class="size_14">{{ reception.entry_flight_arrival_date }} {{
                          reception.entry_flight_arrival_time }}</p>
                      </template>
                    </div>
                    <div class="fake_textbox" v-if="!reception.entry_flight_arrival">
                      <label>날짜</label>
                      <input type="date" :min="minDate" :max="maxDate" v-model="reception.entry_flight_arrival_date" />
                    </div>
                    <div class="fake_textbox">
                      <label>편명</label>
                      <input type="text" v-model="reception.entry_flight_id" @input="handleInputForAirline(index)"
                        @keyup.enter="fetchAirplaneInfo(index, reception.entry_flight_arrival_date, reception.entry_flight_id, reception.pta_id)" />
                    </div>
                    <transition name="fade-alert">
                      <p class="float_alert">
                        엔터를 눌러 저장</p>
                    </transition>
                    <!-- <div v-if="isLoading[index]?.show || flightInfos[index]?.show || reception.entry_flight_arrival"
                      class="flight_info">
                      <template v-if="isLoading[index]?.show">
                        데이터를 불러오는 중입니다.
                      </template>
                      <template v-else-if="flightInfos[index]?.flight_info">
                        <p>{{ flightInfos[index].flight_info.airline }}</p>
                        <p>{{ flightInfos[index].flight_info.flight_id }}</p>
                        <p>{{ flightInfos[index].flight_info.estimate_time }}</p>
                      </template>
                      <template v-else-if="reception.entry_flight_arrival">
                        <p>{{ reception.entry_airline }}</p>
                        <p>{{ reception.entry_flight_no }}</p>
                        <p>{{ reception.entry_flight_arrival_date }}</p>
                        <p>{{ reception.entry_flight_arrival_time }}</p>
                      </template>
                    </div> -->
                  </td>
                  <td>
                    <input type="text" class="fake_textbox" v-model="reception.hotel_name" placeholder="호텔명/호실"
                      @input="handleInputForHotel(index)"
                      @keyup.enter="saveNewInput(reception.pta_id, index, 'hotel')" />
                    <transition name="fade-alert">
                      <p v-if="showAlertListForHotel[index]" class="float_alert">엔터를 눌러 저장</p>
                    </transition>
                  </td>
                  <td class="status_log">
                    <template v-if="reception.status_logs.length > 0">
                      <template v-if="onlyCurrentStatus">
                        <div>
                          <p class="icon_box" @mouseover="showTooltip(index)" @mouseleave="hideTooltip(index)">
                            <i class=""
                              :class="`icon-${getIconClass('BUYER', 'logs', reception.status_logs[0].code_name)}`"></i>
                            <label>{{ getIconName('BUYER', 'logs', reception.status_logs[0].code_name) }}</label>
                          </p>
                          <span class="size_12 sub_text">{{ reception.status_logs[0].created_at }}</span>
                          <span class="tooltip" :class="{ 'active': tooltipState[index] }">{{
                            reception.status_logs[0].created_by }}/{{ reception.status_logs[0].created_how === 'QR' ?
                            'QR변경' : '수동변경' }}</span>
                        </div>
                      </template>
                      <template v-else>
                        <div v-for="(status, index) in reception.status_logs">
                          <p class="icon_box" @mouseover="showTooltip(index)" @mouseleave="hideTooltip(index)">
                            <i class="" :class="`icon-${getIconClass('BUYER', 'logs', status.code_name)}`"></i>
                            <label>{{ getIconName('BUYER', 'logs', status.code_name) }}</label>
                          </p>
                          <span class="size_12 sub_text">{{ status.created_at }}</span>
                          <span class="tooltip" :class="{ 'active': tooltipState[index] }">{{ status.created_how
                            }}</span>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </td>
                  <td class="memo">
                    <input type="text" class="fake_textbox" v-model="receptionInputMemo[index]"
                      @input="handleInputForMemo(index)" @keyup.enter="saveNewInput(reception.pta_id, index, 'memo')" />
                    <transition name="fade-alert">
                      <p v-if="showAlertListForMemo[index]" class="float_alert">엔터를 눌러 저장</p>
                    </transition>
                    <div class="memo_log_wrap">
                      <p v-for="(memo, index) in reception.memo" :key="index">
                        <i class="icon-talk"></i>
                        <span>{{ memo }}</span>
                      </p>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td>정보 없음</td>
                </tr>
              </template>
            </tbody>
          </table>
          <table v-else-if="viewMode === 'log'" class="log_view">
            <thead>
              <tr>
                <th>
                  <p>바이어</p>
                </th>
                <th v-for="status in getStatusList" :key="status.status">
                  <p>
                    <i :class="`icon-${status.icon}`"></i>
                    {{ status.label }}
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="list in statusLogList" :key="list.account_id">
                <td class="name">
                  <div class="status_wrap">
                    <p class="company_name">{{ list.company }}</p>
                  </div>
                  <p class="buyer_name">{{ list.full_name }}</p>
                </td>
                <td class="status" v-for="status in getStatusList" :key="status.status"
                  :class="{ 'no_status': !getStatusLog(list.status_logs, status.status).created_by }">
                  <template v-if="getStatusLog(list.status_logs, status.status).created_by">
                    <p class="schedule_info">{{ getStatusLog(list.status_logs, status.status).created_at.split(' ')[0]
                      }}</p>
                    <p class="status_time"><i :class="`icon-${status.icon}`"></i>{{ getStatusLog(list.status_logs,
                      status.status).created_at.split(' ')[1] }}</p>
                    {{ getStatusLog(list.status_logs, status.status).created_by }}
                  </template>
                  <template v-else></template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination :currentPage="currentPage" :totalPages="totalPages" :handleClickPage="handleClickPage" />
    </div>
    <reception-table-modal :show-status-modal="showStatusModal" @close="closeAllModals"
      :selected-user-data="selectedUserData"></reception-table-modal>
  </div>
</template>
