// 유저 정보에 대해 저장하는 store
import router from '@/router'

const userStore = {
    namespaced: true,
    state: () => ({
        isAuthenticated: false,
        email: '',
        accountId: '',
        accountType: '',
        userName: '',
        token: '',
        multitype_user: false // 다중 사용자 유형 여부를 나타내는 상태 변수
    }),
    mutations: {
        login(state, status) {
            state.isAuthenticated = status;
          },
        login_info: function (state, payload) {
            state.email = payload.email
            state.accountId = payload.account_id
            state.accountType = payload.account_type
            state.userName = payload.full_name
            state.token = payload.token
        },
        setMultiTypeUser(state, value) {
            state.multitype_user = value; // multitype_user 상태를 설정합니다.
        },
        loginCheck: function (state) {
            if (!state.token) {
                router.push({
                    name: 'home'
                }).catch(error => {
                    console.log(error)
                })
            } else {
                // 토큰이 존재할 경우 만료 여부 확인
                const tokenExpired = isTokenExpired(state.token);
                if (tokenExpired) {
                    alert("토큰이 만료되었습니다. 다시 로그인해주세요.");
                    // 로그아웃 실행
                    localStorage.removeItem('vuex');
                    // Vuex state 초기화
                    state.email = '';
                    state.accountId = '';
                    state.accountType = '';
                    state.userName = '';
                    state.token = '';
                    state.isAuthenticated = false;
                    // 홈 페이지로 이동
                    router.push({
                        name: 'home'
                    });
                }
            }
        },
        logout(state) {
            if (confirm("로그아웃 하시겠습니까?")) {
                // 로그아웃 시 로컬 스토리지에서 값 제거
                localStorage.removeItem('vuex');
                // Vuex state 초기화
                state.email = '';
                state.accountId = '';
                state.accountType = '';
                state.userName = '';
                state.token = '';
                state.isAuthenticated = false;
                // 홈 페이지로 이동
                router.push({
                    name: 'home'
                });
            }
        }
    }
}

export default userStore

function isTokenExpired(token) {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const expirationDate = new Date(decodedToken.exp * 1000);
    return expirationDate < new Date();
}