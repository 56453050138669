<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import axios from "axios"; // axios는 일반적으로 직접 가져와 사용합니다.

export default {
	name: "tabHeader",
	props: {
		window: Object,
		activeSub: String,
		activeHeadTab: String,
	},
	setup(props, { emit }) {
		const store = useStore(); // Vuex 스토어 접근
		const route = useRoute(); // vue-router의 현재 route 정보 접근
		const activesub = ref(props.activeSub);
		const activeHeadTab = ref(props.activeHeadTab);
		const eventId = computed(() => store.state.eventStore.eventId);
		const surveyArray = computed(() => store.state.eventStore.surveyArray);
		const surveyRoute = ref("surveySetting");
		const currentPath = route.name;

		watch(
			// 사용중인 언어 변경 디텍
			() => store.state.eventStore.masterId,
			(newMasterId, oldMasterId) => {
				//MasterId
				if (newMasterId !== oldMasterId) {
					if (store.state.eventStore.masterId != null) {
						surveyRoute.value = "masterSurveySetting";
					} else {
						surveyRoute.value = "surveySetting";
					}
				}
			},
			{ immediate: true }
		);

		const description = {
			//setting
			eventsetting: "세팅된 상담회 정보를 한눈에 확인하고 관리하여 성공적인 상담회를 진행해 보세요.",
			eventSettingTables: "현장 운영에 필요한 부스와 스케줄을 세팅해요. 부스별 참여자와 시간대를 미리 계획하고 조정할 수 있어요.",
			bisSetting: "상담회에 참여 중인 바이어, 통역사, 셀러 정보를 관리합니다. 참여하지 않는 사용자도 상담회에 포함시킬 수 있어요.",
			adminSetting: "관리자 계정 등록 및 권한 유형을 설정합니다.",
			questionSetting: "설문 구조를 이루는 질문 항목들을 관리합니다. 이곳에 등록한 질문지는 다른 상담회에서도 활용할 수 있는 소스가 돼요.",
			satisfySetting: "바이어와 셀러에게 요청할 상담 만족도 조사 폼을 설정합니다.",
			//reception
			reception: "바이어의 영접 정보와 상담회 도착 전까지의 현황을 실시간으로 체크합니다.",
			//opertaion
			operateAttendInterpreter: "통역사의 상담회장 출석 여부를 체크합니다. 도착 예정 시간을 묻는 메시지는 이곳에서 예약할 수 있어요.",
			operateAttendSeller: "셀러의 상담회장 출석 여부를 체크합니다.",
			operateTables: "실시간으로 상담회 전체 스케줄을 확인하세요. 상담 진행 상황을 체크하며 BIS의 상태를 변경할 수 있어요.",
			satisfefwesySetting: "",
		};
		// 라우팅 두개에서 사용하는 하나의 description 변수 키로 처리
		description[surveyRoute.value] = `상담 종료 후 통역사에게 요청할 상담일지 폼을 설정합니다.<br>질문지에 등록된 질문을 사용하거나 이전 상담의 폼을 활용하여 새로운 구조를 만들 수 있어요.`;

		return {
			activesub,
			activeHeadTab,
			eventId,
			surveyArray,
			surveyRoute,
			description,
			currentPath,
		};
	},
};
</script>
<template>
	<div>
		<template v-if="activesub === 'setting'">
			<div class="header_tab" v-if="activeHeadTab == 'home'">
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'eventsetting', params: { eventId: eventId } }">
					<a :href="href" @click="navigate" :class="{ active: isActive }">상담회 설정</a>
				</router-link>
			</div>
			<div class="header_tab" v-if="activeHeadTab == 'schedule'">
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'eventSettingTables', params: { eventId: eventId } }">
					<a :href="href" @click="navigate" :class="{ active: isActive }">운영 스케줄 관리</a>
				</router-link>
			</div>
			<div class="header_tab" v-else-if="activeHeadTab == 'user'">
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'bisSetting' }">
					<a :href="href" @click="navigate" :class="{ active: isActive }">BIS 관리</a>
				</router-link>
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'adminSetting' }">
					<a :href="href" @click="navigate" :class="{ active: isActive }">어드민 관리</a>
				</router-link>
			</div>
			<div class="header_tab" v-else-if="activeHeadTab == 'question'">
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'questionSetting', params: { eventId: eventId } }">
					<a :href="href" @click="navigate" :class="{ active: isActive }">질문지</a>
				</router-link>
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: surveyRoute, params: { eventId: eventId } }">
					<a :href="href" @click="navigate" :class="{ active: isActive }">설문 구조</a>
				</router-link>
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'satisfySetting', params: { eventId: eventId } }">
					<a :href="href" @click="navigate" :class="{ active: isActive }">만족도 조사</a>
				</router-link>
			</div>
			<div class="header_tab" v-else-if="activeHeadTab == 'prematch'">
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'BISSetting' }">
					<a :href="href" @click="navigate" :class="{ active: isActive }">사전주선</a>
				</router-link>
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'adminSetting' }">
					<a :href="href" @click="navigate" :class="{ active: isActive }">사전주선</a>
				</router-link>
			</div>
		</template>
		<template v-if="activesub === 'reception'">
			<div class="header_tab" v-if="activeHeadTab == 'reception'">
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'reception' }">
					<a :href="href" :class="{ active: isActive }" @click="navigate">영접</a>
				</router-link>
			</div>
		</template>
		<template v-if="activesub === 'survey'">
			<div class="header_tab" v-if="activeHeadTab == 'survey'">
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'surveyRecordInterpreter' }">
					<a :href="href" :class="{ active: isActive }" @click="navigate">통역사</a>
				</router-link>
				<template v-if="surveyArray.includes('BUYER')">
					<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'surveyRecordBuyer' }">
						<a :href="href" :class="{ active: isActive }" @click="navigate">바이어</a>
					</router-link>
				</template>
				<template v-if="surveyArray.includes('SELLER')">
					<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'surveyRecordSeller' }">
						<a :href="href" :class="{ active: isActive }" @click="navigate">셀러</a>
					</router-link>
				</template>
			</div>
			<div class="header_tab" v-if="activeHeadTab == 'satisfy'">
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'satisfyRecordInterpreter' }">
					<a :href="href" :class="{ active: isActive }" @click="navigate">통역사</a>
				</router-link>
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'satisfyRecordBuyer' }">
					<a :href="href" :class="{ active: isActive }" @click="navigate">바이어</a>
				</router-link>
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'satisfyRecordSeller' }">
					<a :href="href" :class="{ active: isActive }" @click="navigate">셀러</a>
				</router-link>
			</div>
		</template>
		<template v-if="activesub === 'operate'">
			<div class="header_tab" v-if="activeHeadTab == 'attend'">
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'operateAttendInterpreter' }">
					<a :href="href" @click="navigate" :class="{ active: isActive }">통역사</a>
				</router-link>
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'operateAttendSeller' }">
					<a :href="href" @click="navigate" :class="{ active: isActive }">셀러</a>
				</router-link>
			</div>
			<div class="header_tab" v-else-if="activeHeadTab == 'operate'">
				<router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'operateTables' }">
					<a :href="href" :class="{ active: isActive }" @click="navigate">현장운영</a>
				</router-link>
			</div>
		</template>
		<div class="header_wrap_descript" v-html="description[currentPath]"></div>
	</div>
</template>
