<script>
import { ref, onMounted, computed, inject } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';


export default {
  name: 'HomeView',
  setup() {
    const store = useStore(); // Vuex 스토어를 가져옵니다.
    const isAuthenticated = computed(() => store.state.userStore.isAuthenticated);
    const accountType = computed(() => store.state.userStore.accountType);

    if (isAuthenticated.value) {
      if (accountType.value === "BUYER" || accountType.value === "INTERPRETER" || accountType.value === "SELLER") {
        router.push('/bis/dashboard')
      } else {
        router.push('/admin/event/select')
      }
    } else {
      router.push('/')
    }
  }
}
</script>
<template>
  <div class="home">
    <div class="main pc">
      <section class="section1">
        <div class="contain">
          <div class="cont_wrap flex">
            <div class="text_wrap flex">
              <p class="subtitle">수출상담회 · 전시회 운영 플랫폼</p>
              <img src="../assets/img/common/bisat_logo.png" />
            </div>
            <div class="left wow fadeIn">
              <h1>수출상담회의 A부터 Z까지.<br />하나로 끝내는<br /><b>수출상담회 운영•분석</b> 플랫폼</h1>
              <p class="description">바이어 영접, 상담회 운영, 실적 집계 등 수출상담회의 모든 스트림라인을 실시간으로 관리하고 공유하세요. 어디에 있더라도 상담 현황을 파악하고,
                실적집계
                데이터를 즉시 확인할 수 있습니다. 그리고 비샛 데이터 분석 결과를 통해 다양한 인사이트를 얻으세요.</p>
            </div>
            <div class="right">
              <div class="count_wrap flex">
                <div class="count_cont flex">
                  <p class="count_title">비샛을 통해 진행한 <b>수출상담회</b></p>
                  <p class="count_num"><span id="event_cnt" class="count"></span>회</p>
                </div>
                <div class="count_cont flex">
                  <p class="count_title">비샛을 통해 기록한 <b>상담</b></p>
                  <p class="count_num"><span id="survey_cnt"></span>건</p>
                </div>
                <div class="count_cont flex">
                  <p class="count_title">비샛과 함께한 <b>통역사</b></p>
                  <p class="count_num"><span id="interpreter_cnt"></span>명</p>
                </div>
              </div>
            </div>
          </div>
          <img src="../assets/img/landing/section1_img1.png" class="float b wow fadeInDown" data-wow-duration="4s" />
          <img src="../assets/img/landing/section1_img2.png" class="float i wow fadeInDown" data-wow-duration="4s"
            data-wow-delay=".3s" />
          <img src="../assets/img/landing/section1_img3.png" class="float s wow fadeInDown" data-wow-duration="4s"
            data-wow-delay=".5s" />
          <div class="bst__section1_mouse">
            <div class="bst__section1_mouse_scroll"></div>
          </div>
          <div class="bst__section1_mouse_chevrons">
            <i class="eqicon-chevron_down"></i>
            <i class="eqicon-chevron_down"></i>
            <i class="eqicon-chevron_down"></i>
          </div>
        </div>
      </section>

      <section class="section7">
        <div class="sec7_l">
          <p class="sec7_tit wow fadeInDown">Where Buyers, Interpreters and Sellers meet @t a Trade show.</p>
          <a class="sec7_btn wow fadeInDown" href="mailto:korea@eqqui.com">도입문의</a>
        </div>
        <img class="sec7_r" src="../assets/img/landing/sec7_img.png" alt="sec7_img">
      </section>
      <footer class="footer">
        <div class="footer_l">
          <span class="footer_txt">eQQui, Inc. © 2015-2023</span>
        </div>
        <div class="footer_r">
          <span class="footer_txt">에퀴코리아㈜</span>
          <span class="footer_txt">대표자: 박미순</span>
          <span class="footer_txt">korea@eqqui.com </span>
          <span class="footer_txt">서울특별시 서초구 서초중앙로 41(서초동, 대성빌딩) 7층</span>
          <span class="footer_txt">사업자등록번호: 401-86-00658</span>
          <span class="footer_txt">통신판매업신고번호: 2019-서초-0905호</span>
        </div>
        <img class="bst__big_watermark" src="../assets/img/landing/big_bisat_watermark.png">
        <img class="bst__big_watermark_hover" src="../assets/img/landing/big_bisat_watermark_hovered.png">
      </footer>
    </div>
  </div>
</template>
