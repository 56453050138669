<script>
import { ref, onMounted, computed, inject } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import infoDeskModal from '@/components/admin/infoDeskModal.vue';

export default {
  name: 'infoDesk',
  components: {
    Pagination,
    infoDeskModal
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const token = ref('');
    const page = ref(1); // 페이지 번호
    const limit = 10; // 한 페이지에 표시할 항목 수
    const eventId = computed(() => store.state.eventStore.eventId);
    const eventTitle = computed(() => store.state.eventStore.eventTitle);

    // MODAL
    const userInfo = ref({})
    const showInfoDeskModal = ref(false);
    const openInfoDeskModal = (info) => {
      userInfo.value = { info }
      showInfoDeskModal.value = true
    }
    const closeAllModals = () => {
      showInfoDeskModal.value = false;
    };

    const infoList = ref([]);
    // PAGINATION
    const info_total = ref(0);
    const info_pagination = ref(0);

    function pagination() {
      let totalPages = Math.ceil(info_total.value / limit); // 나눈 값을 올림하여 정수로 변환
      info_pagination.value = totalPages <= 1 ? 1 : totalPages; // 1 이하인 경우에는 1로 설정
    }
    const handleClickPage = (index) => {
      page.value = index
      fetchInfoList();
    }

    const checkboxBuyer = ref(true)
    const checkboxSeller = ref(true)

    async function fetchInfoList() {
      try {
        const queryParams = new URLSearchParams({
          page: page.value,
          limit: limit,
          piece_event_id: eventId.value,
        });
        queryParams.append('account_type_list', 'BUYER');
        queryParams.append('account_type_list', 'SELLER');

        const url = `/user/read_list?${queryParams.toString()}`;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });
        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          infoList.value = response.data.user_list; // 이벤트 리스트를 저장합니다.
          info_total.value = response.data.total;
          pagination();
        } else {
          alert('인포데스크를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('인포데스크를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    // input에 포커스 여부를 추적하는 참조 변수
    const isInputFocused = ref(false);

    // 포커스 상태를 업데이트하는 함수
    const handleFocus = () => {
      isInputFocused.value = true;
    };

    const handleBlur = () => {
      isInputFocused.value = false;
    };

    const searchQuery = ref(''); // 검색어를 저장할 상태 변수

    // 검색어에 따라 infoList를 필터링하는 computed 속성
    const filteredInfoList = computed(() => {
      let filteredList = infoList.value;

      // 검색어 필터링
      if (searchQuery.value) {
        filteredList = filteredList.filter((info) => {
          const company = info.company || ''; // null인 경우 빈 문자열 할당
          const product = info.product || ''; // null인 경우 빈 문자열 할당
          return (
            company.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            product.toLowerCase().includes(searchQuery.value.toLowerCase())
          );
        });
      }

      // 체크박스 필터링
      filteredList = filteredList.filter(info => {
        if (checkboxBuyer.value && checkboxSeller.value) {
          return info.account_type === 'BUYER' || info.account_type === 'SELLER';
        } else if (checkboxBuyer.value) {
          return info.account_type === 'BUYER';
        } else if (checkboxSeller.value) {
          return info.account_type === 'SELLER';
        } else {
          return false; // 아무 것도 선택되지 않은 경우 빈 리스트 반환
        }
      });

      return filteredList;
    });


    // BUYER와 SELLER의 갯수를 계산하는 computed 속성
    const buyerCount = computed(() => {
      return filteredInfoList.value.filter(info => info.account_type === 'BUYER').length;
    });

    const sellerCount = computed(() => {
      return filteredInfoList.value.filter(info => info.account_type === 'SELLER').length;
    });

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        fetchInfoList();
      }
    });
    return {
      isAuthenticated,
      eventTitle,
      info_pagination,
      handleClickPage,
      page,
      isInputFocused,
      handleFocus,
      handleBlur,
      checkboxBuyer,
      checkboxSeller,
      infoList,
      openInfoDeskModal,
      showInfoDeskModal,
      closeAllModals,
      userInfo,
      searchQuery,
      filteredInfoList,
      buyerCount,
      sellerCount
    };
  },
}

</script>

<template>
  <div id="infoDesk">
    <!-- 인포데스크 헤더 -->
    <div class="header">
      <div class="logo_wrap">
        <img src="../../assets/img/common/bisat_logo.png" />
      </div>
    </div>
    <div class="left">
      <div class="title_wrap">
        <p class="main_text b_700 size_20">{{ eventTitle }}</p>
        <p class="size_34 b_700">찾으시는 기업이나</p>
        <p class="size_34 b_700">상담 품목이 있으세요?</p>
        <div :class="['main_search', { active: isInputFocused }]">
          <input type="text" placeholder="기업명 또는 상담 품목을 검색하세요." v-model="searchQuery" @focus="handleFocus"
            @blur="handleBlur" @keyup="fetchInfoList">
          <p class="icon"><i class="icon-search"></i></p>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="info_cont_wrap">
        <div class="info_cont">
          <div class="info_cont_top ds_flex al_center jus_between">
            <p class="b_700">참가 리스트</p>
            <div class="ds_flex al_center gap18">
              <div>
                <label for="checkbox_buyer" class="fake_checkbox" :class="{ 'active': checkboxBuyer }">
                  <i v-if="checkboxBuyer" class="icon-checked"></i>
                </label>
                <input type="checkbox" id="checkbox_buyer" v-model="checkboxBuyer" />
                <label class="function_name" for="checkbox_buyer">바이어 {{ buyerCount }}</label>
              </div>
              <div>
                <label for="checkbox_seller" class="fake_checkbox" :class="{ 'active': checkboxSeller }">
                  <i v-if="checkboxSeller" class="icon-checked"></i>
                </label>
                <input type="checkbox" id="checkbox_seller" v-model="checkboxSeller" />
                <label class="function_name" for="checkbox_seller">셀러 {{ sellerCount }}</label>
              </div>
            </div>
          </div>
          <div class="info_cont_list">
            <table class="table_wrap">
              <thead>
                <tr>
                  <th>유형</th>
                  <th>기업명</th>
                  <th>상담 품목</th>
                  <th>일정표</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="filteredInfoList.length > 0">
                  <tr v-for="(info, index) in filteredInfoList" :key="index">
                    <td>
                      <p class="user_type_bdg" :class="info.account_type">{{ info.account_type === "BUYER" ? "바이어" :
                        "셀러" }}</p>
                    </td>
                    <td class="company">{{ info.company }}</td>
                    <td class="item">{{ info.product }}</td>
                    <td>
                      <p class="inline_cont_btn" @click="openInfoDeskModal(info)">일정표 보기
                      </p>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="4" class="nodata">정보가 없습니다.</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination :currentPage="page" :totalPages="info_pagination" :handleClickPage="handleClickPage" />
      </div>
      <info-desk-modal :show-info-desk-modal="showInfoDeskModal" @close="closeAllModals"
        :selected-user-data="userInfo"></info-desk-modal>
    </div>
  </div>
</template>
