
<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default {
  setup() {
    const route = useRoute()
    
    // 경로에 따라 다른 레이아웃을 사용
    const layout = computed(() => {
      if (route.path.startsWith('/admin')) {
        return 'AppAdmin'
      } else if (route.path.startsWith('/bis')) {
        return 'AppBIS'
      }else{
        return 'AppAdmin'
      }
    })

    return { layout }
  }
}
</script>


<template>
  <component :is="layout">
    <router-view />
  </component>
</template>
