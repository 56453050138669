<script>
import { ref, onMounted, computed, inject, watch } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';
import tabHeader from '@/components/admin/tabHeader.vue';
import bisatStatus from '@/assets/js/bisatStatus.json';
import bisatLang from '@/assets/js/bisatLang.json';

export default {
  name: 'operateAttend',
  components: {
    Pagination,
    subHeader,
    tabHeader
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');
    const activeSub = ref('operate');
    const activeHeadTab = ref('attend');

    //이벤트 일시 관련 변수
    const eventDateList = ref([]);
    const selectDate = ref(null);

    // PAGINATION 관련 변수
    const currentPage = ref(1);
    const itemsPerPage = ref(10); // 페이지당 아이템 수
    const totalPages = computed(() => Math.ceil(attendList.value.length / itemsPerPage.value));
    const paginatedAttendList = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return attendList.value.slice(start, end);
    });

    const handleClickPage = (pageIndex) => {
      if (pageIndex >= 1 && pageIndex <= totalPages.value) {
        currentPage.value = pageIndex;
      }
    };

    // 모달 관련 변수
    // const showStatusModal = ref(false);
    // const selectedUserData = ref({});
    // const closeAllModals = (type) => {
    //   showStatusModal.value = false;
    // };
    // const openOperateModal = (type, userId, pta_id) => {
    //   selectedUserData.value = { type, userId, pta_id, mode: 'manual' };
    //   showStatusModal.value = true;
    // };

    // 출석 데이터 관련 변수
    const attendList = ref([])

    // STEP1. 이벤트 날짜 리스트 받아오기
    async function fetchDate() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value
        });

        const url = `/schedule/read_date?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateList.value = response.data.date_list;
          selectDate.value = eventDateList.value[0]
          createAttendList()
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    // STEP2. 출석 리스트 생성
    async function createAttendList() {
      try {
        const response = await axios.post('/attendance/create_list_by_date', {
          "date": selectDate.value,
          "piece_event_id": eventId.value,
          "account_type": 'SELLER',
        }, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });
        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          fetchAttendList()
        } else {
          alert('저장하지 못했습니다.');
        }
      } catch (error) {
        console.error(error);
      }
    }

    // STEP3. 출석 리스트 불러오기
    async function fetchAttendList() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: selectDate.value,
          account_type: 'SELLER',
        });

        const url = `/attendance/read_list_by_date?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          attendList.value = response.data.ar_list.map(item => {

            let etaDate, etaTime;
            if (item.eta) {
              const eta = item.eta;
              [etaDate, etaTime] = eta.split(' ');
            } else {
              etaDate = '';
              etaTime = '';
            }

            let attendDate, attendTime;
            if (item.attend_at) {
              const attendAt = item.attend_at;
              [attendDate, attendTime] = attendAt.split(' ');
            } else {
              attendDate = '';
              attendTime = '';
            }

            let offDate, offTime;
            if (item.offduty_at) {
              const offAt = item.offduty_at;
              [offDate, offTime] = offAt.split(' ');
            } else {
              offDate = '';
              offTime = '';
            }

            return {
              ...item,
              etaDate: etaDate, // 날짜 부분
              etaTime: etaTime, // 시간 부분
              attendDate: attendDate, // 날짜 부분
              attendTime: attendTime, // 시간 부분
              offDate: offDate, // 날짜 부분
              offTime: offTime // 시간 부분
            };
          });
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    const getIconClass = (type, category, status) => {
      const categoryData = bisatStatus[type][category];
      const statusItem = categoryData.find(item => item.status === status);
      return statusItem ? statusItem.icon : '';
    };

    const getLanguage = (code) => {
      const languageLabel = bisatLang.find(item => item.code === code);
      return languageLabel ? languageLabel.label : '';
    };

    const toggleShouldSend = (attend) => {
      // attend.should_send 값을 토글합니다.
      attend.should_send = attend.should_send === 'Y' ? 'N' : 'Y';

      // 이후 API 호출 등의 저장 로직을 실행합니다.
      saveShouldSend(attend.at_id, attend.should_send);
    };

    const saveShouldSend = (at_id, should_send) => {
      updateOption(at_id, '', should_send, '')
    };

    // 옵션 업데이트
    const updateOption = async (at_id, employ_type, should_send, evaluation) => {
      const data = {
        date: selectDate.value,
        piece_event_id: eventId.value,
        at_id: at_id,
        employ_type: employ_type,
        should_send: should_send,
        evaluation: evaluation,
      };
      const url = '/attendance/update_record';
      try {
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.data.result === true) {
          router.go(0)
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('옵션 업데이트 오류:', error);
      }
    }
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        fetchDate()
      }
    });
    return {
      activeSub,
      activeHeadTab,
      fetchDate,
      eventDateList,
      selectDate,
      currentPage,
      totalPages,
      handleClickPage,
      attendList,
      paginatedAttendList,
      getIconClass,
      getLanguage,
      toggleShouldSend,
      updateOption
    };
  },
}

</script>

<template>
  <div id="operateAttend">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="wide_wrap">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
        <p class="inline_cont_btn">메시지 전송 예약</p>
      </div>
      <div class="tables_wrap">
        <div class="function_bar">
          <div class="row">
            <select v-model="selectDate">
              <option v-for="(item, index) in eventDateList" :key="index" :value="item">{{ item }}</option>
            </select>
            <div class="cont_wrap ds_flex gap18 al_center">
              <p class="icon_box"><i class="icon-download"></i></p>
              <!-- TODO: 검색어 기능 추가 예정 -->
              <p class="fake_textbox"><input type="text" placeholder="검색어" /><i class="icon-search"></i></p>
            </div>
          </div>
          <div class="row">

          </div>
        </div>
        <div class="table_wrap">
          <table class="table_view">
            <thead>
              <tr>
                <th>근무형태</th>
                <th>이름</th>
                <th>부스</th>
                <th>전송상태/시간</th>
                <th>도착 예정 시간</th>
                <th>출석한 시간</th>
                <th>퇴근 시간</th>
                <th>근태 평가</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="paginatedAttendList.length > 0">
                <tr v-for="attend in paginatedAttendList" :key="attend.ar_id">
                  <td>
                    <select v-model="attend.employ_type"
                      @change="updateOption(attend.at_id, attend.employ_type, '', '')">
                      <option value="FT">정규</option>
                      <option value="PT">예비</option>
                    </select>
                  </td>
                  <td class="name">
                    <div class="status_wrap">
                      <i :class="`icon-${getIconClass('INTERPRETER', 'logs', attend.code_name)}`"></i>
                      <p>{{ attend.full_name }}</p>
                    </div>
                    <p class="lang">
                      {{ getLanguage(attend.lang1) }}<template v-if="attend.lang2">, {{ getLanguage(attend.lang2)
                        }}</template>
                    </p>
                  </td>
                  <td class="booth">{{ attend.booths }}</td>
                  <td>
                    <div>
                      <input type="checkbox" :id="'should_send_' + attend.ar_id" :checked="attend.should_send === 'Y'"
                        @change="toggleShouldSend(attend)" />
                      <label class="toggle_box" :for="'should_send_' + attend.ar_id"
                        :class="{ active: attend.should_send === 'Y' }"></label>
                    </div>
                    <p v-if="attend.sent_datetime">{{ attend.sent_datetime }}</p>
                  </td>
                  <td>
                    <template v-if="attend.etaTime">
                      <p class="time">{{ attend.etaTime }}</p>
                      <p class="date">{{ attend.etaDate }}</p>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </td>
                  <td>
                    <template v-if="attend.attendTime">
                      <p class="time">{{ attend.attendTime }}</p>
                      <p class="date">{{ attend.attendDate }}</p>
                    </template>
                    <template v-else>
                      <p class="inline_cont_btn">출석하기</p>
                    </template>
                  </td>
                  <td>
                    <template v-if="attend.offDate">
                      <p class="time">{{ attend.offDate }}</p>
                      <p class="date">{{ attend.offTime }}</p>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </td>
                  <td>
                    <select v-model="attend.evaluation" @change="updateOption(attend.at_id, '', '', attend.evaluation)">
                      <option value="OK">정상근무</option>
                      <option value="NOSHOW">불참</option>
                      <option value="LATE">지각</option>
                      <option value="LEAVE">무단이탈</option>
                      <option value="OTHER">기타</option>
                    </select>
                  </td>
                  <td class="memo">
                    {{ attend.memo }}
                    <input type="text" class="fake_textbox" v-model="attend.memo" />
                    <transition name="fade-alert">
                      <p class="float_alert">엔터를 눌러 저장</p>
                    </transition>
                    <div class="memo_log_wrap">
                      <p v-for="(memo, index) in attend.memo" :key="index">
                        <i class="icon-talk"></i>
                        <span>{{ memo }}</span>
                      </p>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td>정보 없음</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination :currentPage="currentPage" :totalPages="totalPages" :handleClickPage="handleClickPage" />
    </div>
  </div>
</template>