<script>
import { computed, ref, watch } from "vue";
import * as survey_form from "@/assets/js/survey_form";

export default {
    props: {
        id: Number,
        options: Object,
        answers: Array,
        disabled: Boolean,
    },
    setup(props, { emit }) {
        let id = props.id;
        let options = ref({});
        let answers = ref([]);
        let disabled = ref(props.disabled);
        const selected = ref([]);
        const opened = ref(false);

        const openSelect = () => {
            if (opened.value == true) {
                opened.value = false;
            } else {
                opened.value = true;
            }
        };

        const changeOption = (option) => {
            if (answers.value.includes(option)) {
                answers.value = answers.value.filter((e) => e !== option);
            } else {
                answers.value.push(option);
            }
            emit("change", answers.value);
        };

        watch(
            // 드롭다운 프롭 변경 디텍
            [() => props.options, () => props.answers, () => props.disabled],
            ([newOptions, newAnswers, newDisabled], [oldOptions, oldAnswers, oldDisabled]) => {
                //options
                if (newOptions !== oldOptions) {
                    options.value = newOptions;
                }

                //answers
                if (newAnswers !== oldAnswers) {
                    answers.value = newAnswers;
                }

                //disabled
                if (newDisabled !== oldDisabled) {
                    disabled.value = newDisabled;
                }
            },
            { immediate: true }
        );

        return {
            id,
            options, // 옵션 v-for 돌려야 하는 값 object 또는 array
            answers,
            disabled, // 변경 불가 select로 만들기
            selected, // 선택된 옵션값
            changeOption, // 선택값 변경 함수
            opened, // 드롭다운 오픈 체크
            openSelect, // 드롭다운 오픈 함수
            survey_form,
            focus,
        };
    },
    computed: {
        allSelected: {
            //getter
            get: function () {
                let status_scoped = [];
                this.options.forEach((item) => {
                    if (this.answers.includes(item)) {
                        status_scoped.push(item);
                    }
                });
                return this.options.length === this.answers.length;
            },
            //setter
            set: function (e) {
                let status_scoped = [];
                this.options.forEach((item) => {
                    if (this.answers.includes(item)) {
                        status_scoped.push(item);
                    }
                });
                this.answers = e ? this.options : [];
            },
        },
    },
};
</script>
<template>
    <div class="fake_dropdown_wrap">
        <div class="fake_dropdown ds_flex al_center gap4" :class="[opened ? 'active' : '', disabled ? 'disabled' : '']" @click="!disabled ? openSelect() : ''">
            <div class="secondary_text size_14 b_500">
                <template v-if="id == 'statusBuyer'">바이어</template>
                <template v-if="id == 'statusSeller'">셀러</template>
                <template v-if="id == 'statusInterpreter'">통역사</template>
            </div>
            <p>
                <template v-if="allSelected">
                    전체선택
                </template>
                <template v-else>
                    <template v-if="answers.length > 1">
                        {{ answers[0].label }} 외 {{ answers.length - 1 }}개
                    </template>
                    <template v-else>
                        {{ answers[0].label }}
                    </template>
                </template>
            </p>
            <i :class="opened ? 'icon-up_arrow' : 'icon-down_arrow'"></i>
        </div>
        <template v-if="!disabled">
            <transition name="dropdown">
                <div class="fake_dropdown_options" v-if="opened" style="">
                    <div v-for="a in options" :key="a.status" class="fake_option" :class="answers.includes(a) ? 'active' : ''" @click="changeOption(a)">
                        <label :for="`check_${id}_${a.status}`" class="fake_checkbox" :class="{'active': answers.includes(a)}">
                            <i v-if="answers.includes(a)" class="icon-checked"></i>
                        </label>
                        <input type="checkbox" :id="`check_${id}_${a.status}`">
                        <div class="ds_flex al_center gap8">
                            <i :class="`icon-${a.icon}`" style="font-size: 16px"></i>
                            <p>{{ a.label }}</p>
                        </div>
                    </div>
                </div>
            </transition>
        </template>
    </div>
</template>
