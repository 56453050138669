<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'

export default defineComponent({
  props: {
    showImageUploadModal: Boolean,
    showUserATypeModal: Boolean,
    showUserBTypeModal: Boolean,
    a_account_list: Array,
    b_account_list: Array
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');
    const page = ref(1);
    const limit = 10;

    // PM과 Client 리스트 관리
    const userPmList = ref([]);
    const selectedPM = ref([]);
    const userClientList = ref([]);
    const selectedClient = ref([]);

    // 페이지네이션 상태 관리
    const events_total = ref(0);
    const event_pagination = ref(0);

    // 페이지네이션 계산 함수
    function pagination() {
      let totalPages = Math.ceil(events_total.value / limit);
      event_pagination.value = totalPages <= 1 ? 1 : totalPages;
    }

    // FETCH PM LIST
    async function fetchPMList() {
      try {
        const queryParams = new URLSearchParams({
          page: page.value,
          limit: limit
        });
        queryParams.append('account_type_list', 'A1');
        queryParams.append('account_type_list', 'A2');
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_list?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          userPmList.value = response.data.user_list; // 이벤트 리스트를 저장합니다.
          pagination();
        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }
    // FETCH CLIENT LIST
    async function fetchClientList() {
      try {
        const queryParams = new URLSearchParams({
          page: page.value,
          limit: limit
        });
        queryParams.append('account_type_list', 'b1');
        queryParams.append('account_type_list', 'b2');
        queryParams.append('account_type_list', 'c1');
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_list?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          userClientList.value = response.data.user_list; // 이벤트 리스트를 저장합니다.
          pagination();
        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    const activeModalType = computed(() => {
      if (props.showUserATypeModal) {
        return 'PM';
      } else if (props.showUserBTypeModal) {
        return 'Client';
      }
      return null;
    });
    // 체크박스 상태 변경 처리
    const handleCheckboxChange = (userItem, event) => {
      const selectedData = {
        at_id: userItem.at_id,
        account_id: userItem.account_id,
        account_type: userItem.account_type,
        full_name: userItem.full_name,
        company: userItem.company
      };

      // 현재 모달 타입에 따라 분기
      const selectedList = activeModalType.value === 'PM' ? selectedPM : selectedClient;
      const alreadySelected = selectedList.value.some(pm => pm.at_id === userItem.at_id);
      const index = selectedList.value.findIndex(pm => pm.at_id === userItem.at_id);

      if (event.target.checked) {
        if (!alreadySelected) selectedList.value.push(selectedData);
      } else {
        if (alreadySelected) selectedList.value.splice(index, 1);
      }
    };

    const isActive = (at_id) => {
      const selectedList = activeModalType.value === 'PM' ? selectedPM : selectedClient;
      return selectedList.value.some(pm => pm.at_id === at_id);
    };

    watch(
      [() => props.a_account_list, () => props.b_account_list, () => props.showUserATypeModal, () => props.showUserBTypeModal],
      ([newAccountList, newAccountClientList, newShowUserATypeModal, newShowUserBTypeModal], [oldAccountList, oldAccountClientList, oldShowUserATypeModal, oldShowUserBTypeModal]) => {
        if (newAccountList !== oldAccountList) {
          selectedPM.value = newAccountList.map(item => ({
            at_id: item.at_id,
            account_id: item.account_id,
            account_type: item.account_type,
            full_name: item.full_name,
            company: item.company
          }));
        }

        if (newAccountClientList !== oldAccountClientList) {
          selectedClient.value = newAccountClientList.map(item => ({
            at_id: item.at_id,
            account_id: item.account_id,
            account_type: item.account_type,
            full_name: item.full_name,
            company: item.company
          }));
        }

        if (newShowUserATypeModal && newShowUserATypeModal !== oldShowUserATypeModal) {
          fetchPMList();
        }

        if (newShowUserBTypeModal && newShowUserBTypeModal !== oldShowUserBTypeModal) {
          fetchClientList();
        }
      },
      { immediate: true }
    );


    const sendSelectedPMToParent = () => {
      emit('updateSelectedPM', selectedPM.value);
      emit('close');
    }

    const sendSelectedClientToParent = () => {
      emit('updateSelectedClient', selectedClient.value);
      emit('close');
    }

    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
      }
      if (props.showUserATypeModal) {
        fetchPMList();
        if (props.a_account_list) {
          selectedPM.value = props.a_account_list.map(item => ({
            at_id: item.at_id,
            account_id: item.account_id,
            account_type: item.account_type,
            full_name: item.full_name,
            company: item.company
          }));
        }
      }
      else if (props.showUserBTypeModal) {
        fetchClientList();
        if (props.b_account_list) {
          selectedClient.value = props.b_account_list.map(item => ({
            at_id: item.at_id,
            account_id: item.account_id,
            account_type: item.account_type,
            full_name: item.full_name,
            company: item.company
          }));
        }
      }
    });
    return {
      userPmList,
      selectedPM,
      handleCheckboxChange,
      isActive,
      sendSelectedPMToParent,
      userClientList,
      selectedClient,
      sendSelectedClientToParent
    }
  },
  methods: {
    closeModals() {
      this.$emit('close');
    }
  }
});
</script>

<template>
  <div id="eventSettingModal">
    <div class="overlay" v-if="showImageUploadModal || showUserATypeModal || showUserBTypeModal"></div>
    <div class="modal" v-if="showImageUploadModal || showUserATypeModal || showUserBTypeModal">
      <p class="icon_box close" @click="closeModals">
        <i class="icon-delete sub_text"></i>
      </p>
      <template v-if="showImageUploadModal">
        <p class="modal_title size_28 b_700">이미지 추가하기</p>
        <div class="img_upload ds_flex">
          <i class="icon-img size_32"></i>
          <div class="text_wrap t_center">
            <p class="b_700 secondary_text">인포데스크 배경 이미지를 업로드하세요.</p>
            <p class="sub_text size_14">최적화된 이미지 사이즈는 1400 x 600px입니다.</p>
          </div>
          <p class="block_cont_btn secondary_text size_14">파일 업로드하기</p>
        </div>
        <p class="block_main_btn active_btn">저장</p>
      </template>
      <template v-if="showUserATypeModal">
        <p class="modal_title size_28 b_700">PM 리스트</p>
        <div class="user_table_modal">
          <div class="table_fx ds_flex">
            <p class="table_length">{{ userPmList.length }}명의 PM</p>
            <div class="fake_textbox ds_flex">
              <input type="text" placeholder="검색어" />
              <div class="icon_box">
                <i class="icon-search"></i>
              </div>
            </div>
          </div>
          <div class="table_cont">
            <div class="table_cont_head ds_flex">
              <p class="table_cont_th">선택</p>
              <p class="table_cont_th">권한</p>
              <p class="table_cont_th name">이름</p>
              <p class="table_cont_th id">아이디</p>
              <p class="table_cont_th company">기업명</p>
            </div>
            <div class="table_cont_body">
              <div class="table_cont_tr ds_flex" v-for="(item, index) in userPmList" :key="index">
                <div class="table_cont_td">
                  <label class="fake_checkbox" :for="'userAtype-' + item.at_id"
                    :class="{ 'active': isActive(item.at_id) }">
                    <i v-if="isActive(item.at_id)" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" :id="'userAtype-' + item.at_id" @change="handleCheckboxChange(item, $event)"
                    :checked="isActive(item.at_id)" />
                </div>
                <div class="table_cont_td">
                  <p class="autority" :class="item.account_type">{{ item.account_type }}</p>
                </div>
                <p class="table_cont_td name">{{ item.full_name }}</p>
                <p class="table_cont_td id">{{ item.email }}</p>
                <p class="table_cont_td company">{{ item.company }}</p>
              </div>
            </div>
            <div class="table_cont_bottom">
              <div class="table_select_user_wrap ds_flex gap18">
                <p class="b_700">선택한 PM</p>
                <div class="table_select_user ds_flex">
                  <p class="ds_flex" v-for="(item, index) in selectedPM" :key="index">{{ item.full_name }}<i
                      class="icon-delete sub_text"></i></p>
                </div>
              </div>
              <div class="btn_wrap">
                <p class="block_main_btn active_btn" @click="sendSelectedPMToParent">저장</p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="showUserBTypeModal">
        <p class="modal_title size_28 b_700">고객사 리스트</p>
        <div class="user_table_modal">
          <div class="table_fx ds_flex">
            <p class="table_length">{{ userClientList.length }}명의 고객</p>
            <div class="fake_textbox ds_flex">
              <input type="text" placeholder="검색어" />
              <div class="icon_box">
                <i class="icon-search"></i>
              </div>
            </div>
          </div>
          <div class="table_cont">
            <div class="table_cont_head ds_flex">
              <p class="table_cont_th">선택</p>
              <p class="table_cont_th">권한</p>
              <p class="table_cont_th name">이름</p>
              <p class="table_cont_th id">아이디</p>
              <p class="table_cont_th company">기업명</p>
            </div>
            <div class="table_cont_body">
              <div class="table_cont_tr ds_flex" v-for="(item, index) in userClientList" :key="index">
                <div class="table_cont_td">
                  <label class="fake_checkbox" :for="'userBtype-' + item.at_id"
                    :class="{ 'active': isActive(item.at_id) }">
                    <i v-if="isActive(item.at_id)" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" :id="'userBtype-' + item.at_id" @change="handleCheckboxChange(item, $event)"
                    :checked="isActive(item.at_id)" />
                </div>
                <div class="table_cont_td">
                  <p class="autority" :class="item.account_type">{{ item.account_type }}</p>
                </div>
                <p class="table_cont_td name">{{ item.full_name }}</p>
                <p class="table_cont_td id">{{ item.email }}</p>
                <p class="table_cont_td company">{{ item.company }}</p>
              </div>
            </div>
            <div class="table_cont_bottom">
              <div class="table_select_user_wrap ds_flex gap18">
                <p class="b_700">선택한 고객사</p>
                <div class="table_select_user ds_flex">
                  <p class="ds_flex" v-for="(item, index) in selectedClient" :key="index">{{ item.full_name }}<i
                      class="icon-delete sub_text"></i></p>
                </div>
              </div>
              <div class="btn_wrap">
                <p class="block_main_btn active_btn" @click="sendSelectedClientToParent">저장</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
