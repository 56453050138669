<script>
import { ref, onMounted, computed, inject, watch, watchEffect, reactive } from 'vue';
import Vue3Datatable from '@bhplugin/vue3-datatable'
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';
import tabHeader from '@/components/admin/tabHeader.vue';
import scheduleModal from '@/components/admin/eventSettingTablesModal.vue';

export default {
  name: 'eventSettingTables',
  components: {
    Pagination,
    subHeader,
    tabHeader,
    scheduleModal
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');
    const activeSub = ref('setting'); // 여기에서 'setting'이라는 값을 정의합니다.
    const activeHeadTab = ref('schedule'); // 여기에서 'schedule'이라는 값을 정의합니다.

    //이벤트 일시 관련 변수
    const eventDateList = ref([]);
    const selectDate = ref(null);

    const eventDateTime = ref([])

    //이벤트 부스 관련 변수
    const boothList = ref([])

    // 스케줄 관련 변수
    const schedule = ref([])

    // 모달 관련 변수
    const showBoothSideModal = ref(false);
    const showScheduleUploadModal = ref(false);
    const showScheduleAddModal = ref(false);
    const selectedScheduleData = ref({});

    const closeAllModals = (type) => {
      showBoothSideModal.value = false;
      showScheduleUploadModal.value = false;
      showScheduleAddModal.value = false;
    };

    // PAGINATION 관련 변수
    const currentPage = ref(1);
    const itemsPerPage = ref(10); // 페이지당 아이템 수

    const totalPages = computed(() => Math.ceil(boothList.value.length / itemsPerPage.value));
    const paginatedBoothList = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return boothList.value.slice(start, end);
    });
    const handleClickPage = (pageIndex) => {
      if (pageIndex >= 1 && pageIndex <= totalPages.value) {
        currentPage.value = pageIndex;
      }
    };




    // STEP1. 이벤트 날짜 리스트 받아오기
    async function fetchDate() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value
        });

        const url = `/schedule/read_date?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateList.value = response.data.date_list;
          selectDate.value = eventDateList.value[0]
          fetchDateTime()
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    // STEP2. 이벤트 세팅 시 설정한 상담회 날짜, 시간 불러오기 (X축 생성)
    async function fetchDateTime() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: selectDate.value,
        });

        const url = `/schedule/read_datetime?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          // console.log('Response Data:', response.data.datetime);
          eventDateTime.value = response.data.datetime.map(item => ({
            ...item,
            start_time: item.start_time.slice(0, 5), // HH:MM 형식으로 변환
            end_time: item.end_time.slice(0, 5), // HH:MM 형식으로 변환
          }));
          fetchBoothList();
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    // STEP3. 부스 불러오기 (Y축 생성)
    async function fetchBoothList() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
        });

        const url = `/schedule/read_booths?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          boothList.value = response.data.booths;
          fetchSchedule();
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }
    // STEP4. 스케줄 불러오기 (데이터 추가)
    async function fetchSchedule() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: selectDate.value,
        });

        const url = `/schedule/read_piece_to_schedule?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.length >= 0) {
          schedule.value = response.data;
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 불러올때 오류:', error);
      }
    }

    const openScheduleAddModal = (booth, time, isSchedule) => {
      selectedScheduleData.value = { booth, time, isSchedule };
      showScheduleAddModal.value = true;
    }

    const getSchedules = (ptb_id, ptd_id) => {
      return schedule.value.filter(s => s.ptb_id === parseInt(ptb_id) && s.ptd_id === parseInt(ptd_id));
    };

    const scheduleDownload = async () => {
      try {
        const response = await axios.get(`/schedule/download_schedules_table?piece_event_id=${eventId.value}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
          responseType: 'blob' // 중요: 파일을 다운로드할 때는 responseType을 'blob'으로 설정해야 합니다.
        });
        // 파일 객체 생성
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // 파일 URL 생성
        const url = URL.createObjectURL(blob);

        // 파일 다운로드를 트리거하기 위해 링크를 생성하고 클릭
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'schedule_table.xlsx'); // 적절한 파일 이름과 확장자로 변경
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // 메모리 해제
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('파일 다운로드 중 오류 발생:', error);
      }
    }

    const changeBoothMode = ref([false]);

    const updateBooth = async (ptb_id, booth_name) => {
      try {
        const response = await axios.put('/schedule/update_booths',
          {
            ptb_id: ptb_id,
            booth_name: booth_name
          },
          {
            headers: {
              Authorization: `Bearer ${token.value}`
            }
          },
        );
        if (response.data.result === true) {
          alert("저장되었습니다.");
          router.go()
        } else {
          console.log('Update failed:', response.data.result);
        }
      } catch (error) {
        console.error('Update failed:', error);
      }
    };

    const deleteBooth = async (ptb_id) => {
      if (confirm("삭제하시겠습니까?")) { // 사용자가 확인을 누른 경우에만 실행
        try {
          const response = await axios.delete(`/schedule/delete_booth?ptb_id=${ptb_id}`, {
            headers: {
              Authorization: `Bearer ${token.value}`
            }
          });
          if (response.data.result === true) {
            alert("삭제되었습니다.");
            router.go();
          } else {
            console.log('Delete failed:', response.data.result);
          }
        } catch (error) {
          console.error('Delete failed:', error);
        }
      }
    };

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        fetchDate()
      }
    });

    return {
      activeSub,
      activeHeadTab,
      eventId,
      eventDateList,
      fetchDateTime,
      selectDate,
      eventDateTime,
      boothList,
      getSchedules,
      showBoothSideModal,
      showScheduleUploadModal,
      showScheduleAddModal,
      openScheduleAddModal,
      selectedScheduleData,
      closeAllModals,
      scheduleDownload,
      currentPage,
      itemsPerPage,
      totalPages,
      paginatedBoothList,
      handleClickPage,
      changeBoothMode,
      updateBooth,
      deleteBooth
    };
  },
}

</script>

<template>
  <div id="eventSettingTables">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="wide_wrap">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
        <div class="header_tab gap18">
          <p class="inline_cont_btn" @click="showBoothSideModal = true">부스 추가</p>
          <p class="inline_cont_btn" @click="showScheduleUploadModal = true">스케줄 업로드</p>
        </div>
      </div>
      <div class="tables_wrap eventSettingTables_wrap">
        <div class="function_bar">
          <div class="row">
            <select v-model="selectDate" @change="fetchDateTime">
              <option v-for="(item, index) in eventDateList" :key="index" :value="item">{{ item }}</option>
            </select>
            <div class="cont_wrap ds_flex gap18 al_center">
              <p class="icon_box" @click="scheduleDownload"><i class="icon-download"></i></p>
              <!-- TODO: 검색어 기능 추가 예정 -->
              <p class="fake_textbox"><input type="text" placeholder="검색어" /><i class="icon-search"></i></p>
            </div>
          </div>
        </div>
        <div class="table_wrap">
          <table>
            <thead>
              <tr>
                <th>부스<span>▾</span></th>
                <th v-for="date in eventDateTime" :key="date.ptd_id">{{ date.start_time }}~{{ date.end_time }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="paginatedBoothList.length > 0">
                <tr v-for="(booth, index) in paginatedBoothList" :key="index">
                  <td class="booth" :class="{ 'edit': changeBoothMode[index] }">
                    <template v-if="!changeBoothMode[index]">
                      {{ booth.booth_name }}
                      <p class="icon_box" @click="changeBoothMode[index] = true"><i class="icon-edit"></i></p>
                    </template>
                    <template v-else>
                      <input type="text" class="fake_textbox" v-model="booth.booth_name" />
                      <div class="ds_flex">
                        <p class="icon_box" @click="deleteBooth(booth.ptb_id)"><i class="icon-trash"></i></p>
                        <p class="icon_box active" @click="updateBooth(booth.ptb_id, booth.booth_name)">저장</p>
                      </div>
                    </template>
                  </td>
                  <td v-for="time in eventDateTime" :key="time.ptd_id">
                    <template v-if="getSchedules(booth.ptb_id, time.ptd_id).length">
                      <div class="schedule_info_wrap" v-for="schedule in getSchedules(booth.ptb_id, time.ptd_id)"
                        :key="schedule.schedule_id" @click="openScheduleAddModal(booth, time, schedule.schedule_id)">
                        <p class="schedule_info">{{ schedule.created_by_name }}</p>
                        <p class="schedule_user_info"><span class="type_bdg">B</span>{{ schedule.buyer_company }} {{
                          schedule.buyer_name }}</p>
                        <p class="schedule_user_info"><span class="type_bdg">I</span>{{ schedule.interpreter_name }}</p>
                        <p class="schedule_user_info"><span class="type_bdg">S</span>{{ schedule.seller_company }} {{
                          schedule.seller_name }}</p>
                        <p class="schedule_user_info"><span class="type_bdg">FM</span>{{ schedule.manager_name }}</p>
                      </div>
                    </template>
                    <template v-else>
                      <p class="add_schedule" @click="openScheduleAddModal(booth, time, false)">+ 추가</p>
                    </template>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td>부스 없음</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination :currentPage="currentPage" :totalPages="totalPages" :handleClickPage="handleClickPage" />
    </div>
    <schedule-modal :show-booth-side-modal="showBoothSideModal" :show-schedule-upload-modal="showScheduleUploadModal"
      :show-schedule-add-modal="showScheduleAddModal" @close="closeAllModals" :boothList="boothList"
      :selectedScheduleData="selectedScheduleData"></schedule-modal>
  </div>
</template>