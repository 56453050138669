<script>
import { ref, computed, onMounted, inject, watch } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios'; // axios는 일반적으로 직접 가져와 사용합니다.

export default {
  name: 'commonBisHeader',
  props: {
    window: Object,
    user: Object,
    event: Object,
  },
  setup(props, { emit }) {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const token = ref('');
    const sideNav = ref(false)

    const { userName, formattedUserType, accountStatus } = props.user;
    const { eventTitle, selectDate, scheduleDate, handleSelectDate } = props.event;

    const pastPushMessage = computed(() => store.state.bisStore.pushMessage)
    const newPushMessage = ref(pastPushMessage.value)

    const updatePushMessageValue = () => {
      store.commit('bisStore/setPushMessage', newPushMessage.value)
    }
    const logout = () => {
      store.commit('userStore/logout');
    };

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
      }
    });
    return {
      sideNav,
      userName,
      formattedUserType,
      eventTitle,
      selectDate,
      scheduleDate,
      handleSelectDate,
      accountStatus,
      updatePushMessageValue,
      newPushMessage,
      logout
    };
  }
}
</script>


<template>
  <div>
    <div class="header_bis">
      <div class="top_wrap">
        <router-link to="/bis/dashboard">
          <div class="logo_wrap">
            <img src="../../assets/img/common/bis_logo.png" />
          </div>
        </router-link>
        <i class="icon-hamburger" @click="sideNav = true"></i>
      </div>
      <div class="event_wrap">
        <div class="title_wrap">
          <img src="../../assets/img/common/event_title_badge.png" />
          <p>{{ eventTitle }}</p>
        </div>
        <select v-model="selectDate" @change="handleSelectDate">
          <option v-for="(item, index) in scheduleDate" :key="index" :value="item">{{ item }}</option>
        </select>
      </div>
    </div>
    <!-- 사이드 네비게이션 -->
    <transition name="draw">
      <div class="bis_side_nav" v-if="sideNav">
        <p class="icon_box close" @click="sideNav = false"><i class="icon-delete"></i></p>
        <section class="user_info_wrap">
          <p class="size_14 eqqui_text">{{ formattedUserType }}</p>
          <div class="ds_flex gap12 al_end jus_between">
            <p class="size_24 b_700 user_name">{{ userName }}</p>
            <a class="inline_cont_btn" href="/bis/myAccount">내 정보</a>
          </div>
        </section>
        <section class="qr_section">
          <div class="ds_flex">
            <div class="qr_cont">
              <p class="icon_box"><i class="icon-qr_scanner"></i></p>
              <p class="size_14 b_700">QR코드 스캔</p>
            </div>
            <div class="qr_cont">
              <p class="icon_box"><i class="icon-qr_code"></i></p>
              <p class="size_14 b_700">내 QR코드</p>
            </div>
          </div>
        </section>
        <section class="survey_section ds_flex dir_column gap8">
          <div class="ds_flex jus_between">
            <p class="b_600">제출된 상담일지</p>
            <p class="size_18 b_700"
              :class="[{ 'none': accountStatus.total_crecords === 0 }, { 'done': accountStatus.total_accept_crecords === accountStatus.total_schedules }]">
              {{ accountStatus.total_crecords }}/{{ accountStatus.total_schedules }}</p>
          </div>
          <div class="ds_flex gap6 al_center"
            v-if="accountStatus.total_accept_crecords === accountStatus.total_schedules">
            <img src="../../assets/img/bis/bis_survey_great.svg" />
            <p class="size_14 eqqui_text b_600">상담일지가 모두 승인됐어요!</p>
          </div>
        </section>
        <section class="menu_section ds_flex dir_column gap24">
          <div class="ds_flex al_center jus_between">
            <p class="size_14 b_700">푸시 메시지 알림</p>
            <input type="checkbox" v-model="newPushMessage" id="pushMessage" :checked="newPushMessage"
              @change="updatePushMessageValue()" />
            <label class="toggle_box" for="pushMessage" :class="{ active: newPushMessage }"></label>
          </div>
          <a class="ds_flex al_center jus_between" href="/bis/notice">
            <p class="size_14 b_700">공지사항</p>
            <p class="icon_box"><i class="icon-up_arrow rotate90 size_24"></i></p>
          </a>
          <a class="ds_flex al_center jus_between" href="/bis/glossary">
            <p class="size_14 b_700">무역 용어집</p>
            <p class="icon_box"><i class="icon-up_arrow rotate90 size_24"></i></p>
          </a>
          <div class="ds_flex al_center jus_between">
            <p class="size_14 b_700">다른 상담회 보기</p>
            <p class="icon_box"><i class="icon-up_arrow rotate90 size_24"></i></p>
          </div>
          <div class="ds_flex al_center jus_between">
            <p class="size_14 b_700">한국어</p>
            <p class="icon_box"><i class="icon-up_arrow rotate90 size_24"></i></p>
          </div>
          <div class="ds_flex al_center jus_between" @click="logout()">
            <p class="size_14 b_700">로그아웃</p>
          </div>
        </section>
        <section class="footer_section">
          <img src="../../assets/img/bis/bis_footer.svg"/>
        </section>
      </div>
    </transition>
  </div>
</template>
