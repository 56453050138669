<script>
import { ref, onMounted, computed, inject, watch } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import Pagination from "@/components/admin/pagination.vue";
import subHeader from "@/components/admin/subHeader.vue";
import tabHeader from "@/components/admin/tabHeader.vue";
import operateTableModal from "@/components/admin/operateTableModal.vue";
import bisatStatus from "@/assets/js/bisatStatus.json";
import dropdownOperationStatus from "@/components/admin/dropDown/dropDownOperationStatus.vue";
export default {
  name: "eventSettingTables",
  components: {
    Pagination,
    subHeader,
    tabHeader,
    operateTableModal,
    dropdownOperationStatus,
  },
  setup() {
    const axios = inject("axios"); // $axios 주입 받기
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref("");
    const activeSub = ref("operate");
    const activeHeadTab = ref("operate");

    //이벤트 일시 관련 변수
    const eventDateList = ref([]);
    const selectDate = ref(null);
    const eventDateTime = ref([]);

    //이벤트 부스 관련 변수
    const boothList = ref([]);

    // 스케줄 관련 변수
    const schedule = ref([]);

    // 모달 관련 변수
    const showStatusModal = ref(false);
    const selectedUserData = ref({});

    const closeAllModals = (type) => {
      showStatusModal.value = false;
    };

    // PAGINATION 관련 변수
    const currentPage = ref(1);
    const itemsPerPage = ref(10); // 페이지당 아이템 수

    const totalPages = computed(() => Math.ceil(boothList.value.length / itemsPerPage.value));
    const paginatedBoothList = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return boothList.value.slice(start, end);
    });
    const handleClickPage = (pageIndex) => {
      if (pageIndex >= 1 && pageIndex <= totalPages.value) {
        currentPage.value = pageIndex;
      }
    };

    // STEP1. 이벤트 날짜 리스트 받아오기
    async function fetchDate() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
        });

        const url = `/schedule/read_date?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateList.value = response.data.date_list;
          if (selectDate.value === null) {
            selectDate.value = eventDateList.value[0];
          }
          fetchDateTime();
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }

    // STEP2. 이벤트 세팅 시 설정한 상담회 날짜, 시간 불러오기 (X축 생성)
    async function fetchDateTime() {
      store.commit('eventStore/setEventDate', selectDate.value)
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: selectDate.value,
        });

        const url = `/schedule/read_datetime?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateTime.value = response.data.datetime.map((item) => ({
            ...item,
            start_time: item.start_time.slice(0, 5), // HH:MM 형식으로 변환
            end_time: item.end_time.slice(0, 5), // HH:MM 형식으로 변환
          }));
          fetchBoothList();
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }

    // STEP3. 부스 불러오기 (Y축 생성)
    async function fetchBoothList() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
        });

        const url = `/schedule/read_booths?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          boothList.value = response.data.booths;
          fetchSchedule();
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }
    // STEP4. 스케줄 불러오기 (데이터 추가)
    async function fetchSchedule() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: selectDate.value,
        });

        const url = `/schedule/read_piece_to_schedule?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.length > 0) {
          schedule.value = response.data;
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 불러올때 오류:", error);
      }
    }

    // 펑션바 기능들 start
    const selectedType = ref("booth");
    const filteredSchedule = computed(() => {
      const list = questionList.value.filter((item) => {
        return item.list_title.includes(searchText.value);
      });
      return list;
    });

    const optionsBUYER = bisatStatus["BUYER"]["operate"];
    const optionsSELLER = bisatStatus["SELLER"]["operate"];
    const optionsINTERPRETER = bisatStatus["INTERPRETER"]["operate"];
    const answers = ref({
      BUYER: [...bisatStatus["BUYER"]["operate"]],
      SELLER: [...bisatStatus["SELLER"]["operate"]],
      INTERPRETER: [...bisatStatus["INTERPRETER"]["operate"]],
    });

    const changeCheckValue = (BIS, options) => {
      answers.value[BIS] = options;
    };
    // 펑션바 기능들 start

    const openOperateModal = (type, userData) => {
      const statusKey = `${type.toLowerCase()}_status`;
      const idKey = `${type.toLowerCase()}_id`;

      const scheduleId = userData["schedule_id"];
      const userStatus = userData[statusKey] || "";
      const userId = userData[idKey] || null;

      selectedUserData.value = { type, userStatus, userId, scheduleId };
      showStatusModal.value = true;
    };

    const getSchedules = (ptb_id, ptd_id) => {
      return schedule.value.filter((s) => s.ptb_id === parseInt(ptb_id) && s.ptd_id === parseInt(ptd_id));
    };

    const scheduleDownload = async () => {
      try {
        const response = await axios.get(`/schedule/download_schedules_table?piece_event_id=${eventId.value}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
          responseType: "blob", // 중요: 파일을 다운로드할 때는 responseType을 'blob'으로 설정해야 합니다.
        });
        // 파일 객체 생성
        const blob = new Blob([response.data], { type: response.headers["content-type"] });

        // 파일 URL 생성
        const url = URL.createObjectURL(blob);

        // 파일 다운로드를 트리거하기 위해 링크를 생성하고 클릭
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "schedule_table.xlsx"); // 적절한 파일 이름과 확장자로 변경
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // 메모리 해제
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("파일 다운로드 중 오류 발생:", error);
      }
    };

    const getIconClass = (type, category, status) => {
      const categoryData = bisatStatus[type][category];
      const statusItem = categoryData.find((item) => item.status === status);
      return statusItem ? statusItem.icon : "";
    };

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: "home" }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        selectDate.value = store.state.eventStore.eventDate;
        fetchDate();
      }
    });

    return {
      activeSub,
      activeHeadTab,
      eventId,
      optionsBUYER,
      optionsSELLER,
      optionsINTERPRETER,
      answers,
      changeCheckValue,
      eventDateList,
      fetchDateTime,
      selectDate,
      eventDateTime,
      boothList,
      getSchedules,
      showStatusModal,
      selectedType,
      filteredSchedule,
      openOperateModal,
      selectedUserData,
      closeAllModals,
      scheduleDownload,
      currentPage,
      itemsPerPage,
      totalPages,
      paginatedBoothList,
      handleClickPage,
      getIconClass,
    };
  },
};
</script>

<template>
  <div id="operationTables" class="back_light_gray2">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="wide_wrap">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
      </div>
      <div class="tables_wrap eventSettingTables_wrap">
        <div class="function_bar">
          <div class="row">
            <select v-model="selectDate" @change="fetchDateTime">
              <option v-for="(item, index) in eventDateList" :key="index" :value="item">{{ item }}</option>
            </select>
            <div class="cont_wrap ds_flex gap18 al_center">
              <p class="icon_box" @click="scheduleDownload"><i class="icon-download"></i></p>
              <!-- TODO: 검색어 기능 추가 예정 -->
              <p class="fake_textbox"><input type="text" placeholder="검색어" /><i class="icon-search"></i></p>
            </div>
          </div>
          <div class="row">
            <div class="user_table_top_cont ds_flex al_center">
              <label class="fx_name">기준으로 보기</label>
              <div class="radio_wrap back_light_gray">
                <div class="radio_option">
                  <input type="radio" id="radio_booth" name="user_type" value="booth" v-model="selectedType" />
                  <label for="radio_booth">부스</label>
                </div>
                <div class="radio_option">
                  <input type="radio" id="radio_buyer" name="user_type" value="buyer" v-model="selectedType" />
                  <label for="radio_buyer">바이어</label>
                </div>
                <div class="radio_option">
                  <input type="radio" id="radio_seller" name="user_type" value="seller" v-model="selectedType" />
                  <label for="radio_seller">셀러</label>
                </div>
                <div class="radio_option">
                  <input type="radio" id="radio_interpreter" name="user_type" value="interpreter"
                    v-model="selectedType" />
                  <label for="radio_interpreter">통역사</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="user_table_top_cont ds_flex al_center">
              <label class="fx_name">상태 보기</label>
              <dropdown-operation-status id="statusBuyer" :options="optionsBUYER" :answers="answers.BUYER" @change="(answers) => {
                changeCheckValue('BUYER', answers);
              }
                "></dropdown-operation-status>
              <dropdown-operation-status id="statusInterpreter" :options="optionsINTERPRETER"
                :answers="answers.INTERPRETER" @change="(answers) => {
                  changeCheckValue('INTERPRETER', answers);
                }
                  "></dropdown-operation-status>
              <dropdown-operation-status id="statusSeller" :options="optionsSELLER" :answers="answers.SELLER" @change="(answers) => {
                changeCheckValue('SELLER', answers);
              }
                "></dropdown-operation-status>
            </div>
            <div class="ds_flex gap12 al_center">
              <div class="inline_cont_btn change_all_status">스케줄 숨기기</div>
              <div class="inline_cont_btn change_all_status">상태 일괄 변경하기</div>
            </div>
          </div>
        </div>
        <div class="table_wrap">
          <table>
            <thead>
              <tr>
                <th>
                  <template v-if="selectedType == 'booth'">부스</template>
                  <template v-else-if="selectedType == 'buyer'">바이어</template>
                  <template v-else-if="selectedType == 'seller'">셀러</template>
                  <template v-else-if="selectedType == 'interpreter'">통역사</template>
                  <span>▾</span>
                </th>
                <th v-for="date in eventDateTime" :key="date.ptd_id">{{ date.start_time }}~{{ date.end_time }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="paginatedBoothList.length > 0">
                <template v-if="selectedType == 'booth'">
                  <tr v-for="(booth, index) in paginatedBoothList" :key="index">
                    <td class="booth">{{ booth.booth_name }}</td>
                    <td v-for="time in eventDateTime" :key="time.ptd_id">
                      <template v-if="getSchedules(booth.ptb_id, time.ptd_id).length">
                        <div class="schedule_info_wrap" v-for="schedule in getSchedules(booth.ptb_id, time.ptd_id)"
                          :key="schedule.schedule_id">
                          <p class="schedule_info">
                            <i class="icon-operate_remark"></i>
                            {{ schedule.manager_name }}
                          </p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('BUYER', schedule)">
                            <span class="type_bdg">B</span><i class="icon_box"
                              :class="`icon-${getIconClass('BUYER', 'operate', schedule.buyer_status)}`"></i> {{
                                schedule.buyer_company }}
                            {{ schedule.buyer_id }}
                          </p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('INTERPRETER', schedule)">
                            <span class="type_bdg">I</span><i class="icon_box"
                              :class="`icon-${getIconClass('INTERPRETER', 'operate', schedule.interpreter_status)}`"></i>
                            {{ schedule.interpreter_name }} {{ schedule.interpreter_id }}
                          </p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('SELLER', schedule)">
                            <span class="type_bdg">S</span><i class="icon_box"
                              :class="`icon-${getIconClass('SELLER', 'operate', schedule.seller_status)}`"></i> {{
                                schedule.seller_company }}
                            {{ schedule.seller_id }}
                          </p>
                        </div>
                      </template>
                      <template v-else>
                        <p class="add_schedule"></p>
                      </template>
                    </td>
                  </tr>
                </template>
                <template v-else-if="selectedType == 'buyer'">
                  <tr v-for="(booth, index) in paginatedBoothList" :key="index">
                    <td class="booth">
                      <i class="icon_box"
                        :class="`icon-${getIconClass('BUYER', 'operate', schedule.buyer_status)}`"></i>
                      <p>{{ schedule.buyer_company }} {{ schedule.buyer_id }}</p>
                    </td>
                    <td v-for="time in eventDateTime" :key="time.ptd_id">
                      <template v-if="getSchedules(booth.ptb_id, time.ptd_id).length">
                        <div class="schedule_info_wrap" v-for="schedule in getSchedules(booth.ptb_id, time.ptd_id)"
                          :key="schedule.schedule_id">
                          <p class="schedule_info">{{ schedule.created_by_name }}</p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('BUYER', schedule)">
                            <span class="type_bdg">B</span>
                            <i class="icon_box"
                              :class="`icon-${getIconClass('BUYER', 'operate', schedule.buyer_status)}`"></i>
                            {{ schedule.buyer_company }}
                            {{ schedule.buyer_id }}
                          </p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('INTERPRETER', schedule)">
                            <span class="type_bdg">I</span><i class="icon_box"
                              :class="`icon-${getIconClass('INTERPRETER', 'operate', schedule.interpreter_status)}`"></i>
                            {{ schedule.interpreter_name }} {{ schedule.interpreter_id }}
                          </p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('SELLER', schedule)">
                            <span class="type_bdg">S</span><i class="icon_box"
                              :class="`icon-${getIconClass('SELLER', 'operate', schedule.seller_status)}`"></i> {{
                                schedule.seller_company }}
                            {{ schedule.seller_id }}
                          </p>
                          <p class="schedule_user_info ds_flex"><span class="type_bdg">FM</span>{{ schedule.manager_name
                            }}</p>
                        </div>
                      </template>
                      <template v-else>
                        <p class="add_schedule"></p>
                      </template>
                    </td>
                  </tr>
                </template>
                <template v-else-if="selectedType == 'seller'">
                  <tr v-for="(booth, index) in paginatedBoothList" :key="index">
                    <td class="booth">{{ booth.booth_name }}</td>
                    <td v-for="time in eventDateTime" :key="time.ptd_id">
                      <template v-if="getSchedules(booth.ptb_id, time.ptd_id).length">
                        <div class="schedule_info_wrap" v-for="schedule in getSchedules(booth.ptb_id, time.ptd_id)"
                          :key="schedule.schedule_id">
                          <p class="schedule_info">{{ schedule.created_by_name }}</p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('BUYER', schedule)">
                            <span class="type_bdg">B</span><i class="icon_box"
                              :class="`icon-${getIconClass('BUYER', 'operate', schedule.buyer_status)}`"></i> {{
                                schedule.buyer_company }}
                            {{ schedule.buyer_id }}
                          </p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('INTERPRETER', schedule)">
                            <span class="type_bdg">I</span><i class="icon_box"
                              :class="`icon-${getIconClass('INTERPRETER', 'operate', schedule.interpreter_status)}`"></i>
                            {{ schedule.interpreter_name }} {{ schedule.interpreter_id }}
                          </p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('SELLER', schedule)">
                            <span class="type_bdg">S</span><i class="icon_box"
                              :class="`icon-${getIconClass('SELLER', 'operate', schedule.seller_status)}`"></i> {{
                                schedule.seller_company }}
                            {{ schedule.seller_id }}
                          </p>
                          <p class="schedule_user_info ds_flex"><span class="type_bdg">FM</span>{{ schedule.manager_name
                            }}</p>
                        </div>
                      </template>
                      <template v-else>
                        <p class="add_schedule"></p>
                      </template>
                    </td>
                  </tr>
                </template>
                <template v-else-if="selectedType == 'interpreter'">
                  <tr v-for="(booth, index) in paginatedBoothList" :key="index">
                    <td class="booth">{{ booth.booth_name }}</td>
                    <td v-for="time in eventDateTime" :key="time.ptd_id">
                      <template v-if="getSchedules(booth.ptb_id, time.ptd_id).length">
                        <div class="schedule_info_wrap" v-for="schedule in getSchedules(booth.ptb_id, time.ptd_id)"
                          :key="schedule.schedule_id">
                          <p class="schedule_info">{{ schedule.created_by_name }}</p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('BUYER', schedule)">
                            <span class="type_bdg">B</span><i class="icon_box"
                              :class="`icon-${getIconClass('BUYER', 'operate', schedule.buyer_status)}`"></i> {{
                                schedule.buyer_company }}
                            {{ schedule.buyer_id }}
                          </p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('INTERPRETER', schedule)">
                            <span class="type_bdg">I</span><i class="icon_box"
                              :class="`icon-${getIconClass('INTERPRETER', 'operate', schedule.interpreter_status)}`"></i>
                            {{ schedule.interpreter_name }} {{ schedule.interpreter_id }}
                          </p>
                          <p class="schedule_user_info ds_flex" @click="openOperateModal('SELLER', schedule)">
                            <span class="type_bdg">S</span><i class="icon_box"
                              :class="`icon-${getIconClass('SELLER', 'operate', schedule.seller_status)}`"></i> {{
                                schedule.seller_company }}
                            {{ schedule.seller_id }}
                          </p>
                          <p class="schedule_user_info ds_flex"><span class="type_bdg">FM</span>{{ schedule.manager_name
                            }}</p>
                        </div>
                      </template>
                      <template v-else>
                        <p class="add_schedule"></p>
                      </template>
                    </td>
                  </tr>
                </template>
              </template>
              <template v-else>
                <tr>
                  <td>부스 없음</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination :currentPage="currentPage" :totalPages="totalPages" :handleClickPage="handleClickPage" />
    </div>
    <operate-table-modal :show-status-modal="showStatusModal" @close="closeAllModals"
      :selected-user-data="selectedUserData"></operate-table-modal>
  </div>
</template>
