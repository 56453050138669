<script>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import bisatStatus from '@/assets/js/bisatStatus.json';
import userDetailForHome from '@/components/bis/userDetailForHome.vue';

export default {
  name: 'BISMyAccount',
  components: {
  },
  props: {
    user: Object,
    event: Object,
  },
  setup(props) {
    const axios = inject('axios'); // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const token = ref('');
    const router = useRouter(); // useRouter를 사용하여 router 인스턴스를 가져옵니다.
    const { userName, userId, formattedUserType, userType, accountStatus } = props.user;
    const { selectDate, scheduleDate, eventId } = props.event;

    const userInfo = ref([])
    async function fetchUserInfo() {
      try {
        const response = await axios.get('/user/read_account_detail_info_for_bis', {
          params: {
            'piece_event_id': eventId.value,
            'account_id': userId.value,
            'account_type': userType.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          userInfo.value = response.data.result
          
        } else {
          console.log("사용자 정보 로드 실패")
        }
      } catch (error) {
      }
    }

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        await fetchUserInfo()
      }
    });
    return {
      userInfo
    };
  },
};
</script>

<template>
  <div>
    <div class="my_account">
      내정보!!
    </div>
  </div>
</template>
