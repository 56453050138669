const bisStore = {
  namespaced: true,
  state: () => ({
    scheduleDate: {},
    isNoticeTouched: {},
    pushMessage: '',
  }),
  mutations: {
    setScheduleDate(state, date) {
      state.scheduleDate = {date};
    },
    setPushMessage(state, boolean) {
      state.pushMessage = boolean;
    },
    setNoticeTouched(state, { noticeId, touched }) {
      state.isNoticeTouched = {
        ...state.isNoticeTouched,
        [noticeId]: touched,
      };
    },
  },
  getters: {
    getNoticeTouched: (state) => (noticeId) => {
      return state.isNoticeTouched[noticeId] || false;
    },
  },
};

export default bisStore;
