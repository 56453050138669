<script>
import { ref, reactive, defineComponent, onMounted, inject, watch, computed } from "vue";
import "@/assets/css/modal.css";
import { useStore } from "vuex";
import dropDownCq from "@/components/admin/dropDown/dropDownCqType.vue";
import dropDownLang from "@/components/admin/dropDown/dropDownLang.vue";
import draggable from "vuedraggable";
import * as survey_form from "@/assets/js/survey_form";
import { types } from "@/assets/js/survey_form";

export default defineComponent({
    name: "surveySettingModal",
    props: {
        show: Object,
        selected: Object,
        BIS: String,
        checkedLang: Array,
        activeList: Array,
        height: Number,
    },
    components: {
        dropDownCq,
        dropDownLang,
        draggable,
    },
    setup(props, { emit }) {
        const axios = inject("axios"); // $axios 주입 받기
        const store = useStore();
        const isAuthenticated = ref(false);
        const userName = ref("");
        const token = ref("");
        const height = props.height;
        const BIS = ref(props.BIS);

        const showNewForm = ref(false); // 새로운 폼 추가하기
        const showLoadForm = ref(false); // 이전 폼 불러오기
        const showLoadPreview = ref(false); // 이전 폼 불러오기에서 미리보기
        const surveyDefinition = (type) => {
            // 다음 버튼 디렉션
            if (type == "new") {
                // 새로운 폼
                showNewForm.value = true;
                showLoadForm.value = false;
                changeProps("showAddType", false);
            } else if (type == "load") {
                // 이전 폼
                showNewForm.value = false;
                showLoadForm.value = true;
                changeProps("showAddType", false);
            } else if (type == "type") {
                // 둘중에 하나 고르는 곳
                showNewForm.value = false;
                showLoadForm.value = false;
                eventSelected.value = 0;
                changeProps("showAddType", true);
            }
        };
        const checkedLang = ref(props.checkedLang); // 부모에서 사용중인 언어리스트
        const activeList = ref([]); // 부모의 activeList
        watch(
            // 사용중인 언어 변경 디텍
            () => props.activeList,
            (newActiveList, oldActiveList) => {
                //activeList
                if (newActiveList !== oldActiveList) {
                    activeList.value = newActiveList;
                    if (oldActiveList != undefined) {
                        readQuestionList();
                    }
                }
            },
            { immediate: true }
        );
        const modal_width = computed(() => {
            // 레이어 팝업 너비
            if (props.show.showAddType || showNewForm.value || (showLoadForm.value && !showLoadPreview.value)) {
                return "narrow_width";
            } else if (props.show.showNewQuestion) {
                return "medium_width";
            } else {
                return "";
            }
        });
        const modal_id = computed(() => {
            // 레이어 팝업 너비
            if (props.show.showOrderQuestion) {
                return "showOrderQuestion";
            } else if (props.show.showPreview) {
                return "showPreview";
            } else if (props.show.showLoadQuestion) {
                return "showLoadQuestion";
            } else if (props.show.showNewQuestion) {
                return "showNewQuestion";
            } else if (showLoadForm.value) {
                return "showLoadForm";
            }
        });
        watch(props, () => {
            if (props.show.showNewQuestion) {
                insertAvailableLang(checkedLang);
                addAnswerForm();
            }
        });
        const changeProps = (props, value) => {
            // 부모단에서 prop 변경 하는 함수
            emit("change", props, value);
        };

        //////////////////
        // 새 폼 추가하기, 질문 불러오기 start //
        //////////////////
        const question_list = ref([]); // 전체 리스트
        const selected_q_list = ref([]); // 선택된 리스트
        const selected_q = ref({
            INTERPRETER: [],
            SELLER: [],
            BUYER: [],
        }); // 지금까지 선택된 리스트

        const readQuestionList = async () => {
            // 질문지 리스트 받아오기
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/satisfaction/read_question_list`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    // 결과값 선가공 후 ref 데이터에 집어넣는다.
                    response.data.question_list.forEach((q, index) => {
                        if (!q.is_in_use) {
                            response.data.question_list.splice(index, 1);
                        }
                    });
                    question_list.value = response.data.question_list.sort((a, b) => {
                        // 이벤트 리스트를 소팅 후 저장합니다.
                        return b.is_essential - a.is_essential || b.is_in_use - a.is_in_use || b.created_at - a.created_at;
                    });
                    if (activeList.value.length < 1) {
                        // 생성된 설문구조가 없으면 필수질문 체크해주기
                        question_list.value.forEach((q) => {
                            if (q.is_essential) {
                                selected_q_list.value.push(q);
                            }
                        });
                    }
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        };
        watch(
            // 선택된 공통질문 변경 디텍
            [() => props.selected, () => props.BIS],
            ([newSelected, newBIS], [oldSelected, oldBIS]) => {
                if (newSelected !== oldSelected) {
                    selected_q.value = newSelected;
                    if (oldSelected !== undefined) {
                        selected_q_list.value = [];
                    }
                }
                if (newBIS !== oldBIS) {
                    BIS.value = newBIS;
                    selected_q_list.value = [];
                }
            },
            { immediate: true }
        );
        const scopedBool = (sq_id) => {
            if (selected_q.value[BIS.value].length < 1) {
                return true;
            } else {
                if (selected_q.value[BIS.value].includes(sq_id)) {
                    return false;
                } else {
                    return true;
                }
            }
        };
        const addNewSurvey = () => {
            selected_q_list.value.forEach((q) => {
                selected_q.value[BIS.value].push(q.sq_id);
            });
            changeProps("selected", selected_q.value[BIS.value]);
            selected_q_list.value = [];
            closeModals();
        };
        //////////////////
        // 새 폼 추가하기, 질문 불러오기 end //
        //////////////////

        //////////////////
        // 질문 불러오기 start //
        //////////////////
        const eventList = ref([]);
        const eventSelected = ref(0);
        const previewEvent = ref(0);
        const previewSurvey = ref([]);
        const readSatisfactionList = async () => {
            // 질문지 리스트 받아오기
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/satisfaction/read_satisfaction_list`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        s_type: BIS.value,
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    // 결과값 선가공 후 ref 데이터에 집어넣는다.
                    eventList.value = response.data.piece_event_list;
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        };

        const readLoadForm = async (eventId) => {
            if (showLoadPreview.value) {
                if (eventId == previewEvent.value) {
                    showLoadPreview.value = false;
                } else {
                    showLoadPreview.value = false;
                    previewEvent.value = eventId;
                    await readPieceToQuestionList(eventId, BIS.value);
                    showLoadPreview.value = true;
                }
            } else {
                previewEvent.value = eventId;
                await readPieceToQuestionList(eventId, BIS.value);
                showLoadPreview.value = true;
            }
        };

        const readPieceToQuestionList = async (id, type) => {
            // BIS 별 질문 받아오기
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/satisfaction/read_piece_to_question_list`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        piece_event_id: id,
                        pts_type: type,
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    // 결과값 선가공 후 ref 데이터에 집어넣는다.
                    previewSurvey.value = response.data.question_list;
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        };

        const sendSelectedClientToParent = () => {
            changeProps("eventSelected", eventSelected.value);
            closeModals();
        }
        //////////////////
        // 질문 불러오기 end //
        //////////////////

        //////////////////
        // 새 질문 추가하기 start //
        //////////////////
        watch(
            // 사용중인 언어 변경 디텍
            () => props.checkedLang,
            (newcheckedLang, oldcheckedLang) => {
                //checkedLang
                if (newcheckedLang !== oldcheckedLang) {
                    checkedLang.value = newcheckedLang;
                }
            },
            { immediate: true }
        );
        const question = ref({
            pts_title: {},
            sq_type: "SINGLE",
            pts_answer_form: [],
        });
        const insertAvailableLang = (checkedLang) => {
            checkedLang.value.forEach((lang) => {
                question.value.pts_title[lang] = "";
                // question.value.pts_answer_form[0][value] = '';
            });
        };
        const addAnswerForm = () => {
            const new_index = {};
            checkedLang.value.forEach((lang) => {
                new_index[lang] = "";
            });
            question.value.pts_answer_form.push(new_index);
        };
        const subAnswerForm = (index) => {
            question.value.pts_answer_form.splice(index, 1);
        };
        const typeCqTitle = (key, value) => {
            question.value.pts_title[key] = value;
        };
        const typeCqAnswerForm = (index, key, value) => {
            question.value.pts_answer_form[index][key] = value;
        };
        const validateCqType = computed(() => {
            return ["SINGLE", "MULTIPLE"].includes(question.value.sq_type);
        });
        const changeCqType = (type) => {
            question.value.sq_type = type;
        };
        const submitQuestion = () => {
            validateNullLang();
            emit("push", question.value);
            closeModals();
        };
        const validateNullLang = () => {
            // 언어페어 비어있는 곳 한국어 페어로 채우기
            let lang = ["ko", "en", "cn", "jp"];
            lang.forEach((key) => {
                if (!question.value.pts_title[key]) {
                    question.value.pts_title[key] = question.value.pts_title["ko"];
                }
                question.value.pts_answer_form.forEach((item, idx) => {
                    if (!item[key]) {
                        question.value.pts_answer_form[idx][key] = question.value.pts_answer_form[idx]["ko"];
                    }
                });
            });
        };
        //////////////////
        // 새 질문 추가하기 end //
        //////////////////

        //////////////////
        // 질문 순서 변경하기 start
        //////////////////
        const dragOptions = computed(() => {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        });
        const drag = ref(false);
        const submitOrder = () => {
            changeProps("activeList", activeList.value);
            closeModals();
        };
        //////////////////
        // 질문 순서 변경하기 end
        //////////////////

        //////////////////
        // 질문지 미리보기 start
        //////////////////
        // const previewAnswer = reactive({
        //     "INTERPRETER": {},
        //     "SELLER": {},
        //     "BUYER": {}
        // });
        // const getPreviewAnswer = paramList => {
        //     paramList.forEach((q, index) => {
        //         if (q.sq_type == "SINGLE") {
        //             previewAnswer[BIS][index] = radioNextAction.value;
        //         } else if (q.sq_type == "MULTIPLE") {
        //             previewAnswer[BIS][index] = "";
        //         } else {
        //             previewAnswer[BIS][index] = "";
        //         }
        //     });
        // }
        const qq = computed(() => {
            return activeList.value[qq_idx.value];
        });
        const qq_idx = ref(0);
        const qq_finish = ref(false);
        const radioNextAction = ref(null);
        const nextaction = () => {
            if (qq.value.sq_type == "SINGLE") {
                if (radioNextAction.value != undefined) {
                    if (qq.value.next_action[radioNextAction.value] == "next") {
                        qq_idx.value++;
                    } else if (qq.value.next_action[radioNextAction.value] == "finish") {
                        qq_idx.value = activeList.value.length - 1;
                        qq_finish.value = true;
                    } else {
                        qq_idx.value = qq.value.next_action[radioNextAction.value];
                    }
                }
            } else {
                if (qq.value.next_action[0] == "next") {
                    qq_idx.value++;
                } else if (qq.value.next_action[0] == "finish") {
                    qq_idx.value = activeList.value.length - 1;
                    qq_finish.value = true;
                } else {
                    qq_idx.value = qq.value.next_action[0];
                }
            }
        };
        const current_lang = ref("ko");
        const changeLang = (lang) => {
            current_lang.value = lang;
        };
        //////////////////
        // 질문지 미리보기 end
        //////////////////

        // 모두 닫기
        const closeModals = () => {
            showNewForm.value = false;
            showLoadForm.value = false;
            showLoadPreview.value = false;
            if (props.show.showNewQuestion) {
                question.value = {
                    pts_title: {},
                    sq_type: "SINGLE",
                    pts_answer_form: [],
                };
            }
            qq_idx.value = 0;
            qq_finish.value = false;
            emit("close");
        };

        onMounted(async () => {
            isAuthenticated.value = store.state.userStore.isAuthenticated;

            if (!isAuthenticated.value) {
                router.push({ name: "home" }); // 홈으로 이동
            } else {
                // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
                userName.value = store.state.userStore.userName;
                token.value = store.state.userStore.token;
                readSatisfactionList();
            }
            window.addEventListener("keyup", (event) => {
                if (event.code === "Escape") {
                    closeModals();
                }
            });
        });

        return {
            // 팝업 내 공통
            height,
            BIS, // 어떤탭인지 구분
            // 팝업유형 props
            showNewForm, // 새로운 폼 추가하기
            showLoadForm, // 이전 폼 불러오기
            showLoadPreview, // 이전 폼 미리보기
            modal_width, // 레이어 팝업 너비
            modal_id, // 팝업 아이디
            changeProps, // 부모단 props 함수 변경
            surveyDefinition, // 다음 버튼 디렉션
            checkedLang, // 부모에서 사용중인 언어
            activeList, // 부모의 activeList
            selected_q_list,
            question_list,
            readQuestionList,
            // 새 폼 추가하기
            selected_q,
            scopedBool,
            addNewSurvey,
            // 새 질문 추가하기
            survey_form,
            question,
            types,
            changeCqType,
            validateCqType,
            addAnswerForm,
            subAnswerForm,
            typeCqTitle,
            typeCqAnswerForm,
            submitQuestion,
            // 질문 불러오기
            eventList, // 피스상담
            eventSelected, // 선택되어 호출할 이벤트
            readLoadForm, // 미리보기
            previewEvent, // 미리보기 할 이벤트
            previewSurvey, // 미리보기 내용
            sendSelectedClientToParent, // 미리보기 내용
            // 질문 순서 변경하기
            dragOptions,
            drag,
            submitOrder,
            // 질문지 미리보기
            qq,
            qq_idx,
            qq_finish,
            radioNextAction,
            nextaction,
            current_lang,
            changeLang,
            // 팝업 모두 초기화/닫기
            closeModals,
        };
    },
    computed: {
        allSelected: {
            //getter
            get: function () {
                let question_list_scoped = [];
                this.question_list.forEach((item) => {
                    if (this.scopedBool(item.sq_id)) {
                        question_list_scoped.push(item);
                    }
                });
                return question_list_scoped.length === this.selected_q_list.length;
            },
            //setter
            set: function (e) {
                let question_list_scoped = [];
                this.question_list.forEach((item) => {
                    if (this.scopedBool(item.sq_id)) {
                        question_list_scoped.push(item);
                    }
                });
                this.selected_q_list = e ? question_list_scoped : [];
            },
        },
    },
});
</script>
<template>
    <div id="surveySettingModal">
        <div class="overlay" v-if="show.showAddType || showNewForm || showLoadForm || show.showNewQuestion"></div>
        <div :id="modal_id" class="modal" :class="modal_width" v-if="show.showAddType || showNewForm || showLoadForm || show.showNewQuestion">
            <p class="icon_box close" @click="closeModals">
                <i class="icon-delete sub_text"></i>
            </p>
            <template v-if="show.showAddType">
                <p class="modal_title size_28 b_700">상담일지 폼 설정하기</p>
                <p class="description">추가할 유형을 선택 후 정보를 입력해주세요.</p>
                <div class="ds_flex gap14" style="margin-top: 60px">
                    <a @click="surveyDefinition('new')" class="add_type">
                        <img src="@/assets/img/survey/add_new.png" />
                        <strong class="size_18 primary_text">새로운 폼 만들기</strong>
                        <p class="size_14 secondary_text">질문지 리스트에서 필요한 질문을 선택하여 폼 만들기</p>
                    </a>
                    <a @click="surveyDefinition('load')" class="add_type">
                        <img src="@/assets/img/survey/load.png" />
                        <strong class="size_18 primary_text">이전 상담회 폼 불러오기</strong>
                        <p class="size_14 secondary_text">다른 상담회에서 쓰인 질문지 리스트를 불러오기</p>
                    </a>
                </div>
            </template>
            <template v-if="showNewForm">
                <p class="modal_title size_28 b_700">새로운 폼 만들기</p>
                <p class="description">상담일지에 필요한 질문을 선택해주세요.</p>
                <div style="margin: 40px 0 16px">
                    <label class="fake_checkbox" for="all" :class="{ active: allSelected }">
                        <i v-if="allSelected" class="icon-checked"></i>
                    </label>
                    <input type="checkbox" id="all" v-model="allSelected" />
                    <label class="secondary_text csP" for="all">전체 선택하기</label>
                </div>
                <div v-for="(q, index) in question_list" :key="index">
                    <input type="checkbox" name="question" :id="`question_${q.sq_id}`" v-model="selected_q_list" :value="q" class="check_wrap_boxed" />
                    <label :for="`question_${q.sq_id}`" class="check_wrap_boxed b_700" :style="index != 0 ? 'margin-top: 8px;' : ''">
                        <em><i class="icon-checked"></i></em>
                        <div>
                            <span v-if="q.is_essential" class="eqqui_text size_14">*</span>
                            {{ q.list_title }}
                        </div>
                        <i :class="[`icon-q_${q.sq_type}`, selected_q_list.includes(q) ? 'main_text' : 'secondary_text']"></i>
                    </label>
                </div>
                <div class="btn_wrap" style="margin-top: 28px">
                    <p class="inline_main_btn cancel_btn" @click="surveyDefinition('type')">이전</p>
                    <p class="inline_main_btn active_btn" @click="addNewSurvey">{{ selected_q_list.length }}개의 질문 추가하기</p>
                </div>
            </template>
            <template v-if="showLoadForm">
                <div class="ds_flex gap18 al_stretch">
                    <div style="flex: 1;">
                        <p class="modal_title size_28 b_700">이전 상담회 폼 불러오기</p>
                        <p class="description" style="margin: 0 0 32px">설문 구조를 불러올 상담회를 선택하세요.</p>
                        <div class="load_form">
                            <div v-for="item in eventList" :key="item.piece_event_id">
                                <input type="radio" :id="`event_${item.piece_event_id}`" v-model="eventSelected" :value="item.piece_event_id" class="radio_wrap_boxed" />
                                <label class="radio_wrap_boxed">
                                    <em></em>
                                    <label :for="`event_${item.piece_event_id}`" class="csP">
                                        <p class="primary_text b_700">{{ item.piece_event_title }}</p>
                                        <p class="secondary_text size_14 b_500">{{ item.start_date }} ~ {{ item.end_date }}</p>
                                        <p class="ds_flex al_center secondary_text size_14 b_500"><i class="icon-pm"></i>{{ item.pm_list[0] }}</p>
                                    </label>
                                    <span class="item_preview" :class="{ 'active': previewEvent == item.piece_event_id }" @click="readLoadForm(item.piece_event_id)">
                                        <i class="icon-preview"></i>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <template v-if="showLoadPreview">
                        <div class="load_divide"></div>
                        <div class="load_preview">
                            <div class="size_18 b_700 primary_text" style="margin: 0 0 28px">질문지 미리보기</div>
                            <div class="size_18 b_700 primary_text" style="margin: 0 0 28px">제목이 나와야되서 api 수정요청 필요</div>
                            <div class="item_list modal_overflow grey_back" :style="`height: ${height - 600}px; min-height: 300px`">
                                <div v-for="(element, index) in previewSurvey" class="item_box">
                                    <div class="title">
                                        <div class="num">{{ index + 1 }}</div>
                                        <span :class="element.is_must == 1 ? 'require' : ''">*</span>
                                        <template v-if="element.is_one_time == 1">
                                            {{ element.pts_title["ko"] }}
                                        </template>
                                        <template v-else>
                                            {{ element.list_title }}
                                        </template>
                                    </div>
                                    <i :class="`icon-q_${element.sq_type} not_entered_text`"></i>
                                </div>
                            </div> 
                        </div>
                    </template>
                </div>
                <div class="btn_wrap" style="margin-top: 28px">
                    <p class="inline_main_btn cancel_btn" @click="surveyDefinition('type')">이전</p>
                    <p class="inline_main_btn active_btn" @click="eventSelected != 0 ? sendSelectedClientToParent() : ''">불러오기</p>
                </div>
            </template>
            <template v-if="show.showNewQuestion">
                <div class="modal_overflow">
                    <p class="modal_title size_28 b_700">새 질문 추가하기</p>
                    <p class="description">이곳에서 추가한 질문은 이전 폼 불러오기에서만 다시 사용 할 수 있어요.</p>
                    <div style="margin-top: 60px">
                        <div class="survey">
                            <div class="size_18 b_700 primary_text">질문</div>
                            <div style="margin: 16px 0 40px">
                                <div class="ds_flex al_center jus_between" v-for="(value, key) in question.pts_title" :key="`pts_title_${key}`" style="margin-bottom: 10px">
                                    <label :for="`pts_title_${key}`" class="secondary_text b_600">{{ survey_form.return_language(key) }}</label>
                                    <input
                                        type="text"
                                        :id="`pts_title_${key}`"
                                        class="fake_textbox"
                                        :placeholder="`${survey_form.return_language(key)} 질문을 입력해 주세요.`"
                                        @keyup="typeCqTitle(key, $event.target.value)"
                                    />
                                </div>
                            </div>
                            <div class="ds_flex al_center jus_between">
                                <p class="size_18 b_700 primary_text">답변</p>
                                <drop-down-cq :defaultValue="question.sq_type" @change="changeCqType"></drop-down-cq>
                            </div>
                            <template v-if="validateCqType">
                                <div class="time_grid">
                                    <template v-for="(form, index) in question.pts_answer_form" :key="index">
                                        <div class="ds_flex">
                                            <div>
                                                <div class="eqqui_text b_700">선택지.{{ index + 1 }}</div>
                                                <div class="grid_inner">
                                                    <template v-for="(value, key) in form" :key="key">
                                                        <label :for="`sq_answer_${key}_${index}`" class="secondary_text b_600">{{ survey_form.return_language(key) }}</label>
                                                        <input
                                                            type="text"
                                                            :id="`sq_answer_${key}_${index}`"
                                                            :placeholder="`${survey_form.return_language(key)} 옵션을 입력해 주세요.`"
                                                            :value="value"
                                                            @keyup="typeCqAnswerForm(index, key, $event.target.value)"
                                                        />
                                                    </template>
                                                </div>
                                            </div>
                                            <template v-if="question.pts_answer_form.length > 1">
                                                <button @click="subAnswerForm(index)">
                                                    <i class="icon-sub_in_circle"></i>
                                                </button>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                                <div class="add_btn">
                                    <button @click="addAnswerForm()" class="eqqui_text">선택지 추가 +</button>
                                </div>
                            </template>
                            <template v-else>
                                <div class="divide_line"></div>
                            </template>
                        </div>
                    </div>
                    <div class="btn_wrap" style="margin-top: 40px">
                        <p class="inline_main_btn cancel_btn" @click="closeModals">취소</p>
                        <p class="inline_main_btn active_btn" @click="submitQuestion()">추가</p>
                    </div>
                </div>
            </template>
        </div>
        <transition name="slide-fade">
            <div :id="modal_id" class="sidemodal padding_issue" v-if="show.showPreview || show.showLoadQuestion || show.showOrderQuestion">
                <template v-if="show.showPreview">
                    <div class="ds_flex al_center jus_between padding_50">
                        <p class="size_26 b_700">질문지 미리보기</p>
                        <drop-down-lang :options="checkedLang" :defaultValue="current_lang" @change="changeLang"></drop-down-lang>
                    </div>
                    <p class="description padding_50">상담일지에 필요한 질문을 선택해주세요.</p>
                    <div class="divide_line"></div>
                    <div class="cont padding_50">
                        <template v-if="qq_finish">
                            <div class="ds_flex al_center gap12">
                                <div class="primary_text size_20 b_700">만족도 조사를 완료했습니다.</div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="ds_flex al_center gap12">
                                <div class="primary_text size_20 b_700">{{ qq.pts_title[current_lang] }}</div>
                            </div>
                            <template v-if="qq.sq_type == 'SINGLE'">
                                <div v-for="(form, index) in qq.pts_answer_form" :key="index">
                                    <input type="radio" name="pts_answer_form" :id="`answer_${index}`" class="radio_wrap_boxed" @change="radioNextAction = index" />
                                    <label :for="`answer_${index}`" class="radio_wrap_boxed b_700">
                                        <em></em>
                                        <div>{{ form[current_lang] }}</div>
                                    </label>
                                </div>
                            </template>
                            <template v-else-if="qq.sq_type == 'MULTIPLE'">
                                <div v-for="(form, index) in qq.pts_answer_form" :key="index">
                                    <input type="checkbox" name="pts_answer_form" :id="`answer_${index}`" class="check_wrap_boxed" />
                                    <label :for="`answer_${index}`" class="check_wrap_boxed b_700">
                                        <em><i class="icon-checked"></i></em>
                                        <div>{{ form[current_lang] }}</div>
                                    </label>
                                </div>
                            </template>
                            <template v-else-if="qq.sq_type == 'SHORT'">
                                <input type="text" class="fake_textbox" placeholder="답변을 입력해주세요." />
                            </template>
                            <template v-else-if="qq.sq_type == 'LONG'">
                                <textarea cols="30" rows="10" placeholder="답변을 입력해주세요."></textarea>
                            </template>
                            <template v-else-if="qq.sq_type == 'NUMBER'">
                                <input type="number" class="fake_textbox" placeholder="숫자만 입력해주세요." />
                            </template>
                            <template v-else-if="qq.sq_type == 'FILE'">
                                <input type="file" />
                            </template>
                            <div class="btn_wrap">
                                <div class="inline_main_btn active_btn" @click="nextaction()">다음</div>
                            </div>
                        </template>
                    </div>
                    <div class="btn_wrap bottom">
                        <div class="divide_line"></div>
                        <p class="inline_main_btn cancel_btn" style="margin-right: 50px" @click="closeModals">닫기</p>
                    </div>
                </template>
                <template v-if="show.showLoadQuestion">
                    <div class="size_26 b_700 padding_50">질문 불러오기</div>
                    <p class="description padding_50">상담일지에 필요한 질문을 선택해주세요.</p>
                    <div style="margin: 40px 50px 16px">
                        <label class="fake_checkbox" for="all" :class="{ active: allSelected }">
                            <i v-if="allSelected" class="icon-checked"></i>
                        </label>
                        <input type="checkbox" id="all" v-model="allSelected" />
                        <label class="secondary_text csP" for="all">전체 선택하기</label>
                    </div>
                    <div class="modal_overflow grey" :style="`height: ${height - 600}px; min-height: 300px`">
                        <template v-for="(q, index) in question_list">
                            <template v-if="scopedBool(q.sq_id)"> 
                                <div :key="index">
                                    <input type="checkbox" name="question" :id="`question_${q.sq_id}`" v-model="selected_q_list" :value="q" class="check_wrap_boxed" />
                                    <label :for="`question_${q.sq_id}`" class="check_wrap_boxed b_700" :style="index != 0 ? 'margin-top: 8px;' : ''">
                                        <em><i class="icon-checked"></i></em>
                                        <div>
                                            <span v-if="q.is_essential" class="eqqui_text size_14">*</span>
                                            <template v-if="q.is_one_time == 0">
                                                {{ q.pts_title["ko"] }}
                                            </template>
                                            <template v-else>
                                                {{ q.list_title }}
                                            </template>
                                        </div>
                                        <i :class="[`icon-q_${q.sq_type}`, selected_q_list.includes(q) ? 'main_text' : 'secondary_text']"></i>
                                    </label>
                                </div>
                            </template>
                        </template>
                        <div class="btn_wrap bottom">
                            <p class="inline_main_btn cancel_btn" @click="closeModals">취소</p>
                            <p class="inline_main_btn active_btn" @click="addNewSurvey">{{ selected_q_list.length }}개의 질문 추가하기</p>
                        </div>
                    </div>
                </template>
                <template v-if="show.showOrderQuestion">
                    <div class="size_26 b_700 padding_50">질문지 순서 변경하기</div>
                    <p class="description padding_50">드래그하여 순서를 변경할 수 있어요.</p>
                    <draggable
                        v-model="activeList"
                        tag="div"
                        class="item_list modal_overflow grey_back"
                        :style="`height: ${height - 600}px; min-height: 300px`"
                        ghost-class="ghost"
                        @start="drag = true"
                        @end="drag = false"
                        item-key="sort"
                    >
                        <template #item="{ element, index }">
                            <div class="item_box csP">
                                <div class="title">
                                    <div class="num">{{ index + 1 }}</div>
                                    <span :class="element.is_must == 1 ? 'require' : ''">*</span>
                                    {{ element.pts_title["ko"] }}
                                </div>
                                <i class="icon-handler sub_text"></i>
                            </div>
                        </template>
                    </draggable>
                    <div class="btn_wrap bottom">
                        <p class="inline_main_btn cancel_btn" @click="closeModals">취소</p>
                        <p class="inline_main_btn active_btn" @click="submitOrder">저장</p>
                    </div>
                </template>
            </div>
        </transition>
    </div>
</template>
