<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import country from '@/assets/js/bisatCountry'
import language from '@/assets/js/bisatLang'
import field from '@/assets/js/bisatField'
import router from '@/router';

export default defineComponent({
  props: {
    showUserInfoModal: Boolean,
    showAdminInfoModal: Boolean,
    accountInfo: Object
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');
    const mode = ref('read')

    // user정보와 API 리턴값 변수
    const storedAccountInfo = ref(props.accountInfo);
    const newAccount_type = ref(props.accountInfo.user_type);
    const userAccountInfo = ref([]);
    const userEventInfo = ref([]);
    const userHistoryInfo = ref([]);

    const popupContainer = ref(null); // popupContainer에 대한 ref 정의

    const scrollToTop = () => {
      if (popupContainer.value) {
        popupContainer.value.scrollTop = 0; // 스크롤을 맨 위로 이동
      }
    }

    // FETCH USER DETAIL
    async function fetchUserInfo() {
      try {
        const queryParams = new URLSearchParams({
          account_id: storedAccountInfo.value.user_id,
          account_type: storedAccountInfo.value.user_type
        });
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_account_detail_info?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result == true) {
          userAccountInfo.value = response.data.info.account;
          userHistoryInfo.value = response.data.info.event_list;
          userEventInfo.value = response.data.info.ongoing_event.map(item => {

            let entry_flight_id, entry_terminal, entry_gate;
            if (item.entry_flight_no) {
              const entry_flight_no = item.entry_flight_no;
              [entry_flight_id, entry_terminal] = entry_flight_no.split(' ');
              [entry_terminal, entry_gate] = entry_terminal.split('/');
            } else {
              entry_flight_id = '';
              entry_terminal = '';
            }

            return {
              ...item,
              entry_flight_id: entry_flight_id,
              entry_terminal: entry_terminal,
              entry_gate: entry_gate
            };
          });
        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    // accountId가 변경될 때 storedAccountId도 업데이트
    watch(() => props.accountInfo, (newValue, oldValue) => {
      storedAccountInfo.value = { ...newValue };
      newAccount_type.value = newValue.user_type;
      fetchUserInfo()
    }, { deep: true });


    const countrySelect = country.map(item => ({
      label: item.label,
      value: item.code
    }))
    const languageSelect = language.map(item => ({
      label: item.label,
      value: item.code
    }))
    const fieldSelect = field.map(item => ({
      label: item.label,
      value: item.code
    }))

    const getCategoryLabel = (value) => {
      const selectedField = field.find(item => item.code === value);
      return selectedField ? selectedField.label : value;
    };

    const getCountryLabel = (value) => {
      const selectedCountry = country.find(item => item.code === value);
      return selectedCountry ? selectedCountry.label : value;
    };

    const getLanguageLabel = (value) => {
      const selectedLanguage = language.find(item => item.code === value);
      return selectedLanguage ? selectedLanguage.label : value;
    };

    const getTerminalName = (value) => {
      const terminalNames = {
        'P01': '제1 터미널',
        'P02': '탑승동',
        'P03': '제2 터미널'
      };

      return terminalNames[value] || '알 수 없는 터미널';
    };

    const handleFloatClick = () => {
      if (mode.value === 'read') {
        mode.value = 'update';
      } else if (mode.value === 'update') {
        if (props.showUserInfoModal) {
          saveData();
        } else if (props.showAdminInfoModal) {
          saveDataForAdmin();
        }
      }
    };

    // TODO: 구조 수정
    const newMemo = ref('');
    // 상태 업데이트
    const saveData = async () => {
      const data = {
        account_id: storedAccountInfo.value.user_id,
        update_product_list: userEventInfo.value.map(info => ({
          pta_id: info.pta_id,
          product: info.pta_product, // info.pta_product에서 info.product로 변경합니다.
        })),
        product: userEventInfo.value.pta_product,
        contact_email: userAccountInfo.value.contact_email,
        mobile: userAccountInfo.value.mobile,
        country: userAccountInfo.value.country,
        lang1: userAccountInfo.value.lang1,
        lang2: userAccountInfo.value.lang2,
        memo: newMemo.value,
        company: userAccountInfo.value.company,
        company_explain: userAccountInfo.value.company_explain,
        company_homepage: userAccountInfo.value.company_homepage,
        company_file: '',
        category: userAccountInfo.value.category,
      }
      const url = '/user/update_account_detail_info';
      try {
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.data.result === true) {
          mode.value = 'read'
          scrollToTop();
          fetchUserInfo();
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('상태 업데이트 오류:', error);
      }
    }

    const saveDataForAdmin = async () => {
      const data = {
        account_id: storedAccountInfo.value.user_id,
        // pta_id_list: userEventInfo.value.map(info => info.pta_id), // pta_id 리스트 생성
        update_product_list: [{
          pta_id: storedAccountInfo.value.pta_id,
          product: ""
        }],
        contact_email: userAccountInfo.value.contact_email,
        mobile: userAccountInfo.value.mobile,
        company: userAccountInfo.value.company,
      }
      const url = '/user/update_account_detail_info';
      try {
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.data.result === true) {
          mode.value = 'read'
          scrollToTop();
          fetchUserInfo();
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('상태 업데이트 오류:', error);
      }
    }

    const deleteUser = async () => {
      if (confirm("삭제하시겠습니까?")) { // 사용자가 확인을 누른 경우에만 실행
        try {
          const response = await axios.delete('/user/delete_account', {
            data: {
              account_id: storedAccountInfo.value.user_id
            },
            headers: {
              Authorization: `Bearer ${token.value}`
            }
          });
          if (response.data.result === true) {
            alert("삭제되었습니다.");
            router.go(0);
          } else {
            console.log('Delete failed:', response.data.result);
          }
        } catch (error) {
          console.error('Delete failed:', error);
        }
      }
    }


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
      }

      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });


    function typeText(type) {
      switch (type) {
        case "BUYER":
          return "바이어";
        case "INTERPRETER":
          return "통역사";
        case "SELLER":
          return "셀러";
        default:
          return "-";
      }
    }
    const closeModals = () => {
      userAccountInfo.value = []
      userEventInfo.value = []
      userHistoryInfo.value = []
      emit('close');
    }

    return {
      closeModals,
      popupContainer,
      mode,
      userAccountInfo,
      userEventInfo,
      userHistoryInfo,
      typeText,
      countrySelect,
      getCountryLabel,
      getLanguageLabel,
      languageSelect,
      fieldSelect,
      getCategoryLabel,
      getTerminalName,
      handleFloatClick,
      newMemo,
      newAccount_type,
      storedAccountInfo,
      deleteUser
    }
  },
});
</script>

<template>
  <div id="userInfo">
    <div class="overlay" v-if="showUserInfoModal || showAdminInfoModal"></div>
    <div class="modal full_width" v-if="showUserInfoModal">
      <p class="icon_box close" @click="closeModals">
        <i class="icon-delete sub_text"></i>
      </p>
      <p class="icon_box float" @click="handleFloatClick">
        <template v-if="mode === 'read'">
          <i class="icon-edit sub_text"></i>
        </template>
        <template v-else>
          저장
        </template>
      </p>
      <div class="user_info_wrap modal_overflow" ref="popupContainer">
        <div class="ds_flex al_center jus_between">
          <p class="type_bdg" :class="userAccountInfo.account_type">{{ typeText(userAccountInfo.account_type) }}</p>
          <p class="icon_box" @click="deleteUser"><i class="icon-trash sub_text"></i></p>
        </div>
        <div class="ds_flex al_end jus_between">
          <p class="modal_title size_28 b_700">{{ userAccountInfo.full_name }}</p>
          <p class="inline_cont_btn">사용자 QR <i class="icon-down_arrow"></i></p>
        </div>
        <hr>
        <div class="ds_flex al_start jus_between user_info">
          <!-- 바이어, 셀러 기본정보 -->
          <template v-if="userAccountInfo.account_type != 'INTERPRETER'">
            <div class="user_info_cont">
              <p class="size_24 b_700">기업 정보</p>
              <div class="input_wrap">
                <div class="input_cont ds_flex al_center">
                  <label>아이디</label>
                  <input type="text" v-model="userAccountInfo.email" class="fake_textbox"
                    :class="{ 'no_border b_700': mode == 'read' }" readonly />
                </div>
                <div class="input_cont ds_flex al_center">
                  <label>기업명</label>
                  <input type="text" class="fake_textbox" v-model="userAccountInfo.company"
                    :class="{ 'no_border b_700': mode == 'read' }" :readonly="mode == 'read'" />
                </div>
                <div class="input_cont ds_flex al_center" v-if="userAccountInfo.account_type == 'BUYER'"
                  :class="{ 'no_border b_700': mode == 'read' }" :readonly="mode == 'read'">
                  <label>국가</label>
                  <template v-if="mode === 'update'">
                    <v-select :options="countrySelect" label="label" :reduce="item => item.value" :clearable="false"
                      v-model="userAccountInfo.country" />
                  </template>
                  <template v-else>
                    <input type="text" :value="getCountryLabel(userAccountInfo.country)"
                      class="fake_textbox no_border b_700" readonly />
                  </template>
                </div>
                <div class="input_cont ds_flex language al_center" v-if="userAccountInfo.account_type == 'BUYER'">
                  <label>통역언어</label>
                  <template v-if="mode === 'update'">
                    <v-select :options="languageSelect" label="label" :reduce="item => item.value" :clearable="false"
                      v-model="userAccountInfo.lang1" />
                    <v-select :options="languageSelect" label="label" :reduce="item => item.value" :clearable="false"
                      v-model="userAccountInfo.lang2" />
                  </template>
                  <template v-else>
                    <input type="text"
                      :value="`${getLanguageLabel(userAccountInfo.lang1)}, ${getLanguageLabel(userAccountInfo.lang2)}`"
                      class="fake_textbox no_border b_700" readonly />
                  </template>
                </div>
                <div class="input_cont ds_flex">
                  <label>담당자</label>
                  <div class="manage_wrap" :class="{ 'back_light_gray3': mode === 'read' }">
                    <template v-if="mode === 'update'">
                      <div class="fake_textbox">
                        <span>이름</span>
                        <input type="text" class="" v-model="userAccountInfo.full_name" />
                      </div>
                      <div class="fake_textbox">
                        <span>이메일</span>
                        <input type="text" class="" v-model="userAccountInfo.contact_email" />
                      </div>
                      <div class="fake_textbox">
                        <span>연락처</span>
                        <input type="text" class="" v-model="userAccountInfo.mobile" />
                      </div>
                    </template>
                    <template v-else>
                      <p class="b_500">{{ userAccountInfo.full_name }}</p>
                      <p class="size_14 secondary_text"><label class="size_14">이메일</label>{{
                        userAccountInfo.contact_email }}</p>
                      <p class="size_14 secondary_text"><label class="size_14">연락처</label>{{ userAccountInfo.mobile }}
                      </p>
                    </template>
                  </div>
                </div>
                <div class="input_cont ds_flex al_center">
                  <label>비고</label>
                  <template v-if="mode === 'update'">
                    <input type="text" class="fake_textbox" v-model="newMemo" />
                  </template>
                  <template v-else>
                    {{ userAccountInfo.memo }}
                  </template>
                </div>
              </div>
            </div>
            <div class="user_info_cont">
              <p class="size_24 b_700">기업 세부 정보</p>
              <div class="input_wrap">
                <div class="input_cont ds_flex">
                  <label>회사소개</label>
                  <template v-if="mode === 'update'">
                    <textarea class="fake_textbox" v-model="userAccountInfo.company_explain"
                      :class="{ 'no_border b_700': mode == 'read' }" :readonly="mode == 'read'" style="resize:none;"
                      rows="5"></textarea>
                  </template>
                  <template v-else>
                    <p class="manage_wrap back_light_gray3 size_14">{{ userAccountInfo.company_explain }}</p>
                  </template>
                </div>
                <div class="input_cont ds_flex">
                  <label>홈페이지</label>
                  <input type="text" class="fake_textbox" v-model="userAccountInfo.company_homepage"
                    :class="{ 'no_border b_700': mode == 'read' }" :readonly="mode == 'read'" />
                </div>
                <div class="input_cont ds_flex">
                  <label>기업소개서</label>
                  <!-- TODO: 파일 업로더 수정 필요 -->
                  <input type="file" class="fake_textbox" />
                </div>
                <div class="input_cont ds_flex" :class="{ 'al_center': mode == 'read' }">
                  <label>관심분야</label>
                  <template v-if="mode === 'update'">
                    <v-select :options="fieldSelect" label="label" :reduce="item => item.value" :clearable="false"
                      v-model="userAccountInfo.category" />
                  </template>
                  <template v-else>
                    <input type="text" :value="getCategoryLabel(userAccountInfo.category)"
                      class="fake_textbox no_border b_700" readonly />
                  </template>
                </div>
              </div>
            </div>
          </template>
          <!-- 통역사 기본정보 -->
          <template v-else>
            <div class="user_info_cont">
              <p class="size_24 b_700">사용자 정보</p>
              <div class="input_wrap">
                <div class="input_cont ds_flex al_center">
                  <label>아이디</label>
                  <template v-if="mode == 'update'">
                    <input type="text" v-model="userAccountInfo.email" class="fake_textbox" readonly />
                  </template>
                  <template v-else>
                    <p class="fake_textbox no_border b_700">{{ userAccountInfo.email }}</p>
                  </template>
                </div>
                <div class="input_cont ds_flex al_center">
                  <label>이름</label>
                  <input type="text" class="fake_textbox" v-model="userAccountInfo.full_name"
                    :class="{ 'no_border b_700': mode == 'read' }" :readonly="mode == 'read'" />
                </div>
                <div class="input_cont ds_flex al_center">
                  <label>연락처</label>
                  <input type="text" class="fake_textbox" v-model="userAccountInfo.mobile"
                    :class="{ 'no_border b_700': mode == 'read' }" :readonly="mode == 'read'" />
                </div>
                <div class="input_cont ds_flex al_center">
                  <label>이메일</label>
                  <input type="text" class="fake_textbox" v-model="userAccountInfo.contact_email"
                    :class="{ 'no_border b_700': mode == 'read' }" :readonly="mode == 'read'" />
                </div>
                <div class="input_cont ds_flex language al_center" v-if="userAccountInfo.account_type == 'BUYER'">
                  <label>통역언어</label>
                  <template v-if="mode === 'update'">
                    <v-select :options="languageSelect" label="label" :reduce="item => item.value" :clearable="false"
                      v-model="userAccountInfo.lang1" />
                    <v-select :options="languageSelect" label="label" :reduce="item => item.value" :clearable="false"
                      v-model="userAccountInfo.lang2" />
                  </template>
                  <template v-else>
                    <input type="text"
                      :value="`${getLanguageLabel(userAccountInfo.lang1)}, ${getLanguageLabel(userAccountInfo.lang2)}`"
                      class="fake_textbox no_border b_700" readonly />
                  </template>
                </div>
                <div class="input_cont ds_flex al_center">
                  <label>비고</label>
                  <input type="text" class="fake_textbox" v-model="userAccountInfo.memo"
                    :class="{ 'no_border b_700': mode == 'read' }" :readonly="mode == 'read'" />
                </div>
              </div>
            </div>
            <div class="user_info_cont">
              <p class="size_24 b_700">세부 정보</p>
              <div class="input_wrap">
                <div class="input_cont ds_flex">
                  <label>에퀴 프로필</label>
                  <input type="text" class="fake_textbox" :class="{ 'no_border b_700': mode == 'read' }"
                    :readonly="mode == 'read'" />
                </div>
                <div class="input_cont ds_flex">
                  <label>예금주명</label>
                  <input type="text" class="fake_textbox" :class="{ 'no_border b_700': mode == 'read' }"
                    :readonly="mode == 'read'" />
                </div>
                <div class="input_cont ds_flex">
                  <label>은행명</label>
                  <input type="text" class="fake_textbox" :class="{ 'no_border b_700': mode == 'read' }"
                    :readonly="mode == 'read'" />
                </div>
                <div class="input_cont ds_flex">
                  <label>계좌번호</label>
                  <input type="text" class="fake_textbox" :class="{ 'no_border b_700': mode == 'read' }"
                    :readonly="mode == 'read'" />
                </div>
              </div>
            </div>
          </template>
        </div>
        <hr />
        <div class="event_info_wrap">
          <p class="size_22 b_700">참여중인 상담회</p>
          <div class="event_info" v-for="event in userEventInfo" :key="event.piece_event_id">
            <div class="ds_flex gap8 al_center">
              <p class="img_wrap"><img src="../../assets/img/common/event_title_badge.png" /></p>
              <p class="size_18 b_700">{{ event.piece_event_title }}</p>
            </div>
            <div class="event_info_cont">
              <template v-if="userAccountInfo.account_type != 'INTERPRETER'">
                <div class="input_cont ds_flex al_center">
                  <label>{{ userAccountInfo.account_type === "BUYER" ? '바이어 코드' : '셀러 코드' }}</label>
                  <input type="text" class="fake_textbox" v-model="event.serial_number"
                    :class="{ 'no_border b_700': mode == 'read' }" :readonly="mode == 'read'" />
                </div>
                <div class="input_cont ds_flex al_center">
                  <label>상담품목</label>
                  <template v-if="mode === 'update'">
                    <select v-model="event.pta_product">
                      <option v-for="product in event.product_list" :key="product.ptp_td">{{ product.product }}</option>
                    </select>
                  </template>
                  <template v-else>
                    <input type="text" :value="event.pta_product" class="fake_textbox no_border b_700" readonly />
                  </template>
                </div>
                <div class="input_cont ds_flex">
                  <label>상담회 비고</label>
                  <div class="memo_wrap">
                    <!-- TODO: 상담회 스케줄 별 비고란이 생기면 그것으로 대체해야함 -->
                    <p v-for="memo, index in event.memo" :key="index">{{ memo.memo }}({{ memo.created_at }}, {{
                      memo.created_by }})</p>
                  </div>
                </div>
              </template>
              <template v-else>
                통역사 참여중인 상담회 정보 뭐 들어갈지 생각
              </template>
            </div>
            <div class="event_reception_wrap" v-if="userAccountInfo.account_type === 'BUYER'">
              <p class="size_18 b_700">영접 정보</p>
              <div class="event_reception_cont ds_flex ">
                <label>입국 정보</label>
                <div class="event_reception_cont_info">
                  <p><span>{{ event.entry_airline }}</span>{{ event.entry_flight_id }} | {{
                    getTerminalName(event.entry_terminal) }} | {{ event.entry_gate }}</p>
                  <p>{{ event.entry_flight_arrival }}</p>
                </div>
              </div>
              <div class="event_reception_cont ds_flex">
                <label>호텔 정보</label>
                <div class="event_reception_cont_info">
                  <input type="text" class="fake_textbox" v-model="event.hotel_name"
                    :class="{ 'no_border b_700': mode == 'read' }" :readonly="mode == 'read'" />
                  <input type="text" class="fake_textbox" v-model="event.hotel_address"
                    :class="{ 'no_border b_700': mode == 'read' }" :readonly="mode == 'read'" />
                </div>
              </div>
              <div class="event_reception_cont ds_flex ">
                <label>비고</label>
                <div class="event_reception_cont_info">
                  <!-- TODO: 영접에서 저장한 비고만 나오는 것인지 확인 필요 -->
                  <p v-for="memo, index in event.memo" :key="index">{{ memo.memo }}({{ memo.created_at }}, {{
                    memo.created_by }})</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="event_history_wrap">
          <p class="size_22 b_700">상담회 참여 내역</p>
          <div class="event_history_info ds_flex dir_column gap20">
            <div class="event_history_cont" v-for="event in userHistoryInfo" :key="event.piece_event_id">
              <div class="ds_flex jus_between">
                <p class="size_18 b_700">
                  <a class="ds_flex gap4 al_center" :href="`/admin/event/setting/${event.piece_event_id}`">{{
                    event.piece_event_title }}<i class="icon-down_arrow size_20 sub_text"></i></a>
                </p>
                <p class="secondary_text">{{ event.start_date }} ~ {{ event.end_date }}</p>
              </div>
              <p>{{ getCategoryLabel(event.category) }} {{ event.pta_product }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal narrow_width" v-if="showAdminInfoModal">
      <p class="icon_box close" @click="closeModals">
        <i class="icon-delete sub_text"></i>
      </p>
      <p class="type_bdg admin" :class="userAccountInfo.account_type">{{ userAccountInfo.account_type }}</p>
      <p class="modal_title size_28 b_700">{{ userAccountInfo.full_name }}</p>
      <div class="user_info admin">
        <div class="input_wrap">
          <div class="input_cont ds_flex al_center">
            <label>사용자 타입</label>
            <template v-if="mode === 'update'">
              <select v-model="newAccount_type">
                <option value="A1">A1</option>
                <option value="A2">A2</option>
                <option value="B1">B1</option>
                <option value="B2">B2</option>
                <option value="C1">C1</option>
                <option value="C2">C2</option>
              </select>
            </template>
            <template v-else>
              <input type="text" v-model="userAccountInfo.account_type" class="fake_textbox no_border b_700" readonly />
            </template>
          </div>
          <div class="input_cont ds_flex al_center">
            <label>아이디</label>
            <input type="text" v-model="userAccountInfo.email" class="fake_textbox"
              :class="{ 'no_border b_700': mode == 'read' }" readonly />
          </div>
          <div class="input_cont ds_flex al_center">
            <label>이메일</label>
            <input type="text" class="fake_textbox" v-model="userAccountInfo.contact_email"
              :class="{ 'no_border b_700': mode == 'read' }" :readonly="mode == 'read'" />
          </div>
          <div class="input_cont ds_flex al_center">
            <label>연락처</label>
            <input type="text" class="fake_textbox" v-model="userAccountInfo.mobile"
              :class="{ 'no_border b_700': mode == 'read' }" :readonly="mode == 'read'" />
          </div>
          <div class="input_cont ds_flex al_center">
            <label>회사명</label>
            <input type="text" class="fake_textbox" v-model="userAccountInfo.company"
              :class="{ 'no_border b_700': mode == 'read' }" :readonly="mode == 'read'" />
          </div>
        </div>
      </div>
      <div class="btn_wrap ds_flex gap18 jus_end">
        <p class="block_cont_btn cancel_btn" @click="deleteUser">삭제</p>
        <p class="block_cont_btn active_btn" @click="handleFloatClick">{{ mode === 'read' ? '수정' : '저장' }}</p>
      </div>
    </div>
  </div>
</template>
