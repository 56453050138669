<script>
import { ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios'; // axios는 일반적으로 직접 가져와 사용합니다.

export default {
  name: 'commonHeader',
  props: {
    window: Object
  },
  setup(props, { emit }) {
    const store = useStore(); // Vuex 스토어 접근
    const route = useRoute(); // vue-router의 현재 route 정보 접근
    const router = useRouter(); // 라우터 인스턴스를 가져오기
    const axios = inject('axios') // $axios 주입 받기

    const user_id = ref('p1@test.test');
    const user_pw = ref('0000');
    const user_data = ref([]);
    const multitype_user = ref(false);

    const isAuthenticated = computed(() => store.state.userStore.isAuthenticated);
    const userName = computed(() => store.state.userStore.userName);
    const eventTitle = computed(() => store.state.eventStore.eventTitle);
    const isActivePreMatch = computed(() => store.state.eventStore.isActivePreMatch);
    const isActiveReception = computed(() => store.state.eventStore.isActiveReception);
    const isActiveLiveMatch = computed(() => store.state.eventStore.isActiveLiveMatch);
    const isActiveOnlineLink = computed(() => store.state.eventStore.isActiveOnlineLink);


    const logout = () => {
      store.commit('userStore/logout');
    };

    const isInEmail = computed(() => {
      const pathName = route.name;
      return pathName === 'surveyEmail';
    })
    if (!isInEmail) {
      // 지금은 로그인해도 진입 가능. 추후 로그인시 막혀야 하면 막아주기
      store.commit('userStore/loginCheck');
    }

    const eventId = computed(() => route.params.eventId || store.state.eventStore.eventId);

    const isAdminRoute = computed(() => route.path.startsWith('/admin'));
    const isBISRoute = computed(() => route.path.startsWith('/BIS'));

    const isVisible = computed(() => {
      const path = route.path;
      const pathName = route.name;
      return path === '/' || pathName === '/surveyEmail' || path === '/admin/event/select' || path === '/admin/event/setting/new' || pathName === 'eventListMaster' || pathName === 'newnewEventsettingMasterMaster'
        || pathName === 'receptionQr' || pathName === 'operateAttendQr';
    });


    const isInfoDesk = computed(() => {
      const pathName = route.name;
      return pathName === 'infoDesk';
    });

    const onEvent = computed(() => {
      const pathName = route.name;
      return pathName === 'eventsetting'
    });

    const login = async () => {
      try {
        const response = await axios.post('/user/login', {
          email: user_id.value,
          password: user_pw.value
        });

        if (response.data.result === true) {
          console.log(response.data);
          if (response.data.user_data.length > 1) {
            multitype_user.value = true;
            user_data.value = response.data.user_data;
          } else {
            user_data.value = response.data.user_data;
            store.commit("userStore/login", true);
            store.commit("userStore/login_info", response.data.user_data[0]);
            // 라우팅 로직 추가
            if (['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].includes(response.data.user_data[0].account_type)) {
              console.log(response.data.user_data[0].account_type + "admin")
              router.push({ name: 'eventList' });
            } else if (['BUYER', 'SELLER', 'INTERPRETER'].includes(response.data.user_data[0].account_type)) {
              fetchBISEventList(response.data.user_data[0].token)
              // router.push({ name: 'BISDashboard' });
              console.log(response.data.user_data[0].account_type + "BIS")
            } else {
              console.error("알 수 없는 유저 타입:", response.data.user_data.account_type);
            }

          }
        } else {
          alert("잘못된 데이터");
        }
      } catch (error) {
        console.error("로그인 실패:", error);
      }
    };

    const multitype_select = (index) => {
      multitype_user.value = false;
      store.commit("userStore/login", true);
      store.commit("userStore/login_info", user_data.value[index]);
      // 라우팅 로직 추가
      if (['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].includes(user_data.value[index].account_type)) {
        router.push({ name: 'eventList' });
      } else if (['BUYER', 'SELLER', 'INTERPRETER'].includes(user_data.value[index].account_type)) {
        fetchBISEventList(user_data.value[index].token)
        // router.push({ name: 'BISDashboard' });
      } else {
        console.error("알 수 없는 유저 타입:", user_data.value[index].account_type);
      }
    };


    const eventListForBIS = ref([]);
    const eventListForBISModal = ref(false);

    async function fetchBISEventList(token) {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/event/read_list_for_bis', {
          params: {
            page: 1,
            limit: 10,
          },
          headers: {
            Authorization: `Bearer ${token}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          eventListForBIS.value = response.data.response_data.event_list;
          if (response.data.response_data.event_list.length > 1) {
            eventListForBISModal.value = true
          } else {
            routeForBIS(0);
          }
        } else {
          alert('이벤트를 불러오지 못했습니다.');
        }
      } catch (error) {
        // 요청이 실패한 경우
        console.error('이벤트 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    async function routeForBIS(index) {
      store.commit('eventStore/setEventId', eventListForBIS.value[index].event_id);
      store.commit('eventStore/setEventTitle', eventListForBIS.value[index].title);
      router.push({ name: 'BISDashboard' });
    }

    const isUserManagementActive = computed(() => {
      const currentPath = route.path;
      return currentPath === '/admin/event/setting/bis' || currentPath === '/admin/event/setting/admin';
    });

    const isQuestionActive = computed(() => {
      const currentPath = route.name;
      return currentPath === 'questionSetting' || currentPath === 'newQuestionDetail' || currentPath === 'questionDetail' || currentPath === 'surveySetting';
    });

    const isAttendActive = computed(() => {
      const currentPath = route.name;
      return currentPath === 'operateAttendInterpreter' || currentPath === 'operateAttendSeller';
    });

    const isSurveyActive = computed(() => {
      const currentPath = route.name;
      return currentPath === 'surveyRecordInterpreter' || currentPath === 'surveyRecordBuyer' || currentPath === 'surveyRecordSeller';
    });

    const isSatisfyActive = computed(() => {
      const currentPath = route.name;
      return currentPath === 'satisfyRecordInterpreter' || currentPath === 'satisfyRecordBuyer' || currentPath === 'satisfyRecordSeller';
    });

    // 모든 로컬 state와 메소드를 반환하여 템플릿에서 접근 가능하도록 합니다.
    return {
      user_id, user_pw, user_data, multitype_user,
      isAdminRoute, isBISRoute,
      isAuthenticated, userName, eventTitle, eventId, isVisible, onEvent,
      login, logout, multitype_select, isUserManagementActive,
      isActivePreMatch, isActiveReception, isActiveLiveMatch, isActiveOnlineLink, isQuestionActive, isAttendActive, isSurveyActive, isSatisfyActive,
      isInfoDesk,
      eventListForBIS, eventListForBISModal, routeForBIS
    };
  }
}
</script>


<template>
  <div>
    <!-- 로그인 상태가 아닌 경우 -->
    <template v-if="!isAuthenticated">
      <div class="header">
        <router-link :to="isAuthenticated ? '/admin/event/select' : '/'">
          <div class="logo_wrap">
            <img src="../../assets/img/common/bisat_logo.png" />
          </div>
        </router-link>
        <div class="login_wrap">
          <input type="text" v-model="user_id" />
          <input type="password" v-model="user_pw" />
          <p class="login" @click="login()">로그인</p>
        </div>
      </div>
    </template>
    <!-- 로그인 상태인 경우 -->
    <template v-else>
      <!-- 어드민 헤더 -->
      <template v-if="isAdminRoute">
        <!-- 이벤트 진입 전 사용하는 네비게이션 없는 헤더 -->
        <template v-if="isVisible">
          <div class="header">
            <router-link :to="isAuthenticated ? '/admin/event/select' : '/'">
              <div class="logo_wrap">
                <img src="../../assets/img/common/bisat_logo.png" />
              </div>
            </router-link>
            <div class="login_wrap">
              <p>{{ userName }} 님</p>
              <p @click="logout"><i class="icon-logout"></i></p>
            </div>
          </div>
        </template>
        <template v-else>
          <!-- 이벤트 진입 후 사용하는 헤더 -->
          <div class="header on_event">
            <img src="../../assets/img/common/event_title_badge.png" />
            <p class="size_26 b_700">{{ eventTitle }}</p>
            <p class="icon_box"><i class="icon-down_arrow size_22"></i></p>
          </div>
          <!-- </template> -->
          <div class="nav">
            <router-link :to="isAuthenticated ? '/admin/event/select' : '/'">
              <div class="logo_wrap">
                <img src="../../assets/img/common/bisat_logo.png" />
              </div>
            </router-link>
            <div class="user_info">
              <router-link to="/">
                <img src="../../assets/img/common/user_info.png">
                <p class="size_18 b_700">{{ userName }} 님</p>
              </router-link>
            </div>
            <div class="menu_wrap">
              <ul>
                <li class="ds_flex al_center">
                  <i class="icon-setting"></i>
                  <span class="menu_title">설정</span>
                  <ul class="w100 ds_flex">
                    <li>
                      <router-link custom v-slot="{ navigate, href, isActive }"
                        :to="{ name: 'eventsetting', params: { eventId: eventId } }">
                        <a :href="href" :class="{ active: isActive }" @click="navigate">상담회 관리</a>
                      </router-link>
                    </li>
                    <li>
                      <router-link custom v-slot="{ navigate, href, isActive }"
                        :to="{ name: 'eventSettingTables', params: { eventId: eventId } }">
                        <a :href="href" :class="{ active: isActive }" @click="navigate">운영 스케줄 관리</a>
                      </router-link>
                    </li>
                    <li>
                      <router-link custom v-slot="{ navigate, href }" to="/admin/event/setting/bis">
                        <a :href="href" :class="{ active: isUserManagementActive }" @click="navigate">사용자 관리</a>
                      </router-link>
                    </li>
                    <li>
                      <router-link custom v-slot="{ navigate, href, isActive }"
                        :to="{ name: 'questionSetting', params: { eventId: eventId } }">
                        <a :href="href" :class="{ active: isActive }" @click="navigate">질문과 설문 관리</a>
                      </router-link>
                    </li>
                    <li v-if="isActivePreMatch == 'Y'">
                      <router-link custom v-slot="{ navigate, href, isActive }"
                        :to="{ name: 'preMatchSetting', params: { eventId: eventId } }">
                        <a :href="href" :class="{ active: isActive }" @click="navigate">사전주선 관리</a>
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li class="ds_flex al_center" v-if="isActiveReception == 'Y'">
                  <i class="icon-landing"></i>
                  <span class="menu_title">영접</span>
                  <ul class="w100 ds_flex">
                    <li>
                      <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'reception' }">
                        <a :href="href" :class="{ active: isActive }" @click="navigate">영접 현황</a>
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li class="ds_flex al_center">
                  <i class="icon-monitor_check"></i>
                  <span class="menu_title">운영</span>
                  <ul class="w100 ds_flex">
                    <li>
                      <router-link custom v-slot="{ navigate, href }" :to="{ name: 'operateAttendInterpreter' }">
                        <a :href="href" :class="{ active: isAttendActive }" @click="navigate">출석표</a>
                      </router-link>
                    </li>
                    <li>
                      <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'operateTables' }">
                        <a :href="href" :class="{ active: isActive }" @click="navigate">현장 운영</a>
                      </router-link>
                    </li>
                    <li v-if="isActiveLiveMatch == 'Y'">
                      <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'operateLiveMatch' }">
                        <a :href="href" :class="{ active: isActive }" @click="navigate">현장 매칭</a>
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li class="ds_flex al_center">
                  <i class="icon-manual2"></i>
                  <span class="menu_title">상담기록</span>
                  <ul class="w100 ds_flex">
                    <li>
                      <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'newEventsettingMaster' }">
                        <a :href="href" :class="{ active: isActive }" @click="navigate">대시보드</a>
                      </router-link>
                    </li>
                    <li>
                      <router-link custom v-slot="{ navigate, href }" :to="{ name: 'surveyRecordInterpreter' }">
                        <a :href="href" :class="{ active: isSurveyActive }" @click="navigate">상담일지</a>
                      </router-link>
                    </li>
                    <li>
                      <router-link custom v-slot="{ navigate, href }" :to="{ name: 'satisfyRecordInterpreter' }">
                        <a :href="href" :class="{ active: isSatisfyActive }" @click="navigate">만족도 조사</a>
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li class="ds_flex al_center csP">
                  <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'infoDesk' }">
                    <i class="icon-information"></i>
                    <a :href="href" target="_blank" class="menu_title csP" :class="{ active: isActive }"
                      @click="navigate">인포데스크</a>
                  </router-link>
                </li>
                <li class="ds_flex al_center">
                  <i class="icon-paper_airplane"></i>
                  <span class="menu_title">전송</span>
                  <ul class="w100 ds_flex">
                    <li>
                      <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'sendNotice' }">
                        <a :href="href" :class="{ active: isActive }" @click="navigate">공지사항</a>
                      </router-link>
                    </li>
                    <li>
                      <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'sendMessage' }">
                        <a :href="href" :class="{ active: isActive }" @click="navigate">문자전송</a>
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </template>


      <!-- BIS 헤더 -->
      <template v-else-if="isBISRoute">
        <div class="header">
          <router-link :to="isAuthenticated ? '/bis/dashboard' : '/'">
            <div class="logo_wrap">
              <img src="../../assets/img/common/bisat_logo.png" />
            </div>
          </router-link>
          <div class="login_wrap">
            <p>BIS {{ userName }} 님</p>
            <p @click="logout"><i class="icon-logout"></i></p>
          </div>
        </div>
      </template>
    </template>
    <div class="overlay" v-if="multitype_user">
      <div class="multitype popup">
        <p class="popup_title">멀티타입 유저입니다.</p>
        <div class="button_wrap">
          <p class="btn" v-for="item, index in user_data" :key="index" @click="multitype_select(index)">
            {{ item.account_type }}
          </p>
        </div>
      </div>
    </div>
    <div class="overlay" v-if="eventListForBISModal">
      <div class="multitype popup">
        <p class="popup_title">이벤트 선택</p>
        <div class="button_wrap">
          <p class="btn" v-for="item, index in eventListForBIS" :key="index" @click="routeForBIS(index)">
            {{ item.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
