<script>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
// import bisatStatus from '@/assets/js/bisatStatus.json';
import userDetailForSchedule from '@/components/bis/userDetailForSchedule.vue';
const bisatStatus = require('@/assets/js/bisatStatus.json')

export default {
  name: 'BISSchedule',
  components: {
    userDetailForSchedule
  },
  props: {
    user: Object,
    event: Object,
  },
  setup(props) {
    const axios = inject('axios'); // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const token = ref('');
    const router = useRouter(); // useRouter를 사용하여 router 인스턴스를 가져옵니다.
    const { userName, formattedUserType, userType, accountStatus } = props.user;
    const { selectDate, scheduleDate, eventId } = props.event;

    // MODALS
    const showUserBISModal = ref(false);
    const activeAccountInfo = ref({});
    const closeAllModals = () => {
      showUserBISModal.value = false;
    };
    const handleUserBISModal = (type, account_id, status, schedule_id) => {
      showUserBISModal.value = true
      activeAccountInfo.value = { type, account_id, status, schedule_id }
    }

    const scheduleList = ref([]);
    const selectListStatus = ref('all');
    async function fetchSchedule() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/schedule/read_piece_to_schedule_for_bis', {
          params: {
            piece_event_id: eventId.value,
            date: selectDate.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          scheduleList.value = response.data.schedule_list;
        } else {
          console.log("스케줄 로드 실패")
        }
      } catch (error) {
      }
    }
    const getIconClass = (type, status) => {
      const categoryData = bisatStatus[type]['forBIS'];
      const statusItem = categoryData.find(item => item.status === status);
      return statusItem ? statusItem.icon : '';
    };
    const getIconName = (type, status) => {
      const categoryData = bisatStatus[type]['forBIS'];
      const statusItem = categoryData.find(item => item.status === status);
      return statusItem ? statusItem.label : '';
    };

    const scheduleStatus = (buyer_status) => {
      if (buyer_status === "ONDUTY") {
        return "상담중"
      } else if (buyer_status === "DONE") {
        return "상담완료"
      } else {
        return "상담전"
      }
    }

    // 필터링된 scheduleList 반환
    const filteredScheduleList = computed(() => {
      return scheduleList.value.filter((item) => {
        const status = item.buyer_status;
        if (selectListStatus.value === "all") {
          return true; // 전체 상태일 때는 모두 표시
        } else if (selectListStatus.value === "ongoing") {
          return status === "ONDUTY";
        } else if (selectListStatus.value === "done") {
          return status === "DONE";
        } else if (selectListStatus.value === "before") {
          return status !== "ONDUTY" && status !== "DONE";
        }
      });
    });

    const countCrStatusAboveThree = computed(() => {
      return scheduleList.value.filter(item => item.cr_status >= 3).length;
    });

    // 상태 업데이트
    const changeStatus = async (schedule, status) => {
      const data = {
        "update_list": [
          {
            piece_event_id: eventId.value,
            account_id: schedule.buyer_id,
            account_type: 'BUYER',
            schedule_id: schedule.schedule_id,
            code_name: status,
            created_how: 'SCHEDULETABLE'
          },
          {
            piece_event_id: eventId.value,
            account_id: schedule.seller_id,
            account_type: 'SELLER',
            schedule_id: schedule.schedule_id,
            code_name: status,
            created_how: 'SCHEDULETABLE'
          },
          {
            piece_event_id: eventId.value,
            account_id: schedule.interpreter_id,
            account_type: 'INTERPRETER',
            schedule_id: schedule.schedule_id,
            code_name: status,
            created_how: 'SCHEDULETABLE'
          },
        ]
      };
      const url = '/schedule/update_schedule_account_status_for_bis';
      try {
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.data.result === true) {
          router.go(0)
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('상태 업데이트 오류:', error);
      }
    }

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        await fetchSchedule()
      }
    });

    return {
      scheduleList,
      selectListStatus,
      getIconClass,
      getIconName,
      showUserBISModal,
      closeAllModals,
      handleUserBISModal,
      activeAccountInfo,
      scheduleStatus,
      filteredScheduleList,
      changeStatus,
      countCrStatusAboveThree
    };
  },
};
</script>

<template>
  <div>
    <div class="schedule">
      <div class="page_title ds_flex jus_between al_center">
        <div class="ds_flex gap8 al_center">
          <p class="icon_box"><i class="icon-down_arrow rotate90 size_28"></i></p>
          <p class="size_22 b_700">스케줄 정보</p>
        </div>
        <p class="inline_cont_btn active_btn">스케줄 추가</p>
      </div>
      <div class="schedule_cont_wrap">
        <section class="total_cnt ds_flex jus_between al_center">
          <div class="total_cnt_cont ds_flex dir_column gap6">
            <p class="size_12 secondary_text">오늘의 스케줄</p>
            <p class="size_20 b_700">{{ scheduleList.length }}개</p>
          </div>
          <div class="total_cnt_cont ds_flex dir_column gap6">
            <p class="size_12 secondary_text">제출된 상담일지</p>
            <p class="size_20 b_700"> {{ countCrStatusAboveThree }}/{{ scheduleList.length }}</p>
          </div>
        </section>
        <section class="schedule_section ds_flex dir_column gap20">
          <div class="schedule_top ds_flex jus_between">
            <p class="size_15 secondary_text">{{ filteredScheduleList.length }}개의 스케줄</p>
            <select v-model="selectListStatus">
              <option value="all">전체 상태</option>
              <option value="before">상담전</option>
              <option value="ongoing">상담중</option>
              <option value="done">완료</option>
            </select>
          </div>
          <template v-if="filteredScheduleList.length > 0">
            <div class="schedule_cont before" v-for="item, index in filteredScheduleList" :key="index">
              <p class="size_14 secondary_text">{{ scheduleStatus(item.buyer_status) }}</p>
              <div class="schedule_list">
                <div class="ds_flex jus_between al_center">
                  <p class="size_20 b_700">{{ item.start_time }}~{{ item.start_time }}</p>
                  <p class="schedule_bdg booth">{{ item.booth_name }}</p>
                </div>
                <div class="user_list_wrap ds_flex dir_column gap16">
                  <div class="user ds_flex dir_column gap12">
                    <div class="ds_flex jus_between al_center">
                      <p class="schedule_bdg BUYER">바이어</p>
                      <p class="ds_flex al_center gap8">
                        <span class="size_12 b_600">{{ getIconName('BUYER', item.buyer_status) }}</span>
                        <i :class="`icon-${getIconClass('BUYER', item.buyer_status)}`"></i>
                      </p>
                    </div>
                    <div class="ds_flex gap4 al_center"
                      @click="handleUserBISModal('BUYER', item.buyer_id, item.buyer_status, item.schedule_id)">
                      <p class="size_14 b_700">{{ item.buyer_company }}</p>
                      <p class="icon_box"><i class="icon-up_arrow rotate90 size_20"></i></p>
                    </div>
                  </div>
                  <div class="user ds_flex dir_column gap12">
                    <div class="ds_flex jus_between al_center">
                      <p class="schedule_bdg SELLER">셀러</p>
                      <p class="ds_flex al_center gap8">
                        <span class="size_12 b_600">{{ getIconName('SELLER', item.seller_status) }}</span>
                        <i :class="`icon-${getIconClass('SELLER', item.seller_status)}`"></i>
                      </p>
                    </div>
                    <div class="ds_flex gap4 al_center"
                      @click="handleUserBISModal('SELLER', item.seller_id, item.seller_status, item.schedule_id)">
                      <p class="size_14 b_700">{{ item.seller_company }}</p>
                      <p class="icon_box"><i class="icon-up_arrow rotate90 size_20"></i></p>
                    </div>
                  </div>
                  <div class="survey_wrap" v-if="item.seller_status !== 'NOSHOW' && item.buyer_status !== 'NOSHOW'">
                    <!-- 상담 시작 전이면서 상담일지 작성 || 이어쓰기 경우 (절반버튼) -->
                    <template
                      v-if="(item.cr_status === null || item.cr_status === 0 || item.cr_status === 1) && (item.buyer_status !== 'ONDUTY' && item.buyer_status !== 'DONE')">
                      <div class="ds_flex jus_between al_center">
                        <template v-if="item.cr_status === null || item.cr_status === 0">
                          <p class="half btn survey">상담일지 작성하기</p>
                        </template>
                        <template v-if="item.cr_status === 1">
                          <p class="half btn survey_rewrite">상담일지 이어쓰기</p>
                        </template>
                        <p class="half btn start" @click="changeStatus(item, 'ONDUTY')">상담을 시작해요</p>
                      </div>
                    </template>
                    <!-- 상담 중이면서 상담일지 작성 || 이어쓰기 경우 (절반버튼) -->
                    <template
                      v-else-if="(item.cr_status === null || item.cr_status === 0 || item.cr_status === 1) && (item.buyer_status === 'ONDUTY')">
                      <div class="ds_flex jus_between al_center">
                        <template v-if="item.cr_status === null || item.cr_status === 0">
                          <p class="half btn survey">상담일지 작성하기</p>
                        </template>
                        <template v-if="item.cr_status === 1">
                          <p class="half btn survey_rewrite">상담일지 이어쓰기</p>
                        </template>
                        <p class="half btn end" @click="changeStatus(item, 'DONE')">상담이 끝났어요</p>
                      </div>
                    </template>
                    <!-- 상담 완료 -->
                    <template v-else-if="item.buyer_status === 'DONE'">
                      <div class="ds_flex jus_between al_center">
                        <template v-if="item.cr_status === null || item.cr_status === 0">
                          <p class="btn survey">상담일지 작성하기</p>
                        </template>
                        <template v-if="item.cr_status === 1">
                          <div class="btn survey_rewrite ds_flex jus_between">
                            <div class="ds_flex gap4 al_center"><img src="../../assets/img/bis/survey_rewrite.svg">
                              <p>상담일지
                                이어쓰기</p>
                            </div>
                            <i class="icon-up_arrow rotate90 size_20"></i>
                          </div>
                        </template>
                        <template v-if="item.cr_status === 2">
                          <p class="btn survey_checking ds_flex"><img src="../../assets/img/bis/survey_checking.svg">상담일지
                            검토중</p>
                        </template>
                        <template v-if="item.cr_status === 3">
                          <div class="btn survey_reject ds_flex jus_between">
                            <div class="ds_flex gap4 al_center"><img src="../../assets/img/bis/survey_reject.svg">
                              <p>상담일지를 수정하세요</p>
                            </div>
                            <i class="icon-up_arrow rotate90 size_20"></i>
                          </div>
                        </template>
                        <template v-if="item.cr_status === 4 || item.cr_status === 6">
                          <p class="btn survey_complete ds_flex"><img src="../../assets/img/bis/survey_complete.svg">상담일지
                            승인됨</p>
                        </template>
                        <template v-if="item.cr_status === 5">
                          <p class="btn survey_complete ds_flex"><img
                              src="../../assets/img/bis/survey_complete_good.svg">상담일지 승인됨<span>거래성사율 높음</span></p>
                        </template>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="ds_flex jus_center dir_column gap16">
              <img src="../../assets/img/bis/empty_schedule.svg"/>
              <p class="t_center size_14 sub_text">스케줄이 없어요</p>
            </div>
          </template>
        </section>
      </div>
    </div>
    <user-detail-for-schedule :show-user-BIS-modal="showUserBISModal" @close="closeAllModals"
      :active-account-info="activeAccountInfo"></user-detail-for-schedule>
  </div>
</template>
