<script>
import { ref, defineComponent, onMounted, inject, watch, computed, onUnmounted } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import router from '@/router';
import country from '@/assets/js/bisatCountry'
import field from '@/assets/js/bisatField'

export default defineComponent({
  props: {
    showUserBISModal: Boolean,
    activeAccountInfo: Object
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');

    const storedAccountInfo = ref(props.activeAccountInfo);

    function userTypeFormat(type) {
      switch (type) {
        case 'INTERPRETER':
          return "통역사";
        case 'SELLER':
          return "셀러";
        case 'BUYER':
          return "바이어"
        default:
          return "-"; // 기본 반환 값
      }
    }

    const getCategoryLabel = (value) => {
      const selectedField = field.find(item => item.code === value);
      return selectedField ? selectedField.label : value;
    };

    const getCountryLabel = (value) => {
      const selectedCountry = country.find(item => item.code === value);
      return selectedCountry ? selectedCountry.label : value;
    };

    // FETCH USER DETAIL
    const userInfo = ref({})
    async function fetchUserInfo() {
      try {
        const queryParams = new URLSearchParams({
          account_id: props.activeAccountInfo.account_id,
          account_type: props.activeAccountInfo.type,
          piece_event_id: eventId.value
        });
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_account_detail_info_for_bis?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result == true) {
          userInfo.value = response.data.info
        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    // accountId가 변경될 때 storedAccountId도 업데이트
    watch(() => props.activeAccountInfo, (newValue, oldValue) => {
      // 속성 이름을 올바르게 참조
      storedAccountInfo.value = { ...newValue };
      // newAccount_type.value = newValue.user_type;
      fetchUserInfo();
    }, { deep: true });

    const closeModals = () => {
      userInfo.value = []
      emit('close');
    }


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
        if (props.showUserBISModal) {
          fetchUserInfo();  // 모달이 열려 있을 때 사용자 정보를 가져옵니다.
        }
      }
    });

    return {
      userInfo,
      closeModals,
      storedAccountInfo,
      userTypeFormat,
      getCategoryLabel,
      getCountryLabel
    }
  },
});
</script>

<template>
  <div id="bisUserDetail">
    <div class="overlay" v-if="showUserBISModal"></div>
    <transition name="slide-up">
      <div class="bottom_sheet" v-if="showUserBISModal">
        <div class="sheet_header">
          <p class="icon_box" @click="closeModals"><i class="icon-delete"></i></p>
        </div>
        <div class="sheet_content">
          <div class="user_info ds_flex dir_column gap12">
            <div class="ds_flex jus_between al_center">
              <p class="user_type_bdg size_12" :class="storedAccountInfo.type">{{ userTypeFormat(storedAccountInfo.type)
                }}</p>
              <a class="user_type_bdg size_12 back_light_gray sub_text" v-if="userInfo.company_homepage"
                :href="userInfo.company_homepage" target="_blank">홈페이지</a>
            </div>
            <p class="size_24 b_700">{{ userInfo.company }}</p>
            <div class="ds_flex gap12 al_center">
              <p v-if="storedAccountInfo.type === 'BUYER'" class="user_type_bdg size_12 country">{{
                getCountryLabel('KO') }}
              </p>
              <p class="user_type_bdg size_12 product">#{{ userInfo.product ? userInfo.product : "-" }}</p>
            </div>
          </div>
          <div class="ds_flex gap20 dir_column sheet_content_sec">
            <p class="size_18 b_700">담당자 정보</p>
            <div class="cont_box">
              <p class="b_700">{{ userInfo.full_name }}</p>
              <div class="ds_flex gap12 manager">
                <p class="size_14 secondary_text">{{ userInfo.mobile }}</p>
                <p class="size_14 secondary_text">{{ userInfo.contact_email }}</p>
              </div>
            </div>
          </div>
          <div class="ds_flex gap20 dir_column sheet_content_sec">
            <p class="size_18 b_700">기업 세부 정보</p>
            <div class="cont_box">
              <p class="b_600 size_14">{{ userInfo.company_explain }}</p>
              <div class="ds_flex jus_between al_center" v-if="userInfo?.company_file?.file_url">
                <p class="size_14 secondary_text">기업소개서</p>
                <a :href="userInfo.company_file.file_url" target="_blank" class="size_14 main_text b_600">
                  {{ userInfo.company_file.file_name }}
                </a>
              </div>
              <div class="ds_flex jus_between al_center">
                <p class="size_14 secondary_text">관심분야</p>
                <p class="size_14 b_600">{{ getCategoryLabel(userInfo.category) }}</p>
              </div>
            </div>
          </div>
          <div class="ds_flex gap20 dir_column sheet_content_sec" v-if="userInfo?.memo?.length > 0">
            <p class="size_14 b_600 secondary_text">비고</p>
            <div class="cont_box">
              <p v-for="memo, index in userInfo.memo" :key="index" class="b_600 secondary_text">
                {{ memo.memo }}   <span class="size_12 sub_text">{{ memo.created_by }} ({{ memo.created_at }})</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
