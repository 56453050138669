<script>
import { computed, ref, watch } from "vue";
import * as survey_form from "@/assets/js/survey_form";

export default {
    props: {
        id: Number,
        options: Object,
        answers: Array,
        disabled: Boolean,
    },
    setup(props, { emit }) {
        let cq_id = ref(0);
        let options = ref({});
        let answers = ref([]);
        let default_value = ref("");
        let disabled = ref(props.disabled);
        const selected = ref(default_value);
        const opened = ref(false);
        const focus = ref(null);

        const openSelect = () => {
            if (opened.value == true) {
                return false;
            } else {
                focus.value.focus();
            }
        };

        const blurClose = () => {
            // 드롭다운 자신 눌렀을때 닫히도록 타임아웃 걸기
            // 타임아웃 없으면 자신 눌렀을 때 닫기는 척만 함
            setTimeout(() => {
                opened.value = false;
            }, 90);
        };

        const changeOption = (option) => {
            if (answers.value.includes(option)) {
                answers.value = answers.value.filter((e) => e !== option);
            } else {
                answers.value.push(option);
            }
            emit("change", answers.value);
            opened.value = false;
        };

        watch(
            // 드롭다운 프롭 변경 디텍
            [() => props.options, () => props.answers, () => props.disabled],
            ([newOptions, newAnswers, newDisabled], [oldOptions, oldAnswers, oldDisabled]) => {
                //options
                if (newOptions !== oldOptions) {
                    options.value = newOptions;
                }

                //answers
                if (newAnswers !== oldAnswers) {
                    answers.value = newAnswers;
                }

                //disabled
                if (newDisabled !== oldDisabled) {
                    disabled.value = newDisabled;
                }
            },
            { immediate: true }
        );

        return {
            cq_id,
            options, // 옵션 v-for 돌려야 하는 값 object 또는 array
            answers,
            disabled, // 변경 불가 select로 만들기
            selected, // 선택된 옵션값
            blurClose, // 선택값 변경 함수
            changeOption, // 선택값 변경 함수
            opened, // 드롭다운 오픈 체크
            openSelect, // 드롭다운 오픈 함수
            survey_form,
            focus,
        };
    },
};
</script>
<template>
    <div class="fake_dropdown_wrap" :style="`width: 100%`">
        <input type="text" ref="focus" name="dropDownOpener" @focus="opened = true" @blur="blurClose()" class="focus_input" />
        <div class="fake_dropdown ds_flex al_center gap4" :class="[opened ? 'active' : '', disabled ? 'disabled' : '']" @click="!disabled ? openSelect() : ''">
            <p>
                <template v-for="(a, index) in answers">
                    <template v-if="index != 0">, </template>
                    {{ options[a]['ko'] }}
                </template>
            </p>
            <i :class="opened ? 'icon-up_arrow' : 'icon-down_arrow'"></i>
        </div>
        <template v-if="!disabled">
            <transition name="dropdown">
                <div class="fake_dropdown_options" v-if="opened" style="">
                    <div v-for="(value, key) in options" :key="key" class="fake_option" :class="selected == key ? 'active' : ''" @click="changeOption(key)">
                        <label :for="`check_${cq_id}_${key}`" class="fake_checkbox" :class="{'active': answers.includes(key)}">
                            <i v-if="answers.includes(key)" class="icon-checked"></i>
                        </label>
                        <input type="checkbox" :id="`check_${cq_id}_${key}`">
                        {{ value['ko'] }}
                    </div>
                </div>
            </transition>
        </template>
    </div>
</template>
